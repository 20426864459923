import axios from "axios";
//const API = "http://localhost:3001";
const API = "https://apigestionbienestaruniversitario.unisinu.edu.co";


export default {
  getAllUsers(token) {
    return axios.post(`${API}/usuarios`,token);
  },
  getToken(credenciales) {
    return axios.post(`${API}/tokenusuario/token`,credenciales);
  },
  create(todo) {
    return axios.post(`${API}/crearusuario`, todo);
  },
  getUser(cc,token) {
    const API2 = `${API}/usuario/${cc}`;
    //console.log(API2);
    return axios.post(API2,token); 
  },
  getUserId(token) {
    const API2 = `${API}/infousuario`;
    return axios.post(API2,token); 
  },
  update(cc, obj) {
    const API2 = `${API}/usuario/${cc}`;
    //console.log(API2);
    return axios.put(API2, obj);
  },
  delete(cc,token) {
    const API2 = `${API}/eliminarusuario/${cc}`;
    //console.log(API2);
    return axios.post(API2,token);
  },
  loadUsers(archivo) {
    const API2 = `${API}/estudiantes`;
    //console.log(API2);
    return axios.post(API2,archivo,{headers : {'content-type': 'multipart/form-data'}});
  },
  
  loadCaracterizacion(archivo) {
    const API2 = `${API}/caracterizaciones`;
    //console.log(API2);
    return axios.post(API2,archivo,{headers : {'content-type': 'multipart/form-data'}});
  },
  getEstudiante(id) {
    const API2 = `${API}/estudiante/id/${id}`;
    //console.log(API2);
    return axios.get(API2); 
  },

  createCaracterizacion(arr,token) {
    
    const API2 = `${API}/caracterizacion`;
    //console.log(API2);
    return axios.post(API2,arr,{token : token});
  },
  createCitaPsico(id,obj) {
    
    const API2 = `${API}/putcitapsicologia/${id}`;
    
    return axios.put(API2,obj);
  },

   existeEstudiante(codigo) {
    const API2 = `${API}/estudiante/existe`;
   
       
    return axios.post(API2,{codigo:codigo});
   
  },
  getActividadesDH() {
    const API2 = `${API}/actividadesdh`;
    return axios.get(API2);
   
  },
  getActividadDH(codigo) {
    const API2 = `${API}/actividaddh/${codigo}`;
    return axios.get(API2);
   
  },
  getPsicologos() {
    const API2 = `${API}/getpsicologos`;
    return axios.get(API2);
   
  },
  modificarActividadDH(codigo,obj) {
    const API2 = `${API}/actividaddh/${codigo}`;
    return axios.put(API2,obj);
   
  },
  

  eliminarActividadDH(id,token){
    const API2 = `${API}/eliminaractividaddh/${id}`
    return axios.post(API2,token);
  },
  getCaracterizaciones(token,fecha1,fecha2) {
    const API2 = `${API}/getcaracterizaciones`;
    return axios.post(API2,{token:token,fecha1,fecha2});
   
  },
  getInformeGeneralADH(token,datos){
    const API2 = `${API}/informegeneralactividadesdh`;
    console.log(token)
    console.log(datos)
    return axios.get(API2,{responseType: 'blob',headers: {
      'x-access-token': `${token}`,
      'periodo': `${datos.periodoAP}`,
      'fecha1': `${datos.fecha1}`,
      'fecha2': `${datos.fecha2}`,
    }}).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `informe-General-ADH.pdf`);
      document.body.appendChild(link);
     
        link.click();
     
      
    })
  },
  
  getPeriodosADH(token){
    const API2 = `${API}/periodosactividadesdh`;
    return axios.post(API2,{token})
  },
  descargarInformeCaracterizacion(ob,token) {
   
    const API2 = `${API}/informecaracterizacion/${ob._id}`;
   
    return axios.get(API2,{responseType: 'blob',headers: {
      'x-access-token': `${token}`
    }}).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Caracterizacion-${ob.nombre}-${ob.codigo}.pdf`);
      document.body.appendChild(link);
     
        link.click();
     
      
    })
   
  },


  crearActividadDH(objeto) {
    const API2 = `${API}/actividaddh`;
    return axios.post(API2,objeto);
  },
  enviarSolicitudAP(objeto) {
    const API2 = `${API}/postsolicitudpsicologia`;
    return axios.post(API2,objeto);
  },
  getSolicitudesAP(token) {
    const API2 = `${API}/getsolicitudespsicologia/`;
    return axios.post(API2,{token}); 
  },
  putSolicitudesAP(id,data) {
    const API2 = `${API}/putsolicitudpsicologia/${id}`;
    return axios.put(API2,data); 
  },
  
  
  InscribirEstudianteActividadDH(idA,nomP,idP,tPers) {
    const API2 = `${API}/postparticipanteactividaddh/${idA}`;
    return axios.post(API2,{idparticipante:idP,nombreparticipante:nomP,tipopersona:tPers,asistencia:false});
  },
  getEstudianteActividadDH(idA,token) {
    const API2 = `${API}/getparticipantesactividaddh/${idA}`;
    return axios.post(API2,{token:token});
  },

  modificarParticipantesActividadDH(idA,token,datos) {
    const API2 = `${API}/putparticipantesactividaddh/${idA}`;
    return axios.put(API2,{datos:datos,token:token});
  },
  getActividadesAsistidasDH(idP){
    const API2 = `${API}/asistenciaactividadesdh/${idP}`;
    return axios.get(API2);
    
  },

  saveEncuestaADH(idA,obj){
    const API2 = `${API}/encuestaactividaddh/${idA}`;
    return axios.post(API2,obj);
    
  },

  //IADH = Informe Actividad Desarrollo Humano
  descargarIADH(idA,token) {
    const API2 = `${API}/informeactividaddh/${idA}`;
    return axios.get(API2,{responseType: 'blob',headers: {
      'x-access-token': `${token}`}}).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `IADH-${idA}.pdf`);
      document.body.appendChild(link);
      link.click();
    })
  },
  
  getOrientacionPsicologica(cedula, token){
    const API2 = `${API}/getorientacioncaso/${cedula}`;
    return axios.post(API2,{token});
  },

  updateOrientacionPsicologica(cedula, datos){
    const API2 = `${API}/putorientacioncaso/${cedula}`;
    return axios.put(API2,datos);
  },

  createRemisionExterna(datos){
    const API2 = `${API}/postremisioncaso`;
    return axios.post(API2,datos);
  },
  updateRemisionExterna(datos){
    const API2 = `${API}/putremisioncaso/${datos._id}`;
    return axios.put(API2,datos);
  },
  getRemisionExternaForIdSolicitud(id,token){
    const API2 = `${API}/getremisionescaso/${id}`;
    return axios.post(API2,{token});
  },

  

  getSolicitudesPsicoAsistidas(idP){
    const API2 = `${API}/getcitasasistio/${idP}`;
    return axios.get(API2);
    
  },
  saveEvaluacionPsicoAsistidas(datos){
    const API2 = `${API}/postevaluacionpsicologia`;
    return axios.post(API2,datos);
    
  },
  createAtencionSalud(datos){
    const API2 = `${API}/postatencionenfermeria`;
    return axios.post(API2,datos);
  },
  getInformeGeneralEnfermeria (token){
    const API2 = `${API}/informegeneralenfermeria`;
    return axios.get(API2,{responseType: 'blob',headers: {
      'x-access-token': `${token}`}}).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Informe_General_Enfermeria.pdf`);
      document.body.appendChild(link);
      link.click();
    })
  },

  getInformeGeneralAP(token,datos){
    const API2 = `${API}/informegeneralasesoriapsicologica`;
    //console.log(token)
    //console.log(datos)
    return axios.get(API2,{responseType: 'blob',headers: {
      'x-access-token': `${token}`,
      'periodo': `${datos.periodo}`,
      'psicologo': `${datos.psicologo}`,
      'fecha1': `${datos.fecha1}`,
      'fecha2': `${datos.fecha2}`,
    }}).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `I_G_Asesorias_Psicologicas.pdf`);
      document.body.appendChild(link);
     
        link.click();
     
      
    })
  },
  getPeriodosAP(token){
    const API2 = `${API}/getperiodossolicitudes`;
    return axios.post(API2,{token})
  },



//historia atencion psicologica
  descargarHAP(idA,token) {
    const API2 = `${API}/informeorietacioncaso/${idA}`;
    return axios.get(API2,{responseType: 'blob',headers: {
      'x-access-token': `${token}`}}).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Historia-${idA}.pdf`);
      document.body.appendChild(link);
      link.click();
    })
  },
  
  //remision atencion psicologica
  descargarRAP(idR,token) {
    const API2 = `${API}/informeremisioncaso/${idR}`;
    return axios.get(API2,{responseType: 'blob',headers: {
      'x-access-token': `${token}`}}).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Remision-${idR}.pdf`);
      document.body.appendChild(link);
      link.click();
    })
  },
  
  sendInscriptionGYM (data){
    const API2 = `${API}/postinscritogimnasio`;
    return axios.post(API2,data);
  },
  RequestInscriptionGYM (token){
    const API2 = `${API}/getinscritosgimnasio`;
    return axios.post(API2,{token});
  },
  

  
  sendNewTournament (data){
    const API2 = `${API}/posttorneo`;
    return axios.post(API2,data);
  },
  RequestTournaments (){
    const API2 = `${API}/gettorneos`;
    return axios.get(API2);
  },
  sendInscriptionTornament (idA,datos){
    const API2 = `${API}/postequipotorneo/${idA}`;
    return axios.post(API2,datos);
  },



  sendGroupsRD (data){
    const API2 = `${API}/postgrupodc`;
    return axios.post(API2,data);
  },
  RequestGroupsRD (){
    const API2 = `${API}/getgruposdc`;
    return axios.get(API2);
  },
  
  sendInscriptionGroupsRD (idA,data){
    const API2 = `${API}/postinscritogrupodc/${idA}`;
    return axios.post(API2,data);
  },
  
  RequestActivityRD (){
    const API2 = `${API}/getactividadesdc`;
    return axios.get(API2);
  },
  RequestOneActivityRD (idA,token){
    const API2 = `${API}/getactividaddc/${idA}`;
    return axios.post(API2,{token});
  },

  sendActivityRD (data){
    const API2 = `${API}/postactividaddc`;
    return axios.post(API2,data);
  },
  InscribirEstudianteActividadRD(idA,nomP,idP,tPers) {
    const API2 = `${API}/postparticipanteactividaddc/${idA}`;
    return axios.post(API2,{numeroIdentificacion:idP,nombreCompleto:nomP,tipoPersona:tPers,asistencia:false});
  },
  ActualizarAsistenciaActividadRD(idA,token,datos) {
   
    const API2 = `${API}/putparticipantesactividaddc/${idA}`;
    return axios.put(API2,{datos,token});
  },
// informe actividad recreadion deporte y CULTURA
  descargarIARDC(idA,token) {
    const API2 = `${API}/informeactividaddc/${idA}`;
    return axios.get(API2,{responseType: 'blob',headers: {
      'x-access-token': `${token}`}}).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `IADRC-${idA}.pdf`);
      document.body.appendChild(link);
      link.click();
    })
  },
  downStaticFile(ruta) {
    const API2 = `${API}${ruta}`;
    return axios.get(API2,{responseType: 'blob'}).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${ruta}.pdf`);
      document.body.appendChild(link);
      link.click();
    })
  },
  
  
};
