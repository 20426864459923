import { render, staticRenderFns } from "./InscripGym.vue?vue&type=template&id=3cdc3add&scoped=true&"
import script from "./InscripGym.vue?vue&type=script&lang=js&"
export * from "./InscripGym.vue?vue&type=script&lang=js&"
import style0 from "./InscripGym.vue?vue&type=style&index=0&id=3cdc3add&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cdc3add",
  null
  
)

export default component.exports