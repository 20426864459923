<template>
  <div class="dh-module">
    
    <InscripGym v-if="$store.state.publicdhselected == 1"/>
    <InscripTorneos v-if="$store.state.publicdhselected == 2"/>
    <InscripGrupos v-if="$store.state.publicdhselected == 3"/>
    <InscripActiv v-if="$store.state.publicdhselected == 4"/>
    
  </div>
</template>

<script>

import InscripGym from '@/components/publico/rd/InscripGym.vue'
import InscripTorneos from '@/components/publico/rd/InscripTorneos.vue'
import InscripGrupos from '@/components/publico/rd/InscripGrupos.vue'
import InscripActiv from '@/components/publico/rd/InscripActiv.vue'

export default {
  name: 'Rdeporte',
  components: {
    InscripGym,
    InscripTorneos,
    InscripGrupos,
    InscripActiv,

   
    
  },
  created(){
    if(this.$store.state.publicdhselected == null)
    this.$router.push("/servicios")
  }
}
</script>
<style scoped>
.dh-module{
  height: 100vh;
  padding-top: 0px;
  
}
</style>
