<template>
  <section class="contenedor">
    <div class="tarjeta">
      <!-- Logo & Information Panel-->
      <div class="informacion">
        <div class="info d-flex align-items-center">
          <div class="content">
            <div class="logo">
              <h1>
                <font class="title-login" size="6" color="#ffffff"
                  >Sistema de Gestión Bienestar</font
                >
              </h1>
            </div>
            <p class="subtitle">Universidad del Sinú - Elías Bechara Zainún</p>
          </div>
        </div>
      </div>
      <!-- Form Panel    -->
      <div class="formulario">
        <div class="form-group">
          <label for="email" class="label-login">Usuario</label>
          <input
            id="usuario-login"
            type="email"
            placeholder="Ingrese su numero de identificacion"
            class="form-control"
            name="email"
            required
            autofocus
          />
        </div>
        <div class="form-group">
          <label for="password" class="label-login">Contraseña</label>
          <input
            placeholder="Ingrese su Contraseña"
            id="password-login"
            type="password"
            class="form-control"
            name="password"
            required
            v-on:keyup.enter="loguear"
          />

          
         
        </div>
        <Alert
          v-if="othererror"
          color="rgb(255, 70, 70)"
          :msj="messageothererror"
        />
        <Alert
          v-if="loading"
          color="rgba(52, 51, 153, 0.26)"
          msj="Cargando..."
        />
        <router-link
          to="/recuperarcontrasena"
          class="btn btn-recuperar"
          href=""
        >
          Olvide mi Contraseña</router-link
        >
        <div class="container-btn-entrar">
          <button autofocus type="button" v-on:keyup.enter="loguear" @click="loguear" class="btn btn-entrar">
            Entrar
          </button>
        </div>
        <div class="container-img-banner">
          <img
            :src="require('@/assets/img/logo-baner.png')"
            class="img-fluid img-banner"
            alt="Responsive image"
          />
        </div>
      </div>
    </div>
    <span class="copyright">Desarrollado por INNTECCO</span>
  </section>
</template>

<script>
import Alert from "@/components/general/Alert.vue";
import Api from "@/logic/ApiUsers.js";
import localToken from "@/logic/localToken.js";

export default {
  name: "Login",
  data: function () {
    return {
      loading: false,
      othererror: false,
      messageothererror: "Correo y/o Contraseña invalidos",
    };
  },
  components: {
    Alert,
  },
  methods: {
    async loguear() {
      try {
        this.othererror = false;
        this.loading = true;
        const usr = document.getElementById("usuario-login").value;
        const pss = document.getElementById("password-login").value;
        
        let credentials = {
          numeroidentificacion: usr,
          password: pss,
        };

        const respuesta = await Api.getToken(credentials);
        
        localToken.setToken(respuesta.data);

     
        this.$router.push("/preadmin");
        this.loading = false;
      } catch (error) {
        console.error(`${error.response.status} line 123`);
        if (error) {
          this.loading = false;
          if (!error.response) {
            this.othererror = true;
            this.messageothererror = "Error de Red";
          }
          if (error.response.status == 401) {
            this.othererror = true;
          } else {
            this.messageothererror = error.response.data.message;
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.contenedor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-width: 100vw;
  height: 100vh;
  min-height: 100vh;
  padding: 0px;
}
.tarjeta {
  display: flex;
  width: 80%;
  height: 80%;
  min-height: 400px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(160, 160, 160, 0.562);
  margin-bottom: 2%;
}
.informacion {
  display: flex;
  width: 100%;
  background-color: rgb(228, 35, 19);
  justify-content: center;
  align-items: center;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  text-align: center;
}
.formulario {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 5%;
  justify-content: space-between;
}

.title-login {
  font-family: "Poppins";
  display: flex;
  justify-content: center;
  align-items: center;
}
.subtitle {
  font-family: "Poppins";
  text-align: center;
  color: #ffffff;
}
.label-login {
  font-family: "Poppins";
}
.container-btn-entrar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.btn-entrar {
  color: white;
  background-color: rgb(228, 35, 19);
  width: 50%;
  border: none;
  font-family: "Poppins";
}
.btn-entrar:hover {
  background-color: rgb(228, 35, 19);
  box-shadow: 0px 0px 10px rgba(128, 128, 128, 0.8);
}
.btn-entrar:focus {
  box-shadow: 0px 0px 10px rgba(128, 128, 128, 0.8);
}
.container-img-banner {
  display: flex;
  width: 100%;
  padding: 10%;
  padding-top: 3%;
  padding-bottom: 3%;
}
.btn-recuperar {
  display: flex;
  text-align: start;
  font-display: "Poppins";
  font-size: 12px;
  color: blue;
  width: max-content;
}

.copyright {
  color: grey;
  font-family: "Poppins";
}

@media (min-width: 360px) and (max-width: 768px) {
  .tarjeta {
    flex-direction: column;
    height: 60%;
    min-height: 400px;
  }
  .informacion {
    height: 50%;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0px;
  }
  .formulario {
    height: 90%;
  }
  .title-login {
    font-size: 22px;
    margin-top: 25px;
  }
  .subtitle {
    margin-top: 15px;
    font-size: 14px;
  }
  .container-img-banner {
    display: none;
  }
}
</style>
