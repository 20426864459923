<template>
  <section class="contenedor">
  
    <span class="copyright">Desarrollado por INNTECCO</span>
  </section>
</template>

<script>
export default {
  name: "Preadmin",
 created(){
   this.$router.push("/admin");
   this.$store.state.optionSlidebarSelected_temp = 0;
    this.$store.dispatch('setOptionSlidebarSelectedAction');
 }
};
</script>

<style scoped>
.contenedor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-width: 100vw;
  height: 100vh;
  min-height: 100vh;
  padding: 0px;
  overflow: auto;
}
.tarjeta {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 50px;
  width: 80%;
  height: 80%;
  min-height: 400px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(160, 160, 160, 0.562);
  margin-bottom: 2%;
  background-color: #f4fbfe;
}
.tarjeta p{
  padding-top: 30px;
  text-align: justify;
  font-size: 20px;
}

@media (min-width: 360px) and (max-width: 768px) {
  .tarjeta {
    padding: 10px;
    flex-direction: column;
    height: 80%;
    min-height: 400px;
    align-items: center;
    justify-content: center;
  }
  .tarjeta p{
    font-size: 16px;
  }
  .tarjeta h1{
    text-align: center;
  }
}
</style>
