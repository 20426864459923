<template>
  <div class="home-services">
    <Header />
    <Services mode="2" />
    <Footer/>
  </div>
</template>

<script>

import Header from '@/components/publico/Header.vue'
import Footer from '../components/publico/Footer.vue'
import Services from '../components/publico/Services.vue'
export default {
  name: 'Servicios',
  components: {
    Header,
    Footer,
    Services
  }
}
</script>
<style scoped>
.home-services{
  padding-top: 80px;
}
</style>
