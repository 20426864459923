<template>
  <footer>
      <div class="container-footer-component">
      <div class="copyright">
        &copy; Copyright <strong><span>Universidad del Sinú Elías Bechara Zainún</span></strong>. Todos los Derechos Reservados.
      </div>
      <div class="credits">
        Diseñado por <a href="https://unisinu.edu.co/">INNTECCO - Universidad del Sinú</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
    name:"Footer"

}
</script>

<style scoped>
.container-footer-component{
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 15vh;
  height: 15vh;
  min-height: 15vh;
  padding: 0vh 5vw 0vh 5vw;
}
.credits > a{
    text-decoration: none;
    color: rgba(228, 36, 19) ;
}
@media (min-width: 360px) and (max-width: 768px) {

  .container-footer-component{
    max-height: 10vh;
  height: 10vh;
  min-height: 10vh;
  }

    .credits,.copyright,.copyright> strong>span{
        font-size: 12px;
    }
}

</style>