<template>
  <section id="team" class="team section-bg">
    <Alerta
      class="alerta"
      v-show="$store.state.showrutadescape"
      :titulo="smsTitle"
      :cuerpo="smsBody"
      :tBoton="smsBText"
    />
    <div class="container">
      <div class="section-title">
        <h2>Recreacion y Deporte</h2>
        <p class="parrafo">
          La inscripcion a torneos se realiza por equipos, asegurese de tener
          los datos de todos los integrantes del equipo, ya que este proceso
          puede realizarlo sola una vez por torneo.
        </p>
      </div>

      <div v-if="showCapture" class="container p-0 col-lg-10">
        <div>
          <div>
            <h4>{{ `Torneo: ${activitySelected.nombre}`}}</h4>
            <div class="container-name-team" v-show="!haveEquipo">
              <input class="input-name-team" type="text" placeholder="Nombre del Equipo" v-model="nombreEquipo">
              <i v-if="nombreEquipo != null" class="bx bxs-check-circle bx-md" @click="haveEquipo=!haveEquipo"></i>
            </div>
            
            <div v-if="haveEquipo" class="container-adicionador">
              
              <div class="adicionador">
                <select class="custom-item" v-model="seleccion">
                  <option value="0" selected hidden>---Seleccione---</option>
                  <option value="estudiante">Estudiante</option>
                  <option value="docente">Docente</option>
                  <option value="administrativo">Administrativo</option>
                  <option value="egresado">Egresado</option>
                  <option value="estudiante-intercambio">
                    Estudiante Intercambio
                  </option>
                  <option value="invitado-externo">Invitado Externo</option>
                </select>

                <input
                  class="custom-item"
                  v-show="seleccion != '0'"
                  type="text"
                  placeholder="Nombre Completo"
                  v-model="player.nombreCompleto"
                />

                <input
                  class="custom-item"
                  v-show="
                    seleccion == 'estudiante' ||
                    seleccion == 'estudiante-intercambio'
                  "
                  type="text"
                  placeholder="Programa"
                  v-model="player.programa"
                />
                <input
                  class="custom-item"
                  v-show="
                    seleccion == 'estudiante' ||
                    seleccion == 'egresado' ||
                    seleccion == 'estudiante-intercambio'
                  "
                  type="text"
                  placeholder="Jornada"
                  v-model="player.jornada"
                />
                <input
                  class="custom-item"
                  v-show="
                    seleccion == 'estudiante' ||
                    seleccion == 'estudiante-intercambio'
                  "
                  type="number"
                  placeholder="Codigo"
                  v-model="player.codigo"
                />
                <input
                  class="custom-item"
                  v-show="
                    seleccion == 'estudiante' ||
                    seleccion == 'estudiante-intercambio'
                  "
                  type="number"
                  placeholder="Semestre"
                  v-model="player.semestre"
                />

                <input
                  class="custom-item"
                  v-show="
                    seleccion == 'docente' || seleccion == 'administrativo'
                  "
                  type="text"
                  placeholder="Cargo"
                  v-model="player.cargo"
                />
                <input
                  class="custom-item"
                  v-show="seleccion == 'invitado-externo'"
                  type="text"
                  placeholder="Parentesco/Entidad"
                  v-model="player.parentescoEntidad"
                />

                <input
                  class="custom-item"
                  v-show="seleccion != '0'"
                  type="number"
                  placeholder="Identificacion"
                  v-model="player.identificacion"
                />
                <input
                  class="custom-item"
                  v-show="seleccion != '0'"
                  type="number"
                  placeholder="Celular"
                  v-model="player.celular"
                />
                <div
                  v-show="seleccion != '0' && !haveCaptian"
                  class="custom-item capitan-item"
                >
                  <span>Capitan ?</span>
                  <input v-model="player.capitan" type="checkbox" />
                </div>

                <input
                  class="custom-item"
                  v-show="seleccion != '0' && player.capitan == true"
                  type="email"
                  placeholder="Email"
                  v-model="player.email"
                />
              </div>

              <div
                v-show="
                  (countPlayers<=maximoPlayers&&seleccion == 'estudiante' &&
                    player.nombreCompleto != null &&
                    player.identificacion != null &&
                    player.celular != null &&
                    player.jornada != null &&
                    player.programa != null &&
                    player.codigo != null &&
                    player.semestre != null) ||
                  (countPlayers<maximoPlayers&&seleccion == 'docente' &&
                    player.nombreCompleto != null &&
                    player.identificacion != null &&
                    player.celular != null &&
                    player.cargo != null) ||
                  ( countPlayers<maximoPlayers&&seleccion == 'administrativo' &&
                    player.nombreCompleto != null &&
                    player.identificacion != null &&
                    player.celular != null &&
                    player.cargo != null) ||
                  ( countPlayers<maximoPlayers &&seleccion == 'egresado' &&
                    player.nombreCompleto != null &&
                    player.identificacion != null &&
                    player.celular != null &&
                    player.jornada != null) ||
                  (countPlayers<maximoPlayers&&seleccion == 'estudiante-intercambio' &&
                    player.nombreCompleto != null &&
                    player.identificacion != null &&
                    player.celular != null &&
                    player.jornada != null &&
                    player.programa != null &&
                    player.codigo != null &&
                    player.semestre != null) ||
                  (countPlayers<maximoPlayers&&seleccion == 'invitado-externo' &&
                    player.nombreCompleto != null &&
                    player.identificacion != null &&
                    player.celular != null &&
                    player.parentescoEntidad != null)
                "
                class="container-aceptar"
              >
                <i class="bx bxs-check-circle bx-lg" @click="addPlayer()"></i>
              </div>
            </div>
           
            <!-- equipo list -->
            <div class="header-team">
              <h5>{{nombreEquipo}}</h5>
            <span v-if="team.length > 0">{{`Maximo Integrantes: ${maximoPlayers}`}}</span>
            </div>
            
            <div v-if="team.length > 0" class="container-list-team">
              <div class="list-team" v-for="item in team" :key="item.ident">
                <div class="a">
                  <h6>{{ item.nombre }}</h6>
                  <h6>{{ item.rol }}</h6>
                </div>
                <div class="b">
                  <i v-if="!item.cap" class="bx bx-user-circle"></i>
                  <i v-if="item.cap" class="bx bxs-user-circle"></i>

                  <i
                    class="bx bx-minus-circle"
                    @click="deletePlayer(item.ident)"
                  ></i>
                </div>
              </div>

              <div class="input-group-append p-3">
                <button class="btn btn-outline-primary" type="button" @click="sendInscripcion()">
                  preInscribir Equipo
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-show="showTorneos" class="row">
        <div
          class="
            col-lg-3 col-md-6
            d-flex
            align-items-stretch
            justify-content-center
          "
          v-for="actividad in Torneos"
          :key="actividad._id"
        >
          <div class="member">
            <div class="member-img">
              <img :src="actividad.imagen" class="img-fluid activ-img" alt="" />
              <div class="social">
                <button
                  class="btn btn-success"
                  @click="preInscribir(actividad._id)"
                >
                  Inscripcion
                </button>
              </div>
            </div>
            <div class="member-info">
              <h4>{{ actividad.nombre }}</h4>
              <div class="row">
                <h6 class="col-2">Lugar:</h6>
                <span class="col-10">{{ actividad.lugar }}</span>
              </div>

              <div class="row">
                <h6 class="col-2">Fecha:</h6>
                <span class="col-10">{{
                  `${actividad.fechaInicio} || ${actividad.fechaFin}`
                }}</span>
              </div>
              <div class="row">
                <h6 class="col-4 d-flex inscritos">Max. Equipos</h6>
                <span class="col-8 pl-0">{{ actividad.maximoEquipos }}</span>
              </div>
              <div class="row">
                <h6 class="col-2">E. Inscritos:</h6>
                <span class="col-10">{{ actividad.equipos.length }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-show="showNone">
          <h4>No hay Torneos, vuele luego.</h4>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ApiUsers from "@/logic/ApiUsers.js";
import Alerta from "@/components/general/Alerta.vue";
//const ObjectId = require('mongo-objectid');
export default {
  name: "InscripTorneos",
  data: function () {
    return {
     
      seleccion: "0",

      
      showTorneos: true,
      showCapture: false,
      showNone: false,

      smsTitle: "Mensaje",
      smsBody: "Cuerpo",
      smsBText: "Clickme",
      smsShow: false,


      Torneos: [],


      maximoPlayers:0,
      haveEquipo:false,
      nombreEquipo:null,
      haveCaptian: false,
      countPlayers: 0,
      player: {
        rol: null,
        nombreCompleto: null,
        programa: null,
        jornada: null,
        codigo: null,
        semestre: null,
        cargo: null,
        parentescoEntidad: null,
        identificacion: null,
        celular: null,
        email: null,
        capitan: false,
      },
      team: [],
      activitySelected: null,
    };
  },
  methods: {
    async sendInscripcion(){
        try {

          const axx = this.team.filter(item => item.cap==true)
          if(axx.length==0){
            this.team[0].cap =true;
          }
          const data = {
            nombreEquipo:this.nombreEquipo,
            integrantes: this.team
          }
          console.log(data);
          const r = await ApiUsers.sendInscriptionTornament(this.activitySelected._id ,data);
          alert(r.data);
          this.$router.push('/servicios');
        } catch (error) {
          console.error(error)
        }
    },
    deletePlayer(value) {
      console.log(value);
      const as = this.team.filter((item) => item.ident == value);
      if(as[0].cap==true) this.haveCaptian =false;
      this.team = this.team.filter((item) => item.ident !== value);
      this.countPlayers -=1;
    },

    addPlayer() {
      if (this.player.capitan && this.player.email == null) {
        this.smsBody = "Debes ingresar un Email.";
        this.smsBText = "Salir";
        this.$store.state.valueSRE = true;
        this.$store.state.rutadeescape = "/servicios";
        this.$store.dispatch("actionShowRutaDeEscape");
      } else {
        const ax = {
          nombre: this.player.nombreCompleto,
          prog: this.player.programa,
          jor: this.player.jornada,
          cod: this.player.codigo,
          sem: this.player.semestre,
          car: this.player.cargo,
          parent: this.player.parentescoEntidad,
          ident: this.player.identificacion,
          cel: this.player.celular,
          ema: this.player.email,
          cap: this.player.capitan,
          rol: this.seleccion,
        };
        if (ax.cap) {
          this.haveCaptian = true;
        }
        this.team.push(ax);
        this.player = {
          rol: null,
          nombreCompleto: null,
          programa: null,
          jornada: null,
          codigo: null,
          semestre: null,
          cargo: null,
          parentescoEntidad: null,
          identificacion: null,
          celular: null,
          email: null,
          capitan: false,
        };
        this.seleccion = "0";
        this.countPlayers +=1;
        console.log(this.countPlayers)
      }
    },
    
  

    async cargarTorneos() {
      try {
        const resp = await ApiUsers.RequestTournaments();
        this.Torneos = resp.data;
        console.log(this.Torneos);
      } catch (error) {
        console.error(error);
        this.showNone = true;
      }
    },
    async preInscribir(idA) {
      console.log(idA);
      this.showTorneos = false;
      this.showCapture = true;
      const activAux = this.Torneos.filter(item=> item._id == idA);

      this.activitySelected = activAux[0];
      this.maximoPlayers= this.activitySelected.maximoJugadores
     
    },
  },
  created() {
    this.cargarTorneos();
  },
 

  components: {
    Alerta,
  },
};
</script>

<style scoped>
.header-team{
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.input-name-team{
  display: flex;
  width: 100%;
  height: 100%;
  padding: .3rem;
  min-height: 3rem;
  border-radius: .5rem;
  border: solid 0.01px rgba(128, 128, 128, 0.288);
}
.input-name-team:focus,.input-name-team:active{
  outline: none;
}
.container-name-team{
 display: flex;
 width: 100%;
 min-height: 2rem;
 align-items: center;
 margin-bottom: 2rem;
}
.list-team {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.list-team > .a,
.list-team > .b {
  display: flex;
  width: 50%;
  justify-content: space-around;
}
.container-list-team {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.custom-item {
  display: flex;
  margin: 0.3rem;
  height: 100%;
  min-height: 2rem;
  width: 30%;
  border: none;
  border-bottom: solid 0.01px rgba(128, 128, 128, 0.199);
  background-color: rgba(240, 248, 255, 0);
}
.capitan-item {
  border: none;
  justify-content: flex-start;
  align-items: center;
}
.capitan-item > input {
  margin: 0.5rem;
}
.capitan-item > input:hover {
  cursor: pointer;
}
.custom-item:focus,
.custom-item:active {
  outline: none;
}
.container-aceptar {
  display: flex;
  padding: 1rem;
  height: 100%;
}
.container-adicionador {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 5px rgba(128, 128, 128, 0.521);
  background-color: #fff;
  margin-bottom: 2rem;
}
.adicionador {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.input-grupo-container {
  display: flex;
  width: 100%;
  justify-content: center;
}
.input-grupo {
  display: flex;
  flex-direction: column;
  width: 90%;
  justify-content: center;
}
.input-grupo > select {
  min-height: 5vh;
  border-radius: 5px;
  background-color: white;
  padding: 5px;
}
.input-grupo > div {
  display: flex;
  justify-content: center;
}
.input-grupo > div > button {
  margin-top: 6vh;
  width: 70%;
}
.activ-img {
  min-height: 180px;
  max-height: 180px;
}
.mensaje {
  height: 80vh;
  width: 90vw;
  position: fixed;
  top: calc(50% - 200px);
  left: calc(50% - (90vw / 2));
}
.team {
  height: 100vh;
  min-height: 76vh;
  overflow: auto;
}

.alerta {
  z-index: 99;
}
.inscritos {
  width: min-content;

  padding-right: 0px;
}
@media (min-width: 768px) {
  .input-grupo-container {
    padding-left: 20vw;
    padding-right: 20vw;
  }
  .parrafo {
    display: flex;
    width: 100%;
    padding-left: 20%;
    padding-right: 20%;
  }
}
</style>