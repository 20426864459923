<template>
  <div class="mensaje">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{titulo}}</h5>
            
          </div>
          <div class="modal-body">
            <p>{{cuerpo}}</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="close">Cerrar</button>
            <button type="button" class="btn btn-primary" @click="redirigir">{{tBoton}}</button>
            
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name:"Alerta",
    props:{
        
        titulo:String,
        cuerpo:String,
        tBoton:String,
        
    },
    methods:{
        close(){
          this.$store.state.valueSRE = false
          this.$store.dispatch("actionShowRutaDeEscape");
        },
        redirigir(){
          this.$router.push(this.$store.state.rutadeescape)
          this.$store.state.valueSRE = false;
          this.$store.dispatch("actionShowRutaDeEscape");
        }
    }

}
</script>

<style>

</style>