<template>
  <div class="caja">
    <img v-animate-css="anima" :src="require('@/assets/img/logo_circle.png')" />
  </div>
</template>

<script>
import "animate.css";

export default {
  name: "LoadFragment",
  data: function () {
    return {
      anima: {
        classes: "tada",
        duration: 2000,
        iteration: "infinite",
      },
    };
  },
};
</script>

<style  scoped>
.caja {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 99;
  backdrop-filter: blur(2px);
  background-color: rgba(128, 128, 128, 0);
  
  max-width: 80vw;
}
.caja > img {
  display: flex;
  width: 10%;
  z-index: 99;
}
@media (min-width: 360px) and (max-width: 768px) {
  .caja{
    max-width: 100vw;
  }
}
</style>