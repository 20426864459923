<template>
  <div class="dh-module">
    
    <InscripActividades v-if="$store.state.publicdhselected == 1"/>
    <EvalActividades v-if="$store.state.publicdhselected == 2"/>
    <Caracterizacion v-if="$store.state.publicdhselected == 3"/>
    <SolicitudAPsico v-if="$store.state.publicdhselected == 4"/>
    <EvalPsico v-if="$store.state.publicdhselected == 5"/>
  </div>
</template>

<script>
import InscripActividades from '@/components/publico/dh/InscripActividades.vue'
import EvalActividades from '@/components/publico/dh/EvalActividades.vue'
import Caracterizacion from '@/components/publico/dh/Caracterizacion.vue'
import SolicitudAPsico from '@/components/publico/dh/SolicitudAPsico.vue'
import EvalPsico from '@/components/publico/dh/EvalPsico.vue'

export default {
  name: 'DHumano',
  components: {
    InscripActividades,
    EvalActividades,
    Caracterizacion,
    SolicitudAPsico,
    EvalPsico,
    
  },
  created(){
    if(this.$store.state.publicdhselected == null)
    this.$router.push("/servicios")
  }
}
</script>
<style scoped>
.dh-module{
  height: 100vh;
  padding-top: 0px;
  
}
</style>
