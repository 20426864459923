<template>
  <div class="contenedorHeaderDH">
    <div class="subSection">ACTIVIDADES DE DESARROLLO HUMANO</div>
    <div class="subSection botonera">
      <div class="btn-actividades" @click="toggle('listar')">ACTIVIDADES</div>
      <div class="btn-actividades" @click="toggle('crear')">
        NUEVA ACTIVIDAD
      </div>
      <div
        class="btn-actividades"
        v-show="seleccionado.length == 1 && showListar"
        @click="preModificarActividad"
      >
        MODIFICAR
      </div>
      <div
        class="btn-actividades"
        v-show="seleccionado.length > 0 &&seleccionado.length <= 10 && showListar"
        @click="descargar"
      >
        GENERAR INFORME
      </div>
      <div
        class="btn-actividades"
        v-show="seleccionado.length > 0 && showListar"
        @click="asistencia"
      >
        ASISTENCIA
      </div>
      <div
        class="btn-actividades"
        v-show="seleccionado.length > 0 && showListar"
        @click="borrarActividad"
      >
        ELIMINAR
      </div>
    </div>
    <div class="subContent" v-show="showCrear">
      <form class="crearform">
        <div class="form-group row p-1">
          <div class="col-12">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">Nombre</div>
              </div>
              <input
                id="text"
                name="text"
                type="text"
                class="form-control"
                required="required"
                v-model="nombreNewActividad"
              />
            </div>
          </div>
        </div>
        <div class="form-group row p-1">
          <div class="col-12">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">Lugar</div>
              </div>
              <input
                id="text2"
                name="text2"
                type="text"
                required="required"
                class="form-control"
                v-model="lugarNewActividad"
              />
            </div>
          </div>
        </div>
        <div class="form-group row p-1">
          <div class="col-12">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">Imagen</div>
              </div>
              <input
                id="text6"
                name="text6"
                placeholder="url de la imagen de cabecera"
                type="url"
                class="form-control"
                v-model="imagenNewActividad"
              />
            </div>
          </div>
        </div>
        <div class="form-group row p-1 cust-w-50">
          <div class="col-12">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">Fecha de inicio</div>
              </div>
              <input
                id="text1"
                name="text1"
                type="date"
                class="form-control"
                required="required"
                v-model="fInicioNewActividad"
              />
            </div>
          </div>
        </div>
        <div class="form-group row p-1 cust-w-50">
          <div class="col-12">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">Fecha de fin</div>
              </div>
              <input
                id="text1"
                name="text1"
                type="date"
                class="form-control"
                required="required"
                v-model="fFinNewActividad"
              />
            </div>
          </div>
        </div>

        <div class="form-group row p-1 cust-w-50">
          <div class="col-12">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">Hora de inicio</div>
              </div>
              <input
                id="text3"
                name="text3"
                type="time"
                class="form-control"
                required="required"
                v-model="hInicioNewActividad"
              />
            </div>
          </div>
        </div>
        <div class="form-group row p-1 cust-w-50">
          <div class="col-12">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">Hora de fin</div>
              </div>
              <input
                id="text3"
                name="text3"
                type="time"
                class="form-control"
                required="required"
                v-model="hFinNewActividad"
              />
            </div>
          </div>
        </div>
        <div class="form-group row p-1 cust-w-50">
          <div class="col-12">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">Periodo Academico</div>
              </div>
              <input
                id="text4"
                name="text4"
                placeholder="ej: 2022-2"
                type="text"
                class="form-control"
                required="required"
                v-model="pAcademicoNewActividad"
              />
            </div>
          </div>
        </div>
        <div class="form-group row p-1 cust-w-50">
          <div class="col-12">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">Encargad@</div>
              </div>
              <input
                id="text5"
                placeholder="Campo Automatico"
                disabled
                name="text5"
                type="text"
                required="required"
                class="form-control"
                v-model="encargadoNewActividad"
              />
            </div>
          </div>
        </div>

        <div v-show="showbtnCrear" class="form-group row p-1">
          <div class="col-12">
            <button
              name="submit"
              type="button"
              class="btn btn-primary"
              @click="crearActividad"
            >
              Crear Actividad
            </button>
          </div>
        </div>
        <div v-show="showbtnModificar" class="form-group row p-1">
          <div class="col-12">
            <button
              name="submit"
              type="button"
              class="btn btn-primary"
              @click="modificarActividad"
            >
              Modificar Actividad
            </button>
          </div>
        </div>
      </form>
    </div>

    <vue-good-table
      id="tabla-actividades"
      v-show="showListar"
      :columns="columns"
      :rows="rows"
      :fixed-header="true"
      max-height="alturaTabla"
      :search-options="{ enabled: true, placeholder: 'Buscar...' }"
      :select-options="{
        enabled: true,
        selectionText: 'Actividades Seleccionadas',
        clearSelectionText: 'limpiar',
      }"
      @on-selected-rows-change="selection"
      :pagination-options="{
        enabled: showPagination,
      }"
    />

    <div v-show="showAsistencia" class="asistencia-container">
      <ul class="lista-asistencia">
        <li
          v-for="item in estudiantesInscritos"
          :key="item._id"
          @click="asistir(item._id)"
        >
          <div class="row-asistencia">
            <h6>{{ item.nombreparticipante }}</h6>
            <i   style="display:flex;justify-content:center;align-items:center;">{{item.asistenciaText}} <i :id="'icon' + item._id" :class="readClass(item.asistencia)"></i></i>
          </div>
          <hr />
        </li>
       <li
        >
          <div class="row-asistencia justify-content-center" >
            <h6>Has llegado al final.</h6>
            
          </div>
          
        </li>
      </ul>
      <div class="btnGuardarAsistencia">
        <button @click="actualizarAsistencia">Guardar</button>
      </div>
    </div>
  </div>
</template>

<script>
import ApiUsers from "@/logic/ApiUsers.js";
import localToken from "@/logic/localToken.js";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
export default {
  name: "DesarrolloFragment",
  data: function () {
    return {
      asistIcon: "x",
      showCrear: false,
      showListar: true,
      showbtnCrear: true,
      showbtnModificar: false,
      showPagination: true,
      showAsistencia: false,
      dataUser: null,
      alturaTabla: "80vh",
      nombreNewActividad: null,
      lugarNewActividad: null,
      fInicioNewActividad: null,
      fFinNewActividad: null,
      hInicioNewActividad: null,
      hFinNewActividad: null,
      pAcademicoNewActividad: null,
      encargadoNewActividad: null,
      idEncargadoNewActividad: null,
      imagenNewActividad: null,
      estudiantesInscritos: [],
      language: {
        search: {
          placeholder: "🔍 Buscar...",
        },
      },

      columns: [
        { label: "Nombre", field: "nombre" },
        { label: "Inscritos", field: "inscritos" },
        { label: "Fecha", field: "fecha" },
        { label: "Hora", field: "hora" },
        { label: "Lugar", field: "lugar" },
        { label: "Periodo", field: "periodo" },
        { label: "Responsable", field: "encargado" },
      ],
      rows: [],
      seleccionado: [],
     
    };
  },

  methods: {
    
    async actualizarAsistencia() {
      try {
        await ApiUsers.modificarParticipantesActividadDH(
          this.seleccionado[0]._id,
          localToken.getToken(),
          this.estudiantesInscritos
        );
        this.showAsistencia = false;
        this.showListar = true;
      } catch (error) {
        console.log(error);
      }
    },
    readClass(info) {
      if (info == false) {
        
        return "bx bxs-user-x";
      } else {
        
        return "bx bxs-user-check";
      }
    },
    toggle(valor) {
      if (valor == "crear") {
        this.showCrear = true;
        this.showListar = false;
        this.showAsistencia = false;
        this.limpiarCampos();
      } else {
        if (valor == "listar") {
          this.showCrear = false;
          this.showListar = true;
          this.showAsistencia = false;
        }
      }
    },
    async crearActividad() {
      try {
        if (
          this.nombreNewActividad == null ||
          this.lugarNewActividad == null ||
          this.fInicioNewActividad == null ||
          this.fFinNewActividad == null ||
          this.hInicioNewActividad == null ||
          this.hFinNewActividad == null ||
          this.pAcademicoNewActividad == null
        ) {
          alert("Todos los campos son requeridos");
        } else {
          if (
            Date.parse(this.fInicioNewActividad) >
            Date.parse(this.fFinNewActividad)
          ) {
            alert("La fecha de inicio no puede anterior a la fecha de fin.");
          } else {
            if (
              Date.parse(this.fInicioNewActividad) ==
                Date.parse(this.fFinNewActividad) &&
              this.hInicioNewActividad > this.hFinNewActividad
            ) {
              alert("La hora de inicio no puede ser mayor a la hora de fin.");
            } else {
              await this.informacionUsuario();
             
              this.encargadoNewActividad = this.dataUser.nombre;
              this.idEncargadoNewActividad = this.dataUser.numeroidentificacion;
              const token = localToken.getToken();
              const actividadTemp = {
                nombre: this.nombreNewActividad,
                lugar: this.lugarNewActividad,
                fechainicio: this.fInicioNewActividad,
                fechafin: this.fFinNewActividad,
                horainicio: this.hInicioNewActividad,
                horafin: this.hFinNewActividad,
                periodoacademico: this.pAcademicoNewActividad,
                encargadoprincipal: this.encargadoNewActividad,
                imagen: this.imagenNewActividad,
                idencargadoprincipal:this.idEncargadoNewActividad,
                token: token,
              };

              const respuesta = await ApiUsers.crearActividadDH(actividadTemp);
              console.log(respuesta);

              alert("Actividad creada satisfactoriamente.");
              this.obtenerActividades();
              this.limpiarCampos();
            }
          }
        }

        //
      } catch (error) {
        console.error(error);
      }
    },
    async asistencia() {
      try {
        await this.informacionUsuario();
        
        if (this.dataUser.numeroidentificacion != this.seleccionado[0].idencargado) {
          alert("Solo el creador de la actividad puede registrar asistencia.");
        }else{
        this.showListar = false;
        this.showAsistencia = true;
        const resp = await ApiUsers.getEstudianteActividadDH(
          this.seleccionado[0]._id,
          localToken.getToken()
        );
        this.estudiantesInscritos = resp.data;
        for (const key in this.estudiantesInscritos) {
          if(this.estudiantesInscritos[key].asistencia){
            this.estudiantesInscritos[key].asistenciaText="Asistio "
          }else{
             this.estudiantesInscritos[key].asistenciaText="NO asistio "
          }
          
        }
        }
      } catch (error) {
        console.log(error);
      }
    },
    asistir(code) {
      for (let i = 0; i < this.estudiantesInscritos.length; i++) {
        if (this.estudiantesInscritos[i]._id == code) {
          this.estudiantesInscritos[i].asistencia =
            !this.estudiantesInscritos[i].asistencia;
          const icono = document.getElementById(`icon${code}`);
          
          if (this.estudiantesInscritos[i].asistencia == true) {
            
            this.estudiantesInscritos[i].asistenciaText="Asistio "
            icono.classList.remove("bxs-user-x");
            icono.classList.add("bxs-user-check");
          } else {
            
            this.estudiantesInscritos[i].asistenciaText="NO asistio "
            icono.classList.remove("bxs-user-check");
            icono.classList.add("bxs-user-x");
          }
        }
      }
    },
    async descargar() {
      try {
        for (let i = 0; i < this.seleccionado.length; i++) {
          await ApiUsers.descargarIADH(this.seleccionado[i]._id,localToken.getToken());
        }
        
      } catch (error) {
        console.log(error);
      }
    },
    async obtenerActividades() {
      try {
        const arrayTemp = [];
        const respuesta = await ApiUsers.getActividadesDH();
        for (const iterator of respuesta.data) {
          let objTemp = {};
          objTemp._id = iterator._id;
          objTemp.nombre = iterator.nombre;
          objTemp.fecha = `${iterator.fechainicio} - ${iterator.fechafin}`;
          objTemp.hora = `${iterator.horainicio} - ${iterator.horafin}`;
          objTemp.inscritos = `${iterator.participantes.length}`;
          objTemp.lugar = `${iterator.lugar}`;
          objTemp.encargado = `${iterator.encargadoprincipal}`;
          objTemp.periodo = `${iterator.periodoacademico}`;
          objTemp.idencargado = `${iterator.idencargadoprincipal}`;
          arrayTemp.push(objTemp);
        }
        this.rows = arrayTemp;
      } catch (error) {
        console.error(error);
      }
    },
    limpiarCampos() {
      this.nombreNewActividad = null;
      this.lugarNewActividad = null;
      this.fInicioNewActividad = null;
      this.fFinNewActividad = null;
      this.hInicioNewActividad = null;
      this.hFinNewActividad = null;
      this.pAcademicoNewActividad = null;
      this.encargadoNewActividad = null;
      this.imagenNewActividad = null;
    },
    async borrarActividad() {
      try {
        if (this.seleccionado.length == 0) {
          alert("no se ha seleccionado ninguna actividad para eliminar.");
        } else {
          for (let i = 0; i < this.seleccionado.length; i++) {
            const respuesta = await ApiUsers.eliminarActividadDH(
              this.seleccionado[i]._id,
              { token: localToken.getToken() }
            );
            console.log(respuesta);
            alert(`eliminado ${this.seleccionado[i]._id}`);
          }
          this.obtenerActividades();
        }
      } catch (error) {
        console.error(error.response);
      }
    },
    selection(params) {
      console.log(params.selectedRows);
      this.seleccionado = params.selectedRows;
    },
    async preModificarActividad() {
      try {
        this.btnCrearModificar = "Modificar Actividad";
        this.showListar = false;
        this.showCrear = true;
        const respuesta = await ApiUsers.getActividadDH(
          this.seleccionado[0]._id
        );
        this.nombreNewActividad = respuesta.data.nombre;
        this.lugarNewActividad = respuesta.data.lugar;
        this.imagenNewActividad = respuesta.data.imagen;
        this.fInicioNewActividad = respuesta.data.fechainicio;
        this.fFinNewActividad = respuesta.data.fechafin;
        this.hInicioNewActividad = respuesta.data.horainicio;
        this.hFinNewActividad = respuesta.data.horafin;
        this.pAcademicoNewActividad = respuesta.data.periodoacademico;
        this.encargadoNewActividad = respuesta.data.encargadoprincipal;
        this.idEncargadoNewActividad = respuesta.data.idencargadoprincipal;
        this.showbtnCrear = false;
        this.showbtnModificar = true;
        this.showbtnModificar;
      } catch (error) {
        console.error(error);
      }
    },
    async modificarActividad() {
      try {
        const token = localToken.getToken();
        const temporal = {
          nombre: this.nombreNewActividad,
          lugar: this.lugarNewActividad,
          imagen: this.imagenNewActividad,
          fechainicio: this.fInicioNewActividad,
          fechafin: this.fFinNewActividad,
          horainicio: this.hInicioNewActividad,
          horafin: this.hFinNewActividad,
          periodoacademico: this.pAcademicoNewActividad,
          encargadoprincipal: this.encargadoNewActividad,
          idencargadoprincipal: this.idEncargadoNewActividad,
          token: token,
        };
        await ApiUsers.modificarActividadDH(this.seleccionado[0]._id, temporal);
        this.showbtnCrear = true;
        this.showbtnModificar = false;
        alert("Actividad Modificada Exitosamente.");
        this.limpiarCampos();
        this.obtenerActividades();
      } catch (error) {
        console.error(error);
      }
    },
    async informacionUsuario() {
      
        await ApiUsers.getUserId({ token: localToken.getToken() }).then(
          (info) => {
            this.dataUser = info.data;
          }
        ).catch (error => {
       if (error.status == 401) {
          console.log(`status 401`);
        } else {
          console.error(`${error.response.status} line 596`);
        }
      })
    },
    ShowLoadAnimation(valor){
      if(valor){
         this.$store.dispatch('setLoadAnimationAction',{s:true})
      }else{
        setTimeout(() => {
        this.$store.dispatch('setLoadAnimationAction',{s:false})
      }, 500); 
      }
      
    },
  },
  created() {
    if (localToken.getToken() == "null") {
      this.$router.push("/login");
    } else {
     this.ShowLoadAnimation(true)
    }
    if (/android/i.test(navigator.userAgent)) {
      this.columns = [
        { label: "Nombre", field: "nombre" },
        { label: "Inscritos", field: "inscritos" },
        { label: "Responsable", field: "encargado" },
      ];
      this.showPagination = true;
      this.alturaTabla = "50px";
    }
    if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
      this.columns = [
        { label: "Nombre", field: "nombre" },
        { label: "Inscritos", field: "inscritos" },
        { label: "Responsable", field: "encargado" },
      ];
      this.showPagination = true;
      this.alturaTabla = "50px";
    }
  },
  mounted() {
    this.obtenerActividades();
    this.ShowLoadAnimation(false);
  },
  components: {
    VueGoodTable,
  },
};
</script>

<style scoped>
.bxs-user-check {
  color: rgb(68, 201, 68);
  margin-left: 5px;
  font-size: 20px;
}
.bxs-user-x {
  color: red;
  margin-left: 5px;
  font-size: 20px;
}
.asistencia-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0px;
  padding: 0px;
}
.lista-asistencia {
  display: flex;
  flex-direction: column;
  width: 100%;
  
  padding: 5px;
  list-style-type: none;
  margin-bottom: 10px;
  height: 60vh;
  max-height: 60vh;
  overflow: auto;
}
.row-asistencia {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-left: 10vw;
  padding-right: 10vw;
}
.row-asistencia:hover{cursor: pointer;}
.btnGuardarAsistencia {
  display: flex;
  margin-top: 20px;
  
  height: 7vh;
  justify-content: center;
}
.btnGuardarAsistencia > button {
  border: none;
  border-radius: 5px;
  width: 80%;
}
.btnGuardarAsistencia > button:hover {
  border: 1px solid gray;
  background-color: #26d45a34;
}
.subContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 3px;
}
.contenedorHeaderDH {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px 6px 12px #c5c5c55d;
  justify-content: flex-start;
  align-items: center;
  margin: 0px;
  height: 100vh;
  overflow-y: scroll;
  padding: 5px;
}

.subSection {
  display: flex;
  justify-content: center;
  width: 98%;
  height: min-content;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 3px;
  border-radius: 2px;
  background-color: rgba(228, 36, 19, 0.05);
  color: #090909;
  padding: 10px 10px 10px 10px;
  transition: all 0.3s;
  border: 1px solid rgba(128, 128, 128, 0.3);
}

.subSection:hover {
  cursor: pointer;
}

.subSection:active {
  box-shadow: 0px 6px 16px -12px #838181, -4px -4px 12px #ffffff;
}

.crearform {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.form-group {
  width: 100%;
}
.listar {
  padding: 10px;
}
.btn-actividades {
  margin: 3px;
  padding: 3px;
  border: 1px solid rgba(128, 128, 128, 0.336);
  border-radius: 3px;
  background-color: rgba(228, 36, 19, 0.05);
}
.btn-actividades:hover {
  cursor: pointer;
  background-color: rgba(228, 36, 19, 0.1);
}
.botonera {
  justify-content: space-around;
  background-color: rgba(228, 36, 19, 0);
  border: none;
  flex-wrap: wrap;
}

#tabla-actividades {
  display: flex;
  width: 100vw;
  padding: 10px;
  margin-bottom: 70px;
}
@media (min-width: 768px) {
  .btnGuardarAsistencia{
    height: 8vh;
    margin: 20px;
  }
.lista-asistencia {
  height: 65vh;
  max-height: 65vh;
  padding: 20px;
}
  .row-asistencia {
   
  padding-left: 30px;
  padding-right: 30px;
}
  .botonera {
    justify-content: flex-start;
  }
  #tabla-actividades {
    display: block;
    margin-bottom: 0px;
    width: 100%;
  }
  .form-group {
    width: 33%;
  }
  .cust-w-50 {
    width: 50%;
  }
  .crearform {
    justify-content: space-between;
  }
}
</style>