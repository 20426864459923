<template>
  <section id="team" class="team section-bg">
    <Alerta
      class="alerta"
      v-show="$store.state.showrutadescape"
      :titulo="smsTitle"
      :cuerpo="smsBody"
      :tBoton="smsBText"
    />
    <div class="container">
      <div class="section-title">
        <h2>Recreacion y Deporte</h2>
        <p>Inscripcion a Gymnasio.</p>
      </div>
      <div class="input-grupo-container">
        <div v-show="showOneSection" class="input-grupo">
          <h5>Rol:</h5>
          <select class="custom-select" v-model="seleccion">
            <!-- <option selected>Selecione</option> -->
            <option value="estudiante">Estudiante</option>
            <option value="docente">Docente</option>
            <option value="administrativo">Administrativo</option>
            <option value="egresado">Egresado</option>
            <option value="estudiante-intercambio">
              Estudiante Intercambio
            </option>
            <option value="invitado-externo">Invitado Externo</option>
          </select>
          <div class="input-group-append p-3">
            <button
              class="btn btn-outline-primary"
              type="button"
              @click="selectRol"
            >
              Siguiente
            </button>
          </div>
        </div>
      </div>

      <div v-show="showInputCodigo" class="container p-2 col-lg-6">
        <div>
          <div class="form-group">
            <label for="codEs">Codigo del Estudiante</label>
            <input
              min="0"
              type="number"
              class="form-control"
              id="codEs"
              v-model="codigoEstudiante"
            />
          </div>
        </div>
        <div class="input-group-append p-3">
          <button
            class="btn btn-outline-primary"
            type="button"
            @click="loadEstudiante"
          >
            Siguiente
          </button>
        </div>
      </div>

      <div v-show="showInputDatos" class="container p-2 col-lg-6">
        <form class="row">
          <!-- nombre -->
          <div class="form-group col-6">
            <label>Nombre</label>
            <input
              type="text"
              class="form-control"
              placeholder=""
              v-model="nombre"
            />
          </div>

          <div class="form-group col-6">
            <label>Apellido</label>
            <input
              type="text"
              class="form-control"
              placeholder=""
              v-model="apellido"
            />
          </div>
          <div class="form-group">
            <label for="exampleFormControlInput1"># Identificacion</label>
            <input
              min="0"
              type="number"
              class="form-control"
              v-model="identificacion"
            />
          </div>
          <div class="form-group">
            <label for="exampleFormControlInput1">Programa/Dependencia</label>
            <input
              type="text"
              class="form-control"
              placeholder=""
              v-model="programaDependecia"
            />
          </div>
          <div class="form-group col-4">
            <label for="exampleFormControlInput1">Edad</label>
            <input
              type="number"
              class="form-control"
              placeholder=""
              v-model="edad"
            />
          </div>
          <div class="form-group col-4">
            <label for="exampleFormControlInput1">EPS</label>
            <input
              type="text"
              class="form-control"
              placeholder=""
              v-model="eps"
            />
          </div>
          <div v-show="seleccion == 'estudiante'" class="form-group col-4">
            <label for="exampleFormControlInput1">Codigo</label>
            <input
              type="text"
              class="form-control"
              placeholder=""
              v-model="codigoEstudiante"
              disabled
            />
          </div>
          <div class="form-group col-4">
            <label for="exampleFormControlInput1">Periodo</label>
            <input
              type="text"
              class="form-control"
              placeholder=""
              v-model="periodo"
            />
          </div>
          <div v-show="seleccion == 'estudiante'" class="form-group col-4">
            <label for="exampleFormControlInput1">Semestre</label>
            <input
              type="text"
              class="form-control"
              placeholder=""
              v-model="semestre"
            />
          </div>
          <div class="form-group col-4">
            <label for="exampleFormControlInput1">Jornada</label>
            <input
              type="text"
              class="form-control"
              placeholder=""
              v-model="jornada"
            />
          </div>
          <div class="form-group col-8">
            <label for="exampleFormControlInput1">Email</label>
            <input
              type="email"
              class="form-control"
              placeholder=""
              v-model="email"
            />
          </div>
          <div class="form-group col-10">
            <label for="exampleFormControlInput1">Telefono</label>
            <input
              type="number"
              class="form-control"
              placeholder=""
              v-model="telefono"
            />
          </div>
          <div class="form-group col-4">
            <label for="exampleFormControlInput1">Peso</label>
            <input
              type="number"
              class="form-control"
              placeholder=""
              v-model="peso"
            />
          </div>
          <div class="form-group col-4">
            <label for="exampleFormControlInput1">Estatura</label>
            <input
              type="number"
              class="form-control"
              placeholder=""
              v-model="estatura"
            />
          </div>
          <div class="form-group col-4">
            <label for="exampleFormControlInput1">IMC</label>
            <input
              type="number"
              class="form-control"
              placeholder=""
              v-model="imc"
            />
          </div>
          <div class="form-group col-4">
            <label for="exampleFormControlInput1">Medida Biceps (cm)</label>
            <input
              type="number"
              class="form-control"
              placeholder=""
              v-model="Mbiceps"
            />
          </div>
          <div class="form-group col-4">
            <label for="exampleFormControlInput1">Medida Cintura (cm)</label>
            <input
              type="number"
              class="form-control"
              placeholder=""
              v-model="Mcintura"
            />
          </div>
          <div class="form-group col-4">
            <label for="exampleFormControlInput1">Medida Cadera (cm)</label>
            <input
              type="number"
              class="form-control"
              placeholder=""
              v-model="Mcadera"
            />
          </div>
          <div class="form-group col-4">
            <label for="exampleFormControlInput1">Medida Muslos (cm)</label>
            <input
              type="number"
              class="form-control"
              placeholder=""
              v-model="Mmuslo"
            />
          </div>
          <div class="form-group col-4">
            <label for="exampleFormControlInput1">Medida Pierna (cm)</label>
            <input
              type="number"
              class="form-control"
              placeholder=""
              v-model="Mpierna"
            />
          </div>
          <div class="form-group col-4">
            <label for="exampleFormControlInput1"
              >Medida Pantorrilla (cm)</label
            >
            <input
              type="number"
              class="form-control"
              placeholder=""
              v-model="Mpantorrilla"
            />
          </div>
          <div class="form-group col-4">
            <label for="exampleFormControlInput1">Medida Pecho (cm)</label>
            <input
              type="number"
              class="form-control"
              placeholder=""
              v-model="Mpecho"
            />
          </div>
          <div class="form-group col-4">
            <label for="exampleFormControlInput1">Medida Triceps (cm)</label>
            <input
              type="number"
              class="form-control"
              placeholder=""
              v-model="Mtriceps"
            />
          </div>
          <div class="form-group col-4">
            <label for="exampleFormControlInput1"
              >Medida Sub. Escapular (cm)</label
            >
            <input
              type="number"
              class="form-control"
              placeholder=""
              v-model="Mescapula"
            />
          </div>
          <div class="form-group col-4">
            <label for="exampleFormControlInput1">Medida Abdomen (cm)</label>
            <input
              type="number"
              class="form-control"
              placeholder=""
              v-model="Mabdomen"
            />
          </div>
          <div class="form-group col-4">
            <label for="exampleFormControlInput1"
              >Medida Pliegue Muslo (cm)</label
            >
            <input
              type="number"
              class="form-control"
              placeholder=""
              v-model="MPmuslo"
            />
          </div>
          <div class="form-group col-4">
            <label for="exampleFormControlInput1"
              >Medida Pliegue Pierna (cm)</label
            >
            <input
              type="number"
              class="form-control"
              placeholder=""
              v-model="MPpierna"
            />
          </div>
          <div class="form-group">
            <label for="exampleFormControlInput1"
              >Objetivo del Entrenamiento</label
            >
            <input
              type="text"
              class="form-control"
              placeholder=""
              v-model="objetivo"
            />
          </div>
          <div class="form-group col-6">
            <label for="exampleFormControlInput1"
              >Antecedentes Familiares</label
            >
            <input
              type="text"
              class="form-control"
              placeholder=""
              v-model="antecedentesF"
            />
          </div>
          <div class="form-group col-6">
            <label for="exampleFormControlInput1">Enfermedades Padecidas</label>
            <input
              type="text"
              class="form-control"
              placeholder=""
              v-model="enfermedades"
            />
          </div>
          <div class="form-group col-6">
            <label for="exampleFormControlInput1">Cirugias</label>
            <input
              type="text"
              class="form-control"
              placeholder=""
              v-model="cirugias"
            />
          </div>

          <div class="form-group col-12 mt-4">
            <input type="checkbox" v-model="tyc" />
            <span>
              Acepto la
              <a
                href="https://www.unisinu.edu.co/politica-de-privacidad/"
                target="_blank"
              >
                Política de tratamiento de datos
              </a>
              de la Universidad del Sinú – Elías Bechara Zainúm y Doy fe de la
              veracidad de la información suministrada sobre mis antecedentes
              médicos personales y familiares. Me hago responsable de cada hecho
              que ocurra en caso de no respetar las indicaciones dadas por los
              instructores de acuerdo a mi condición de salud y si decido
              entrenar sin realizarme la valoración inicial
            </span>
          </div>
        </form>
        <div class="input-group-append p-3">
          <button
            class="btn btn-outline-primary"
            type="button"
            @click="sendReq"
          >
            Siguiente
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ApiUsers from "@/logic/ApiUsers.js";
import Alerta from "@/components/general/Alerta.vue";
//const ObjectId = require('mongo-objectid');
export default {
  name: "InscripGym",
  data: function () {
    return {
      // v-models
      nombre: null,
      apellido: null,
      identificacion: null,
      programaDependecia: null,
      edad: null,
      eps: null,
      codigoEstudiante: null,
      periodo: null,
      semestre: null,
      jornada: null,
      email: null,
      telefono: null,
      peso: null,
      estatura: null,
      imc: null,
      Mbiceps: null,
      Mcintura: null,
      Mcadera: null,
      Mmuslo: null,
      Mpierna: null,
      Mpantorrilla: null,
      Mpecho: null,
      Mtriceps: null,
      Mescapula: null,
      Mabdomen: null,
      MPmuslo: null,
      MPpierna: null,
      objetivo: null,
      antecedentesF: null,
      enfermedades: null,
      cirugias: null,
      tyc: null,
      // -----------------------------

      seleccion: "estudiante",

      showInputCodigo: false,
      showInputDatos: false,
      showActividades: false,
      showOneSection: true,
      showNone: false,
      smsTitle: "Mensaje",
      smsBody: "Cuerpo",
      smsBText: "Clickme",

      smsShow: false,
      actividades: [],
    };
  },
  methods: {
    selectRol() {
      if (this.seleccion == "estudiante") {
        this.showInputCodigo = true;
        this.showInputDatos = false;
        this.showOneSection = false;
      } else {
        this.showInputDatos = true;
        this.showInputCodigo = false;
        this.showOneSection = false;
      }
    },
    async loadEstudiante() {
      if (this.codigoEstudiante == null) {
        this.smsBody = "Debes ingresar un codigo.";
        this.smsBText = "Salir";
        this.$store.state.valueSRE = true;
        this.$store.state.rutadeescape = "/servicios";
        this.$store.dispatch("actionShowRutaDeEscape");
      } else {
        try {
          const datos = await ApiUsers.existeEstudiante(this.codigoEstudiante);
          if (datos.data == true) {
            this.showInputCodigo = false;
            this.showInputDatos = true;
            const r = await ApiUsers.getEstudiante(this.codigoEstudiante);
            console.log(r.status);
            this.nombre = r.data[0].nombre;
            this.apellido = r.data[0].apellido;
            this.identificacion = r.data[0].numeroidentificacion;
            this.programaDependecia = r.data[0].programa;
            //this.periodo = r.data[0].periodo;
            this.semestre = r.data[0].semestre;
            this.email = r.data[0].correoelectronico;
          } else {
            this.smsBody = "Este codigo no registra en la base de datos.";
            this.smsBText = "Salir";
            this.$store.state.valueSRE = true;
            this.$store.state.rutadeescape = "/servicios";
            this.$store.dispatch("actionShowRutaDeEscape");
          }
        } catch (error) {
          console.log(error);
          this.smsBody = "Ocurrio un error interno, intente mas tarde.";
          this.smsBText = "Salir";
          this.$store.state.valueSRE = true;
          this.$store.state.rutadeescape = "/servicios";
          this.$store.dispatch("actionShowRutaDeEscape");
        }
      }
    },
    async sendReq() {
      if (
        this.nombre == null ||
        this.apellido == null ||
        this.identificacion == null ||
        this.programaDependecia == null ||
        this.edad == null ||
        this.eps == null ||
        //this.codigoEstudiante == null ||
        this.periodo == null ||
        //this.semestre == null ||
        this.jornada == null ||
        this.email == null ||
        this.telefono == null ||
        this.peso == null ||
        this.estatura == null ||
        this.imc == null ||
        this.Mbiceps == null ||
        this.Mcintura == null ||
        this.Mcadera == null ||
        this.Mmuslo == null ||
        this.Mpierna == null ||
        this.Mpantorrilla == null ||
        this.Mpecho == null ||
        this.Mtriceps == null ||
        this.Mescapula == null ||
        this.Mabdomen == null ||
        this.MPmuslo == null ||
        this.MPpierna == null ||
        this.objetivo == null ||
        this.antecedentesF == null ||
        this.enfermedades == null ||
        this.cirugias == null ||
        this.tyc == null ||
        this.tyc == false
      ) {
        this.smsBody = "Todos los campos deben ser diligenciados.";
        this.smsBText = "Salir";
        this.$store.state.valueSRE = true;
        this.$store.state.rutadeescape = "/servicios";
        this.$store.dispatch("actionShowRutaDeEscape");
      } else {
        if (
          this.seleccion == "estudiante" &&
          (this.codigoEstudiante == null || this.semestre == null)
        ) {
          this.smsBody = "Todos los campos deben ser diligenciados.";
          this.smsBText = "Salir";
          this.$store.state.valueSRE = true;
          this.$store.state.rutadeescape = "/servicios";
          this.$store.dispatch("actionShowRutaDeEscape");
        } else {
          let recado = {
            nombreCompleto: `${this.nombre} ${this.apellido}`,
            numeroIdentificacion: this.identificacion,
            tipoPersona: this.seleccion,
            programaDependencia: this.programaDependecia,
            edad: this.edad,
            eps: this.eps,
            codigo: this.codigoEstudiante,
            periodo: this.periodo,
            semestre: this.semestre,
            jornada: this.jornada,
            correoElectronico: this.email,
            numeroTelefono: this.telefono,

            valoracionGeneral: {
              peso: this.peso,
              talla: this.estatura,
              imc: this.imc,
              antropometria: {
                biceps: this.Mbiceps,
                cintura: this.Mcintura,
                caderas: this.Mcadera,
                muslos: this.Mmuslo,
                pierna: this.Mpierna,
                pantorrilla: this.Mpantorrilla,
                pecho: this.Mpecho,
              },
              pliegues: {
                triceps: this.Mtriceps,
                subEscapular: this.Mescapula,
                abdomen: this.Mabdomen,
                muslo: this.MPmuslo,
                pierna: this.MPpierna,
              },
              objetivoEntrenamiento: this.objetivo,
            },

            antecedentesMedicos: {
              antecedentesFamiliares: this.antecedentesF,
              enfermedadesPadecidas: this.enfermedades,
              cirugias: this.cirugias,
            },

            tyc: this.tyc,
          };

          try {
            const resp = await ApiUsers.sendInscriptionGYM(recado);
            console.log(resp);
            alert("Solicitud enviado Satisfactoriamente.");
            this.$router.push("/servicios");
          } catch (error) {
            console.log(error);
            this.smsBody = `${error.response.data}`;
            this.smsBText = "Salir";
            this.$store.state.valueSRE = true;
            this.$store.state.rutadeescape = "/servicios";
            this.$store.dispatch("actionShowRutaDeEscape");
          }
        }
      }
    },
  },
  created() {},

  components: {
    Alerta,
  },
};
</script>

<style scoped>
.input-grupo-container {
  display: flex;
  width: 100%;
  justify-content: center;
}
.input-grupo {
  display: flex;
  flex-direction: column;
  width: 90%;
  justify-content: center;
}
.input-grupo > select {
  min-height: 5vh;
  border-radius: 5px;
  background-color: white;
  padding: 5px;
}
.input-grupo > div {
  display: flex;
  justify-content: center;
}
.input-grupo > div > button {
  margin-top: 6vh;
  width: 70%;
}
.activ-img {
  min-height: 180px;
  max-height: 180px;
}
.mensaje {
  height: 80vh;
  width: 90vw;
  position: fixed;
  top: calc(50% - 200px);
  left: calc(50% - (90vw / 2));
}
.team {
  height: 100vh;
  min-height: 76vh;
  overflow: auto;
}

.alerta {
  z-index: 99;
}
.inscritos {
  width: min-content;

  padding-right: 0px;
}
@media (min-width: 768px) {
  .input-grupo-container {
    padding-left: 20vw;
    padding-right: 20vw;
  }
}
</style>