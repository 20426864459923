<template>
  <section id="hero" class="d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div
          class="
            col-lg-6
            pt-4 pt-lg-0
            order-2 order-lg-1
            d-flex
            flex-column
            justify-content-center
          "
        >
          <h1>SISTEMA DE GESTIÓN BIENESTAR UNIVERSITARIO</h1>
          <h2 class="parrafo-banner">
            La Oficina de Bienestar Universitario de la Universidad del Sinú
            “Elías Bechara Zainúm” promueve la formación integral a través del
            desarrollo social, físico, espiritual, psicoafectivo e intelectual
            de quienes conforman la comunidad académica creando espacios para el
            fortalecimiento de las dimensiones del ser humano.
          </h2>
          <div>
            <div class="btn-get-started scrollto" @click="organizar()">
              EXPLORAR
            </div>
            <!-- <router-link to="#procesos" class="btn-get-started scrollto">FORMATO DE CARACTERIZACIÓN</router-link> -->
          </div>
        </div>
        <div class="col-lg-6 order-1 order-lg-2 hero-img">
          <img
            :src="require('@/assets/img/hero-img-2.png')"
            class="img-fluid"
            alt=""
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Banner",
  methods: {
    organizar() {
      this.$store.state.newoptorgservices = "1";
      this.$store.dispatch("updatesetOptOrgServicesAction");
      this.$router.push("/servicios");
    },
  },
};
</script>

<style scoped>
.btn-get-started {
  margin-right: 5px;
  background-color: rgb(228, 35, 19, 0.7) !important;
  text-decoration: none;
}
.btn-get-started:hover {
  background-color: rgba(228, 36, 19) !important;
}
.parrafo-banner {
  text-align: justify;
}

.btn-get-started:hover {
  cursor: pointer;
}
</style>