<template>
  <header id="header" class="my-fixed-top">
    <div class="container d-flex align-items-center justify-content-between">
      <router-link to="/" class="logo"
        ><img
          :src="require('@/assets/img/logo-baner.png')"
          alt="logo-unisinu"
          class="img-fluid"
      /></router-link>

      <nav id="navbar" class="navbar">
        <ul>
          <li><router-link class="nav-link" to="/">Inicio</router-link></li>
          
          <li>
            <router-link class="nav-link" to="/servicios" >Procesos</router-link>
          </li>

          <li class="dropdown">
            <i class="bx bxs-down-arrow nav-link"></i>
            <ul>
              <li>
                <router-link class="dropdown" to="/login"
                  >Iniciar Sesión</router-link
                >
              </li>
            </ul>
          </li>
          <!-- <li class="dropdown"><a href="#"><i class='bx bxs-down-arrow'></i> <i class="bi bi-chevron-down"></i></a>
            <ul>
              <li><a href="#">Drop Down 1</a></li>
              <li class="dropdown"><a href="#"><span>Deep Drop Down</span> <i class="bi bi-chevron-right"></i></a>
                <ul>
                  <li><a href="#">Deep Drop Down 1</a></li>
                  <li><a href="#">Deep Drop Down 2</a></li>
                  <li><a href="#">Deep Drop Down 3</a></li>
                  <li><a href="#">Deep Drop Down 4</a></li>
                  <li><a href="#">Deep Drop Down 5</a></li>
                </ul>
              </li>
              <li><a href="#">Drop Down 2</a></li>
              <li><a href="#">Drop Down 3</a></li>
              <li><a href="#">Drop Down 4</a></li>
            </ul>
          </li> -->
        </ul>
        <i class="bi bi-list mobile-nav-toggle"></i>
      </nav>
      <!-- .navbar -->

      <nav class="navbar toggle-menu">
        <span @click="toggleoptions=!toggleoptions" class="toggle-button"><i class="bx bx-menu"></i></span>
      </nav>
    </div>
    <div v-if="toggleoptions" class="navbar">
      <ul class="toggle-options">
        <li>
          <router-link class="nav-link" to="#about"
            >Nuestra Plataforma</router-link
          >
        </li>
        <li>
          <router-link class="nav-link" to="/servicios">Procesos</router-link>
        </li>

        <li class="dropdown">
          <i class="bx bxs-down-arrow nav-link"></i>
          <ul>
            <li>
              <router-link class="dropdown" to="/login"
                >Iniciar Sesión</router-link
              >
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  data:function(){
    return{
      toggleoptions:false,
    }
  }
};
</script>

<style scoped>
.my-fixed-top{
  position: fixed;
  top: 0;
  min-width: 100vw;
}
#header {
  padding-top: 10px;
  padding-bottom: 10px;
  
}
.container{min-height: 60px;}
.nav-link {
  color: rgb(15, 57, 76);
}
.nav-link:hover {
  color: #e42313;
}
.dropdown {
  text-decoration: none;
  min-width: 100px;
  list-style: none;
}
.toggle-menu {
  display: none;
}
.toggle-options {
  display: none;
}

@media (min-width: 360px) and (max-width: 768px) {
  .container{min-height: 60px;}
  .toggle-menu {
    display: flex;
    flex-direction: column;
  }
  .toggle-options {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .toggle-options > li{
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
  .dropdown > i {
    padding-left: 30px;
  }
  .toggle-button {
    margin-right: 10px;
  }
  .bx-menu {
    transform: scale(1.9);
  }
  .bx-menu:hover {
    cursor: pointer;
    color: gray;
  }
  .bx-menu:focus {
    cursor: pointer;
    color: gray;
  }
}
</style>

