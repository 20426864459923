<template>
<div class="contenedor-alert" v-bind:style="{ 'background-color': color }">
  <strong>{{msj}}</strong>
</div>
</template>

<script>
export default {
name:"Alert",
props:{
    msj: String,
    color:String
},

}
</script>

<style scoped >
.contenedor-alert{
    display: flex;
    width: 100%;
    height: max-content;
    background-color: rgba(128, 128, 128, 0.26) ;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 5px;
    justify-content: center;
    align-items: center;
}
strong{
    text-align: center;
}

</style>