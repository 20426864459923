
export default {
  getToken() {
    return window.sessionStorage.getItem("vue-compilation-version-0003453");
  },
  setToken(valor) {
    window.sessionStorage.setItem("vue-compilation-version-0003453", valor);

   
  },
};
