import { render, staticRenderFns } from "./ReportesGenerales.vue?vue&type=template&id=10bf9171&scoped=true&"
import script from "./ReportesGenerales.vue?vue&type=script&lang=js&"
export * from "./ReportesGenerales.vue?vue&type=script&lang=js&"
import style0 from "./ReportesGenerales.vue?vue&type=style&index=0&id=10bf9171&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10bf9171",
  null
  
)

export default component.exports