<template>
  <section id="team" class="team section-bg">
    <Alerta
      class="alerta"
      v-show="$store.state.showrutadescape"
      :titulo="smsTitle"
      :cuerpo="smsBody"
      :tBoton="smsBText"
    />
    <div class="container">
      <div class="section-title">
        <h2>Cultura</h2>
        <p class="parrafo">
          La inscripcion a grupos.
        </p>
      </div>

      <div v-if="showCapture" class="container p-0 col-lg-10">
        <div>
          <div>
            <h4>{{ `Grupo: ${activitySelected.nombre}`}}</h4>
            
            
            <div  class="container-adicionador">
              
              <div class="adicionador">
                <select class="custom-item" v-model="seleccion">
                  <option value="0" selected hidden>---Seleccione---</option>
                  <option value="estudiante">Estudiante</option>
                  <option value="docente">Docente</option>
                  <option value="administrativo">Administrativo</option>
                  <option value="egresado">Egresado</option>
                  <option value="estudiante-intercambio">
                    Estudiante Intercambio
                  </option>
                  <option value="invitado-externo">Invitado Externo</option>
                </select>

                <input
                  class="custom-item"
                  v-show="seleccion != '0'"
                  type="text"
                  placeholder="Nombre Completo"
                  v-model="player.nombreCompleto"
                />

                <input
                  class="custom-item"
                  v-show="
                    seleccion == 'estudiante' ||
                    seleccion == 'estudiante-intercambio'
                  "
                  type="text"
                  placeholder="Programa"
                  v-model="player.programa"
                />
                <input
                  class="custom-item"
                  v-show="
                    seleccion == 'estudiante' ||
                    seleccion == 'egresado' ||
                    seleccion == 'estudiante-intercambio'
                  "
                  type="text"
                  placeholder="Jornada"
                  v-model="player.jornada"
                />
                <input
                  class="custom-item"
                  v-show="
                    seleccion == 'estudiante' ||
                    seleccion == 'estudiante-intercambio'
                  "
                  type="number"
                  placeholder="Codigo"
                  v-model="player.codigo"
                />
                <input
                  class="custom-item"
                  v-show="
                    seleccion == 'estudiante' ||
                    seleccion == 'estudiante-intercambio'
                  "
                  type="number"
                  placeholder="Semestre"
                  v-model="player.semestre"
                />

                <input
                  class="custom-item"
                  v-show="
                    seleccion == 'docente' || seleccion == 'administrativo'
                  "
                  type="text"
                  placeholder="Cargo"
                  v-model="player.cargo"
                />
                <input
                  class="custom-item"
                  v-show="seleccion == 'invitado-externo'"
                  type="text"
                  placeholder="Parentesco/Entidad"
                  v-model="player.parentescoEntidad"
                />

                <input
                  class="custom-item"
                  v-show="seleccion != '0'"
                  type="number"
                  placeholder="Identificacion"
                  v-model="player.identificacion"
                />
                <input
                  class="custom-item"
                  v-show="seleccion != '0'"
                  type="number"
                  placeholder="Celular"
                  v-model="player.celular"
                />
                
              </div>

              <div
                v-show="
                  (seleccion == 'estudiante' &&
                    player.nombreCompleto != null &&
                    player.identificacion != null &&
                    player.celular != null &&
                    player.jornada != null &&
                    player.programa != null &&
                    player.codigo != null &&
                    player.semestre != null) ||
                  (seleccion == 'docente' &&
                    player.nombreCompleto != null &&
                    player.identificacion != null &&
                    player.celular != null &&
                    player.cargo != null) ||
                  ( seleccion == 'administrativo' &&
                    player.nombreCompleto != null &&
                    player.identificacion != null &&
                    player.celular != null &&
                    player.cargo != null) ||
                  ( seleccion == 'egresado' &&
                    player.nombreCompleto != null &&
                    player.identificacion != null &&
                    player.celular != null &&
                    player.jornada != null) ||
                  (seleccion == 'estudiante-intercambio' &&
                    player.nombreCompleto != null &&
                    player.identificacion != null &&
                    player.celular != null &&
                    player.jornada != null &&
                    player.programa != null &&
                    player.codigo != null &&
                    player.semestre != null) ||
                  (seleccion == 'invitado-externo' &&
                    player.nombreCompleto != null &&
                    player.identificacion != null &&
                    player.celular != null &&
                    player.parentescoEntidad != null)
                "
                class="container-aceptar"
              >
                <i class="bx bxs-check-circle bx-lg" @click="sendData()"></i>
              </div>
            </div>
           
           
          </div>
        </div>
      </div>

      <div v-show="showGrupos" class="row">
        <div
          class="
            col-lg-3 col-md-6
            d-flex
            align-items-stretch
            justify-content-center
          "
          v-for="actividad in Grupos"
          :key="actividad._id"
        >
          <div class="member">
            <div class="member-img">
              <img :src="actividad.imagen" class="img-fluid activ-img" alt="" />
              <div class="social">
                <button
                  class="btn btn-success"
                  @click="preInscribir(actividad._id)"
                >
                  Inscripcion
                </button>
              </div>
            </div>
            <div class="member-info">
              <h4>{{ actividad.nombre }}</h4>
              <div class="row">
                <h6 class="col-3 d-flex">Periodo:</h6>
                <span class="col-8 pl-0">{{ actividad.periodoAcademico }}</span>
              </div>

              
              <div class="row">
                <h6 class="col-3 d-flex inscritos">Encargado</h6>
                <span class="col-8 pl-0">{{ actividad.encargado }}</span>
              </div>
              <div class="row">
                <h6 class="col-3 d-flex pr-0">Inscritos:</h6>
                <span class="col-8 pl-0">{{ actividad.inscritos.length }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-show="showNone">
          <h4>No hay Grupos, vuele luego.</h4>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ApiUsers from "@/logic/ApiUsers.js";
import localToken from "@/logic/localToken.js";
import Alerta from "@/components/general/Alerta.vue";

//const ObjectId = require('mongo-objectid');
export default {
  name: "InscripGrupos",
  data: function () {
    return {
     
      seleccion: "0",

      
      showGrupos: true,
      showCapture: false,
      showNone: false,

      smsTitle: "Mensaje",
      smsBody: "Cuerpo",
      smsBText: "Clickme",
      smsShow: false,


      Grupos: [],


      maximoPlayers:0,
      haveEquipo:false,
      nombreEquipo:null,
      haveCaptian: false,
      countPlayers: 0,
      player: {
        rol: null,
        nombreCompleto: null,
        programa: null,
        jornada: null,
        codigo: null,
        semestre: null,
        cargo: null,
        parentescoEntidad: null,
        identificacion: null,
        celular: null,
        
       
      },
      team: [],
      activitySelected: null,
    };
  },
  methods: {
    async sendData(){
        try {
            const rr = { nombreCompleto: this.player.nombreCompleto,
            tipoPersona: this.seleccion,
            programa: this.player.programa,
            jornada: this.player.jornada,
            codigo: this.player.codigo,
            semestre: this.player.semestre,
            parentescoEntidad: this.player.parentescoEntidad,
            numeroIdentificacion: this.player.identificacion,
            numeroCelular: this.player.celular}
         
          this.player.token = localToken.getToken();
          console.log(this.player);
          const r = await ApiUsers.sendInscriptionGroupsRD(this.activitySelected._id ,rr);
          alert(r.data);
          this.$router.push('/servicios');
        } catch (error) {
          console.error(error)
        }
    },
    deletePlayer(value) {
      console.log(value);
      const as = this.team.filter((item) => item.ident == value);
      if(as[0].cap==true) this.haveCaptian =false;
      this.team = this.team.filter((item) => item.ident !== value);
      this.countPlayers -=1;
    },

    
    
  

    async cargarGrupos() {
      try {
        const resp = await ApiUsers.RequestGroupsRD();
        for (const iterator of resp.data) {
          if(iterator.tipoGrupo =='cultural'){
             this.Grupos.push(iterator)
          }
        }
       
       
        
      } catch (error) {
        console.error(error);
        this.showNone = true;
      }
    },
    async preInscribir(idA) {
      console.log(idA);
      this.showGrupos = false;
      this.showCapture = true;
      const activAux = this.Grupos.filter(item=> item._id == idA);

      this.activitySelected = activAux[0];
      this.maximoPlayers= this.activitySelected.maximoJugadores
     
    },
  },
  created() {
    this.cargarGrupos();
  },
 

  components: {
    Alerta,
  },
};
</script>

<style scoped>
.header-team{
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.input-name-team{
  display: flex;
  width: 100%;
  height: 100%;
  padding: .3rem;
  min-height: 3rem;
  border-radius: .5rem;
  border: solid 0.01px rgba(128, 128, 128, 0.288);
}
.input-name-team:focus,.input-name-team:active{
  outline: none;
}
.container-name-team{
 display: flex;
 width: 100%;
 min-height: 2rem;
 align-items: center;
 margin-bottom: 2rem;
}
.list-team {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.list-team > .a,
.list-team > .b {
  display: flex;
  width: 50%;
  justify-content: space-around;
}
.container-list-team {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.custom-item {
  display: flex;
  margin: 0.3rem;
  height: 100%;
  min-height: 2rem;
  width: 30%;
  border: none;
  border-bottom: solid 0.01px rgba(128, 128, 128, 0.199);
  background-color: rgba(240, 248, 255, 0);
}
.capitan-item {
  border: none;
  justify-content: flex-start;
  align-items: center;
}
.capitan-item > input {
  margin: 0.5rem;
}
.capitan-item > input:hover {
  cursor: pointer;
}
.custom-item:focus,
.custom-item:active {
  outline: none;
}
.container-aceptar {
  display: flex;
  padding: 1rem;
  height: 100%;
}
.container-adicionador {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 5px rgba(128, 128, 128, 0.521);
  background-color: #fff;
  margin-bottom: 2rem;
}
.adicionador {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.input-grupo-container {
  display: flex;
  width: 100%;
  justify-content: center;
}
.input-grupo {
  display: flex;
  flex-direction: column;
  width: 90%;
  justify-content: center;
}
.input-grupo > select {
  min-height: 5vh;
  border-radius: 5px;
  background-color: white;
  padding: 5px;
}
.input-grupo > div {
  display: flex;
  justify-content: center;
}
.input-grupo > div > button {
  margin-top: 6vh;
  width: 70%;
}
.activ-img {
  min-height: 180px;
  max-height: 180px;
}
.mensaje {
  height: 80vh;
  width: 90vw;
  position: fixed;
  top: calc(50% - 200px);
  left: calc(50% - (90vw / 2));
}
.team {
  height: 100vh;
  min-height: 76vh;
  overflow: auto;
}

.alerta {
  z-index: 99;
}
.inscritos {
  width: min-content;

  padding-right: 0px;
}
@media (min-width: 768px) {
  .input-grupo-container {
    padding-left: 20vw;
    padding-right: 20vw;
  }
  .parrafo {
    display: flex;
    width: 100%;
    padding-left: 20%;
    padding-right: 20%;
  }
}
</style>