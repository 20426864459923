<template>
  <div class="dh-module">
    
    <InscripActivCU v-if="$store.state.publicdhselected == 1"/>
    <InscripGruposCU v-if="$store.state.publicdhselected == 2"/>
    
  </div>
</template>

<script>

import InscripActivCU from '@/components/publico/cu/InscripActivCU.vue'
import InscripGruposCU from '@/components/publico/cu/InscripGruposCU.vue'

export default {
  name: 'Cultura',
  components: {
   
    InscripActivCU,
    InscripGruposCU,

   
    
  },
  created(){
    if(this.$store.state.publicdhselected == null)
    this.$router.push("/servicios")
  }
}
</script>
<style scoped>
.dh-module{
  height: 100vh;
  padding-top: 0px;
  
}
</style>
