<template>
  <div class="container-salud">
    <h3 class="titulo-salud">Registro Atencion de Enfermeria</h3>
    <div class="contenido">
      <div class="option-item">
        <h4 class="option-item-label">Hora de inicio de la atencion:</h4>
        <input
          class="option-item-entrada"
          type="time"
          v-model="datosAtencion.hora"
        />
      </div>

      <div class="option-item">
        <h4 class="option-item-label">Sede:</h4>
        <input
          class="option-item-entrada"
          type="text"
          v-model="datosAtencion.sede"
          placeholder="Escriba aquí..."
        />
      </div>
      <div class="option-item">
        <h4 class="option-item-label">Lugar:</h4>
        <input
          class="option-item-entrada"
          type="text"
          v-model="datosAtencion.lugar"
          placeholder="Escriba aquí..."
        />
      </div>
      <div class="option-item">
        <h4 class="option-item-label">Nombre completo:</h4>
        <input
          class="option-item-entrada"
          type="text"
          v-model="datosAtencion.nombreApellidos"
          placeholder="Escriba aquí..."
        />
      </div>
      <div class="option-item">
        <h4 class="option-item-label">Edad:</h4>
        <input
          class="option-item-entrada"
          type="number"
          v-model="datosAtencion.edad"
          placeholder="Escriba aquí..."
        />
      </div>
      <div class="option-item">
        <h4 class="option-item-label">Sexo:</h4>
        <input
          class="option-item-entrada"
          type="text"
          v-model="datosAtencion.sexo"
          placeholder="Escriba aquí..."
        />
      </div>
      <div class="option-item">
        <h4 class="option-item-label">Numero de telefono:</h4>
        <input
          class="option-item-entrada"
          type="number"
          v-model="datosAtencion.numeroTelefono"
          placeholder="Escriba aquí..."
        />
      </div>
      <div class="option-item">
        <h4 class="option-item-label">Correo electronico:</h4>
        <input
          class="option-item-entrada"
          type="text"
          v-model="datosAtencion.correo"
          placeholder="Escriba aquí..."
        />
      </div>
      <div class="option-item">
        <h4 class="option-item-label">Direccion:</h4>
        <input
          class="option-item-entrada"
          type="text"
          v-model="datosAtencion.direccion"
          placeholder="Escriba aquí..."
        />
      </div>
      <div class="option-item">
        <h4 class="option-item-label">Numero de identificacion:</h4>
        <input
          class="option-item-entrada"
          type="number"
          v-model="datosAtencion.numeroIdentificacion"
          placeholder="Escriba aquí..."
        />
      </div>
      <div class="option-item">
        <h4 class="option-item-label">Seguridad social:</h4>
        <input
          class="option-item-entrada"
          type="text"
          v-model="datosAtencion.seguridadSocial"
          placeholder="Escriba aquí..."
        />
      </div>
      <div class="option-item">
        <h4 class="option-item-label">Tipo de persona:</h4>
        <select class="option-item-entrada" v-model="datosAtencion.tipoPersona">
          <option value="0" selected hidden>---Seleccione---</option>
          <option value="estudiante">Estudiante</option>
          <option value="estudianteIntercambio">
            Estudiante de intercambio
          </option>
          <option value="docente">Docente</option>
          <option value="administrativo">Administrativo</option>
          <option value="externo">Externo</option>
        </select>
      </div>
      <div
        class="option-item"
        v-show="datosAtencion.tipoPersona == 'estudiante'"
      >
        <h4 class="option-item-label">Programa:</h4>
        <input
          class="option-item-entrada"
          type="text"
          v-model="datosAtencion.programa"
          placeholder="Escriba aquí..."
        />
      </div>
      <div
        class="option-item"
        v-show="datosAtencion.tipoPersona == 'estudiante'"
      >
        <h4 class="option-item-label">Jornada:</h4>
        <input
          class="option-item-entrada"
          type="text"
          v-model="datosAtencion.jornada"
          placeholder="Escriba aquí..."
        />
      </div>
      <div class="option-item" v-show="datosAtencion.tipoPersona == 'docente'">
        <h4 class="option-item-label">Cargo:</h4>
        <input
          class="option-item-entrada"
          type="text"
          v-model="datosAtencion.cargo"
          placeholder="Escriba aquí..."
        />
      </div>
      <div
        class="option-item"
        v-show="datosAtencion.tipoPersona == 'estudiante'"
      >
        <h4 class="option-item-label">Codigo:</h4>
        <input
          class="option-item-entrada"
          type="number"
          v-model="datosAtencion.codigo"
          placeholder="Escriba aquí..."
        />
      </div>
      <div class="option-item">
        <h4 class="option-item-label">Nombre del acompañante:</h4>
        <input
          class="option-item-entrada"
          type="text"
          v-model="datosAtencion.nombreAcompañante"
          placeholder="Escriba aquí..."
        />
      </div>
      <div class="option-item">
        <h4 class="option-item-label">Numero de telefono del acompañante:</h4>
        <input
          class="option-item-entrada"
          type="number"
          v-model="datosAtencion.telefonoAcompañante"
          placeholder="Escriba aquí..."
        />
      </div>
      <div class="option-item">
        <h4 class="option-item-label">Nombre del padre:</h4>
        <input
          class="option-item-entrada"
          type="text"
          v-model="datosAtencion.nombrePadre"
          placeholder="Escriba aquí..."
        />
      </div>
      <div class="option-item">
        <h4 class="option-item-label">Numero de telefono del padre:</h4>
        <input
          class="option-item-entrada"
          type="number"
          v-model="datosAtencion.telefonoPadre"
          placeholder="Escriba aquí..."
        />
      </div>
      <div class="option-item">
        <h4 class="option-item-label">Nombre de la madre:</h4>
        <input
          class="option-item-entrada"
          type="text"
          v-model="datosAtencion.nombreMadre"
          placeholder="Escriba aquí..."
        />
      </div>
      <div class="option-item">
        <h4 class="option-item-label">Numero de telefono de la madre:</h4>
        <input
          class="option-item-entrada"
          type="number"
          v-model="datosAtencion.telefonoMadre"
          placeholder="Escriba aquí..."
        />
      </div>
      <div class="option-item">
        <h4 class="option-item-label">Nombre otro familiar/acudiente:</h4>
        <input
          class="option-item-entrada"
          type="text"
          v-model="datosAtencion.otroAcudiente"
          placeholder="Escriba aquí..."
        />
      </div>
      <div class="option-item">
        <h4 class="option-item-label">
          Numero de telefono otro familiar/acudiente:
        </h4>
        <input
          class="option-item-entrada"
          type="number"
          v-model="datosAtencion.telefonoOtroAcudiente"
          placeholder="Escriba aquí..."
        />
      </div>
      <div class="option-item">
        <h4 class="option-item-label">Tipo de evento:</h4>
        <select class="option-item-entrada" v-model="datosAtencion.tipoEvento">
          <option value="0" selected hidden>---Seleccione---</option>
          <option value="accidente">Accidente</option>
          <option value="enfermedadComun">Enfermedad de origen comun</option>
        </select>
      </div>
      <div class="option-item">
        <h4 class="option-item-label">Descripcion del evento:</h4>
        <textarea
          class="option-item-entrada heigth-t-area"
          v-model="datosAtencion.descripcionEvento"
          placeholder="Escriba aquí..."
        ></textarea>
      </div>
      <div class="option-item">
        <h4 class="option-item-label">Valoracion inicial:</h4>
        <textarea
          class="option-item-entrada heigth-t-area"
          v-model="datosAtencion.valoracionInicial"
          placeholder="Escriba aquí..."
        ></textarea>
      </div>
      <div class="option-item">
        <h4 class="option-item-label">Antecedentes (Familiares-Personales):</h4>
        <textarea
          class="option-item-entrada heigth-t-area"
          v-model="datosAtencion.antecedentes"
          placeholder="Escriba aquí..."
        ></textarea>
      </div>
      <div class="option-item">
        <h4 class="option-item-label">Signos vitales:</h4>
        <textarea
          class="option-item-entrada heigth-t-area"
          v-model="datosAtencion.signosVitales"
          placeholder="Escriba aquí..."
        ></textarea>
      </div>
      <div class="option-item">
        <h4 class="option-item-label">Conducta a seguir:</h4>
        <textarea
          class="option-item-entrada heigth-t-area"
          v-model="datosAtencion.conductaASeguir"
          placeholder="Escriba aquí..."
        ></textarea>
      </div>
      <div class="option-item">
        <h4 class="option-item-label">Recomendaciones:</h4>
        <textarea
          class="option-item-entrada heigth-t-area"
          v-model="datosAtencion.recomendaciones"
          placeholder="Escriba aquí..."
        ></textarea>
      </div>
      <div class="option-item">
        <h4 class="option-item-label">Requiere traslado:</h4>
        <select
          class="option-item-entrada"
          v-model="datosAtencion.traslado.requiere"
        >
          <option value="0" selected hidden>---Seleccione---</option>
          <option value="si">SI</option>
          <option value="no">NO</option>
        </select>
      </div>
      <div class="option-item" v-show="datosAtencion.traslado.requiere == 'si'">
        <h4 class="option-item-label">Institucion a donde se traslada:</h4>
        <input
          class="option-item-entrada"
          type="text"
          v-model="datosAtencion.traslado.institucion"
          placeholder="Escriba aquí..."
        />
      </div>
      <div class="option-item" v-show="datosAtencion.traslado.requiere == 'si'">
        <h4 class="option-item-label">Forma de traslado:</h4>
        <input
          class="option-item-entrada"
          type="text"
          v-model="datosAtencion.traslado.forma"
          placeholder="Escriba aquí..."
        />
      </div>
      <div class="option-item">
        <h4 class="option-item-label">Observaciones:</h4>
        <textarea
          class="option-item-entrada"
          v-model="datosAtencion.observaciones"
          placeholder="Escriba aquí..."
        ></textarea>
      </div>
      <div class="option-item">
        <h4 class="option-item-label">Ingresa al programa de salud:</h4>
        <input
          class="option-item-entrada"
          type="text"
          v-model="datosAtencion.ingresaProgramaSalud"
          placeholder="Escriba aquí..."
        />
      </div>
      <div class="option-item">
        <h4 class="option-item-label">Hora de finalizacion de la atencion:</h4>
        <input
          class="option-item-entrada"
          type="time"
          v-model="datosAtencion.horaFinAtencion"
        />
      </div>
    </div>

    <div class="container-generar">
      <div class="informe btn-generar" @click="crearAtencionSalud()">
        <h6>Guardar</h6>
        <i class="bx bxs-send"></i>
      </div>
    </div>
  </div>
</template>

<script>
import ApiUsers from "@/logic/ApiUsers.js";
import localToken from "@/logic/localToken.js";
export default {
  name: "SaludFragment",
  data: function () {
    return {
      datosAtencion: {
        hora: null,
        horaFinAtencion: null,
        sede: null,
        lugar: null,
        nombreApellidos: null,
        edad: null,
        sexo: null,
        numeroTelefono: null,
        correo: null,
        direccion: null,
        numeroIdentificacion: null,
        seguridadSocial: null,
        tipoPersona: "0",
        programa: null,
        jornada: null,
        cargo: null,
        codigo: null,
        nombreAcompañante: null,
        telefonoAcompañante: null,
        nombrePadre: null,
        telefonoPadre: null,
        nombreMadre: null,
        telefonoMadre: null,
        otroAcudiente: null,
        telefonoOtroAcudiente: null,
        tipoEvento: "0",
        descripcionEvento: null,
        valoracionInicial: null,
        antecedentes: null,
        signosVitales: null,
        conductaASeguir: null,
        recomendaciones: null,
        traslado: {
          requiere: "0",
          institucion: null,
          forma: null,
        },
        observaciones: null,
        ingresaProgramaSalud: null,
      },
    };
  },
  methods: {
    async crearAtencionSalud() {
      try {
        if (
          this.datosAtencion.hora != null &&
          this.datosAtencion.horaFinAtencion != null &&
          this.datosAtencion.sede != null &&
          this.datosAtencion.lugar != null &&
          this.datosAtencion.nombreApellidos != null &&
          this.datosAtencion.edad != null &&
          this.datosAtencion.sexo != null &&
          this.datosAtencion.numeroTelefono != null &&
          this.datosAtencion.correo != null &&
          this.datosAtencion.direccion != null &&
          this.datosAtencion.numeroIdentificacion != null &&
          this.datosAtencion.seguridadSocial != null &&
          this.datosAtencion.tipoPersona != "0" &&
          // this.datosAtencion.codigo != null &&
          this.datosAtencion.nombreAcompañante != null &&
          
          this.datosAtencion.nombrePadre != null &&
          this.datosAtencion.telefonoPadre != null &&
          this.datosAtencion.nombreMadre != null &&
          this.datosAtencion.telefonoMadre != null &&
          this.datosAtencion.otroAcudiente != null &&
          
          this.datosAtencion.tipoEvento != "0" &&
          this.datosAtencion.descripcionEvento != null &&
          this.datosAtencion.valoracionInicial != null &&
          this.datosAtencion.antecedentes != null &&
          this.datosAtencion.signosVitales != null &&
          this.datosAtencion.conductaASeguir != null &&
          this.datosAtencion.recomendaciones != null &&
          this.datosAtencion.traslado.requiere != "0" &&
          this.datosAtencion.observaciones != null &&
          this.datosAtencion.ingresaProgramaSalud != null
        ) {
          this.datosAtencion.token = localToken.getToken();
          const r = await ApiUsers.createAtencionSalud(this.datosAtencion);
          alert(r.data);
          this.datosAtencion = {
            hora: null,
            horaFinAtencion: null,
            sede: null,
            lugar: null,
            nombreApellidos: null,
            edad: null,
            sexo: null,
            numeroTelefono: null,
            correo: null,
            direccion: null,
            numeroIdentificacion: null,
            seguridadSocial: null,
            tipoPersona: "0",
            programa: null,
            jornada: null,
            cargo: null,
            codigo: null,
            nombreAcompañante: null,
            telefonoAcompañante: null,
            nombrePadre: null,
            telefonoPadre: null,
            nombreMadre: null,
            telefonoMadre: null,
            otroAcudiente: null,
            telefonoOtroAcudiente: null,
            tipoEvento: "0",
            descripcionEvento: null,
            valoracionInicial: null,
            antecedentes: null,
            signosVitales: null,
            conductaASeguir: null,
            recomendaciones: null,
            traslado: {
              requiere: "0",
              institucion: null,
              forma: null,
            },
            observaciones: null,
            ingresaProgramaSalud: null,
          };
        } else {
          alert("Algunos campos son requeridos");
        }
      } catch (error) {
        console.log(error);
      }
    },
    ShowLoadAnimation(valor) {
      if (valor) {
        this.$store.dispatch("setLoadAnimationAction", { s: true });
      } else {
        setTimeout(() => {
          this.$store.dispatch("setLoadAnimationAction", { s: false });
        }, 500);
      }
    },
  },
  mounted(){
    this.ShowLoadAnimation(false)
  }
};
</script>

<style>
.container-salud {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.informe h6 {
  text-align: center;
}
.informe {
  border: none;
  display: flex;
  width: 90%;
  padding: 15px;
  margin-top: 10px;
  box-shadow: 1px 3px 10px rgba(128, 128, 128, 0.2);
}
.informe:hover {
  cursor: pointer;
  box-shadow: 1px 3px 10px rgba(128, 128, 128, 0.3);
}
.container-generar {
  display: flex;
  width: 98%;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  margin-bottom: 20px;
}
.btn-generar h6 {
  margin: 0px;
}
.btn-generar i {
  margin-left: 15px;
}
.btn-generar {
  height: 50px;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 5px;
  background-color: #0197f6;
}

.titulo-salud {
  padding: 2rem;
  display: flex;
  justify-content: center;
  width: 100%;
}
.option-item {
  padding: 1rem;
  /* border: solid red; */
}
.option-item-label {
  font-size: 1.2rem;
}
.option-item-entrada {
  display: flex;
  width: 100%;
  height: 3.2rem;
  padding: 1rem;
  border: 0.1px solid gray;
  border-radius: 0.2rem;
}
.option-item-entrada:focus {
  outline: none;
  border: 1px solid black;
}
.heigth-t-area {
  height: 10rem;
}

.contenido {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media (min-width: 768px) {
  .contenido {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .option-item {
    width: 33%;
  }
  .informe {
    width: 16%;
  }
  .container-generar {
    justify-content: flex-start;
  }
}
</style>