<template>
  <section
    class="caracterizacion-Container caracterizacion-Container--bg-color"
    id="container-caracterizacion"
  >
    <div v-if="actualBlock == 0" class="Block1">
      <h2 v-animate-css="animationSetting.fadeInUp" class="Block1__titulo">
        Encuesta de Caracterización
      </h2>
      <p v-animate-css="animationSetting.fadeInUp" class="Block1__parrafo">
        Esta encuesta fue diseñada para caracterizar e identificar las
        condiciones de salud física, psiologica, psicosocial, cultural,
        deportiva y académica del estudiante, cuya finalidad será implementar
        las actividades de acuerdo con las necesidades detectadas.
      </p>
      <div
        v-animate-css="animationSetting.fadeInRight"
        class="Block1__container-btn-continuar"
      >
        <button class="button-base" @click="nextBlock(1)">Continuar</button>
      </div>
    </div>

    <div v-if="actualBlock == 1" class="Block2">
      <h3>{{ actualDate.categoria }}</h3>
      <div class="Block2__container-item">
        <!-- nombre completo -->
        <div v-if="actualIndex == 0">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            v-animate-css="animationSetting.fadeInUp"
            class="input-text-base"
            type="text"
            :value="informacion[actualIndex].respuesta"
            @input="capturar($event.target.value)"
          />
        </div>
        <!-- edad -->
        <div v-if="actualIndex == 1">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            v-animate-css="animationSetting.fadeInUp"
            class="input-text-base"
            type="number"
            :value="informacion[actualIndex].respuesta"
            @input="capturar($event.target.value)"
          />
        </div>
        <!-- sexo -->
        <div v-if="actualIndex == 2">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            :value="informacion[actualIndex].respuesta"
            class="input-text-base"
            @input="capturar($event.target.value)"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="Masculino">Masculino</option>
            <option value="Femenino">Femenino</option>
          </select>
        </div>
        <!-- fecha nacimineto -->
        <div v-if="actualIndex == 3">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            v-animate-css="animationSetting.fadeInUp"
            class="input-text-base"
            type="date"
            :value="informacion[actualIndex].respuesta"
            @input="capturar($event.target.value)"
          />
        </div>
        <!-- pais nacimiento -->
        <div v-if="actualIndex == 4">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="colombia">Colombia</option>
            <option value="otro">otro</option>
          </select>
        </div>
        <!-- departamento nacimiento -->
        <div
          v-if="
            actualIndex == 5 &&
            informacion[actualIndex - 1].respuesta == 'colombia'
          "
        >
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <!-- <option :value="null" selected >Selecione</option> -->
            <option
              v-for="i in colombia.departamentos"
              :disabled="i == 'seleccione'"
              :selected="i == 'seleccione'"
              :key="i"
              :value="i"
            >
              {{ i }}
            </option>
          </select>
        </div>
        <!-- municipio nacimineto -->
        <div
          v-if="
            actualIndex == 6 &&
            informacion[actualIndex - 2].respuesta == 'colombia'
          "
        >
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option
              v-for="j in colombia.municipios[
                `${informacion[actualIndex - 1].respuesta}`
              ]"
              :disabled="j == 'seleccione'"
              :selected="j == 'seleccione'"
              :key="j"
              :value="j"
            >
              {{ j }}
            </option>
          </select>
        </div>
        <!-- Otro departamento nacimiento -->
        <div
          v-if="
            actualIndex == 5 &&
            informacion[actualIndex - 1].respuesta != 'colombia'
          "
        >
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!-- Otro municipio nacimineto -->
        <div
          v-if="
            actualIndex == 6 &&
            informacion[actualIndex - 2].respuesta != 'colombia'
          "
        >
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!-- tipo documento -->
        <div v-if="actualIndex == 7">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            :value="informacion[actualIndex].respuesta"
            class="input-text-base"
            @input="capturar($event.target.value)"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="CC">Cedula de ciudadania</option>
            <option value="TI">Tarjeta de identidad</option>
            <option value="CE">Cedula de extranjeria</option>
          </select>
        </div>
        <!-- fecha nacimineto -->
        <div v-if="actualIndex == 8">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            v-animate-css="animationSetting.fadeInUp"
            class="input-text-base"
            type="number"
            :value="informacion[actualIndex].respuesta"
            @input="capturar($event.target.value)"
          />
        </div>
        <!-- lugar de procedencia -->
        <div v-if="actualIndex == 9">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            v-animate-css="animationSetting.fadeInUp"
            class="input-text-base"
            type="text"
            :value="informacion[actualIndex].respuesta"
            @input="capturar($event.target.value)"
          />
        </div>
        <!-- Pais lugar procedencia -->
        <div v-if="actualIndex == 10">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            v-animate-css="animationSetting.fadeInUp"
            class="input-text-base"
            type="text"
            :value="informacion[actualIndex].respuesta"
            @input="capturar($event.target.value)"
          />
        </div>
        <!-- departamento lugar procedencia -->
        <div v-if="actualIndex == 11">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            v-animate-css="animationSetting.fadeInUp"
            class="input-text-base"
            type="text"
            :value="informacion[actualIndex].respuesta"
            @input="capturar($event.target.value)"
          />
        </div>
        <!-- tipo vivienda  -->
        <div v-if="actualIndex == 12">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="propia">Propia</option>
            <option value="familiar">Familiar</option>
            <option value="arrendada">Arrendada</option>
          </select>
        </div>
        <!--actualmente vivo con  -->
        <div v-if="actualIndex == 13">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="mis padres">Mis padres</option>
            <option value="en casa de un familiar">
              En casa de un familiar
            </option>
            <option value="pensionado">Pensionado</option>
            <option value="amigos">Amigos</option>
            <option value="mi pareja">Mi pareja</option>
            <option value="solo">Solo</option>
          </select>
        </div>
        <!--direccion -->
        <div v-if="actualIndex == 14">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--estrato socio-economico  -->
        <div v-if="actualIndex == 15">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="No estratificado">No estratificado</option>
          </select>
        </div>
        <!--telefono -->
        <div v-if="actualIndex == 16">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="number"
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--celular -->
        <div v-if="actualIndex == 17">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="number"
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--email -->
        <div v-if="actualIndex == 18">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="email"
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--programa -->
        <div v-if="actualIndex == 19">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--semestre -->
        <div v-if="actualIndex == 20">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="number"
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--codigo -->
        <div v-if="actualIndex == 21">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="number"
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--jornada -->
        <div v-if="actualIndex == 22">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="diurna">Diurna</option>
            <option value="nocturna">Nocturna</option>
          </select>
        </div>
        <!--estado civil -->
        <div v-if="actualIndex == 23">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="Soltero">Soltero</option>
            <option value="Casado">Casado</option>
            <option value="Separado">Separado</option>
            <option value="Viudo">Viudo</option>
            <option value="Union Libre">Union Libre</option>
          </select>
        </div>
        <!--hijos -->
        <div v-if="actualIndex == 24">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="number"
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--edades -->
        <div
          v-if="
            actualIndex == 25 && informacion[actualIndex - 1].respuesta != '0'
          "
        >
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--religion -->
        <div v-if="actualIndex == 26">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--minoria -->
        <div v-if="actualIndex == 27">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--conflicto -->
        <div v-if="actualIndex == 28">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="si">SI</option>
            <option value="no">NO</option>
          </select>
        </div>
        <!--etnias -->
        <div v-if="actualIndex == 29">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="indigenas">Indigenas</option>
            <option value="afro decendientes">Afro decendientes</option>
            <option value="raizales">Raizales</option>
            <option value="gitanos">Gitanos</option>
            <option value="otra">Otra</option>
            <option value="ninguna">Ninguna</option>
          </select>
        </div>
        <!--otra etini -->
        <div
          v-if="
            actualIndex == 30 &&
            informacion[actualIndex - 1].respuesta == 'otra'
          "
        >
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--discapacipdad -->
        <div v-if="actualIndex == 31">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="fisica">Fisica</option>
            <option value="cognitiva">Cognitiva</option>
            <option value="auditiva">Auditiva</option>
            <option value="visual">Visual</option>
            <option value="otra">Otra</option>
            <option value="ninguna">Ninguna</option>
          </select>
        </div>
        <!--otra discapacidad -->
        <div v-if="actualIndex == 32 && informacion[31].respuesta == 'otra'">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--mano dominante -->
        <div v-if="actualIndex == 33">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="derecho">Derecho</option>
            <option value="izquierdo">Izquierdo</option>
            <option value="ambidiestro">Ambidiestro</option>
          </select>
        </div>
        <!-- nombre padre -->
        <div v-if="actualIndex == 34">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!-- edad padre -->
        <div v-if="actualIndex == 35">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="number"
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!-- ocupacion padre -->
        <div v-if="actualIndex == 36">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--nivel de estudios-->
        <div v-if="actualIndex == 37">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="analfabeta">Analfabeta</option>
            <option value="basica primaria">Basica primaria</option>
            <option value="bachiller">Bachiller</option>
            <option value="tecnico">Tecnico</option>
            <option value="profesional">Profesional</option>
            <option value="especialista">Especialista</option>
          </select>
        </div>
        <!-- celular padre -->
        <div v-if="actualIndex == 38">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="number"
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!-- nombre madre -->
        <div v-if="actualIndex == 39">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!-- edad madre -->
        <div v-if="actualIndex == 40">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="number"
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!-- ocupacion madre -->
        <div v-if="actualIndex == 41">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--nivel de estudios madre-->
        <div v-if="actualIndex == 42">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="analfabeta">Analfabeta</option>
            <option value="basica primaria">Basica primaria</option>
            <option value="bachiller">Bachiller</option>
            <option value="tecnico">Tecnico</option>
            <option value="profesional">Profesional</option>
            <option value="especialista">Especialista</option>
          </select>
        </div>
        <!-- celular madre -->
        <div v-if="actualIndex == 43">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="number"
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!-- n hermanos -->
        <div v-if="actualIndex == 44">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="number"
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!-- lugar entre ellos -->
        <div
          v-if="actualIndex == 45 && informacion[actualIndex - 1].respuesta > 0"
        >
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="number"
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!-- avisar a  -->
        <div v-if="actualIndex == 46">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!-- parentesco -->
        <div v-if="actualIndex == 47">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!-- celular -->
        <div v-if="actualIndex == 48">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="number"
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--ingresos familiares-->
        <div v-if="actualIndex == 49">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="1-2">1-2</option>
            <option value="2-3">2-3</option>
            <option value="4-5">4-5</option>
            <option value="5-6">5-6</option>
            <option value="+6">+6</option>
            <option value="no sabe">No sabe</option>
          </select>
        </div>
        <!-- nombre colegio -->
        <div v-if="actualIndex == 50">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--tipo colegio-->
        <div v-if="actualIndex == 51">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="publico">Publico</option>
            <option value="privado">Privado</option>
          </select>
        </div>
        <!--modalidad colegio-->
        <div v-if="actualIndex == 52">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="femenino">Femenino</option>
            <option value="masculino">Masculino</option>
            <option value="mixto">Mixto</option>
          </select>
        </div>
        <!--enfasis colegio-->
        <div v-if="actualIndex == 53">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="academico">Academico</option>
            <option value="tecnico">Tecnico</option>
            <option value="comercial">Comercial</option>
            <option value="diversificado">Diversificado</option>
          </select>
        </div>
        <!--  puntaje 11 -->
        <div v-if="actualIndex == 54">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="number"
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--matriculado en otra u -->
        <div v-if="actualIndex == 55">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="si">SI</option>
            <option value="no">NO</option>
          </select>
        </div>
        <!--Cual -->
        <div
          v-if="
            actualIndex == 56 && informacion[actualIndex - 1].respuesta == 'si'
          "
        >
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!-- semestres cursados -->
        <div
          v-if="
            actualIndex == 57 && informacion[actualIndex - 2].respuesta == 'si'
          "
        >
          <p v-animate-css="animationSetting.fadeInUp">
            {{ informacion[57].pregunta }}
          </p>
          <input
            type="number"
            class="input-text-base"
            @input="informacion[57].respuesta = $event.target.value"
            :value="informacion[57].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!-- progrmaam -->
        <div
          v-if="
            actualIndex == 58 && informacion[actualIndex - 3].respuesta == 'si'
          "
        >
          <p v-animate-css="animationSetting.fadeInUp">
            {{ informacion[58].pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            @input="informacion[58].respuesta = $event.target.value"
            :value="informacion[58].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--tipo ingreso -->
        <div v-if="actualIndex == 59">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="primera vez">Primera vez</option>
            <option value="reingreso">Reingreso</option>
            <option value="transf. interna">Transferencia Interna</option>
            <option value="intercambio">Intercambio</option>
          </select>
        </div>
        <!--motivo escojer carrera -->
        <div v-if="actualIndex == 60">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="vocacion">Vocacion</option>
            <option value="campo laboral">Campo laboral</option>
            <option value="tradicion familiar">Tradicion familiar</option>
            <option value="recomendacion">Recomendacion</option>
            <option value="moda o tendencia">Moda o tendencia</option>
          </select>
        </div>
        <!--motivo escojer la u -->
        <div v-if="actualIndex == 61">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="importancia regional">Importancia regional</option>
            <option value="acceso Economico">Acceso Economico</option>
            <option value="calidad Academica">Calidad Academica</option>
            <option value="ubicacion geografica">Ubicacion geografica</option>
            <option value="referencias familiares">
              Referencias familiares
            </option>
            <option value="referencias de amigos">Referencias de amigos</option>
            <option value="informacion de medio">Informacion de medio</option>
            <option value="visitas de colegio">Visitas de colegio</option>
          </select>
        </div>
        <!--es becado -->
        <div v-if="actualIndex == 62">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="si">SI</option>
            <option value="no">NO</option>
          </select>
        </div>
        <!--opciones de becado -->
        <div
          v-if="
            actualIndex == 63 && informacion[actualIndex - 1].respuesta == 'si'
          "
        >
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="academicas">Academicas</option>
            <option value="actividades deportivas">
              Actividades deportivas
            </option>
            <option value="Actividades culturales">
              Actividades culturales
            </option>
            <option value="Convenio empresarial">Convenio empresarial</option>
            <option value="grupo familiar">Grupo familiar</option>
            <option value="otro">Otro</option>
          </select>
        </div>
        <!-- cual otro opcion-->
        <div
          v-if="
            actualIndex == 64 &&
            informacion[actualIndex - 1].respuesta == 'otro'
          "
        >
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            @input="capturar($event.target.value)"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--medio de financiacion -->
        <div v-if="actualIndex == 65">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="Credito Estudiantil">Credito Estudiantil</option>
            <option value="acceso Economico">Acceso</option>
            <option value="Credito ICETEX">Credito ICETEX</option>
            <option value="Credito empresarial">Credito empresarial</option>
            <option value="Credito entidades finacieras">
              Credito entidades finacieras
            </option>
            <option value="Credito universidad del sinu">
              Credito universidad del sinu
            </option>
            <option value="No he requerido financiacion">
              No he requerido financiacion
            </option>
          </select>
        </div>
        <!--areas mejor desempeno -->
        <div v-if="actualIndex == 66">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="matemática">Matemática</option>
            <option value="física">Física</option>
            <option value="química">Química</option>
            <option value="español">Español</option>
            <option value="sociales">Sociales</option>
            <option value="biología">Biología</option>
          </select>
        </div>
        <!--areas peor desempeno -->
        <div v-if="actualIndex == 67">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="matemática">Matemática</option>
            <option value="física">Física</option>
            <option value="química">Química</option>
            <option value="español">Español</option>
            <option value="sociales">Sociales</option>
            <option value="biología">Biología</option>
          </select>
        </div>
        <!--nivel ingles -->
        <div v-if="actualIndex == 68">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="alto">Alto</option>
            <option value="medio">Medio</option>
            <option value="bajo">Bajo</option>
          </select>
        </div>
        <!--nivel frances -->
        <div v-if="actualIndex == 69">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="alto">Alto</option>
            <option value="medio">Medio</option>
            <option value="bajo">Bajo</option>
          </select>
        </div>
        <!--nivel aleman -->
        <div v-if="actualIndex == 70">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="alto">Alto</option>
            <option value="medio">Medio</option>
            <option value="bajo">Bajo</option>
          </select>
        </div>
        <!--afectan rendimineto -->
        <div v-if="actualIndex == 71">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="problemas familiares">Problemas Familiares</option>
            <option value="problemas personales">Problemas Personales</option>
            <option value="conflictos de pareja">Conflictos de Pareja</option>
            <option value="problemas económicos">Problemas Económicos</option>
            <option value="dificultades con los compañeros de clase">
              Dificultades con los compañeros de Clase
            </option>
            <option value="dificultades con los docentes">
              Dificultades con los Docentes
            </option>
          </select>
        </div>
        <!--areas necesita apoyo -->
        <div v-if="actualIndex == 72">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="velocidad lectora">Velocidad Lectora</option>
            <option value="hábitos y técnicas de estudio">
              Hábitos y técnicas de estudio
            </option>
            <option value="concentración y atención">
              Concentración y Atención
            </option>
            <option value="análisis">Análisis</option>
            <option value="interpretación">Interpretación</option>
            <option value="memoria">Memoria</option>
            <option value="técnicas de estudio">
              Técnicas de estudio/option>
            </option>
            <option value="ansiedad">Ansiedad</option>
            <option value="identidad">Identidad</option>
            <option value="estilos de aprendizaje">
              Estilos de Aprendizaje
            </option>
            <option value="motivación">Motivación</option>
            <option value="proyecto de vida">Proyecto de Vida</option>
            <option value="orientación vocacional">
              Orientación Vocacional
            </option>
            <option value="estados emocionales">Estados Emocionales</option>
            <option value="otro">Otro</option>
          </select>
        </div>
        <!--otra areas necesita apoyo -->
        <div
          v-if="
            actualIndex == 73 &&
            informacion[actualIndex - 1].respuesta == 'otro'
          "
        >
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            @input="capturar($event.target.value)"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--elementos dar clases -->
        <div v-if="actualIndex == 74">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="celular">Celular</option>
            <option value="tablet">Tablet</option>
            <option value="computador">Computador</option>
            <option value="llamadas\o comunicación con los docentes">
              Llamadas\o comunicación con los docentes
            </option>
          </select>
        </div>
        <!--elementos dar clases -->
        <div v-if="actualIndex == 75">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="Wifi">Wifi</option>
            <option value="Datos moviles">Datos moviles</option>
            <option value="No tengo accesibilidad a internet">
              No tengo accesibilidad a internet
            </option>
          </select>
        </div>
        <!--modalidad cultural gustaria entrar -->
        <div v-if="actualIndex == 76">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="grupo de danzas folclóricas">
              Grupo de Danzas Folclóricas
            </option>
            <option value="grupo de danzas modernas">
              Grupo de Danzas Modernas
            </option>
            <option value="grupo de música folclórica">
              Grupo de Música Folclórica
            </option>
            <option value="tuna">Tuna</option>
            <option value="coro">Coro</option>
            <option value="guitarra">Guitarra</option>
            <option value="banda musical (género pop - banda rock)">
              Banda Musical (género pop - banda rock)
            </option>
            <option value="teatro">Teatro</option>
            <option value="vallenato">Vallenato</option>
            <option value="otro">Otro</option>
            <option value="ninguna">Ninguna</option>
          </select>
        </div>
        <!--otra modalidad cultural gustaria entrar -->
        <div
          v-if="
            actualIndex == 77 &&
            informacion[actualIndex - 1].respuesta == 'otro'
          "
        >
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            @input="capturar($event.target.value)"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--diciplina deportiva gustaria entrar -->
        <div v-if="actualIndex == 78">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="taekwondo">Taekwondo</option>
            <option value="softbol">Softbol</option>
            <option value="fútbol sala">Fútbol sala</option>
            <option value="fútbol">Fútbol</option>
            <option value="baloncesto">Baloncesto</option>
            <option value="tenis de mesa">Tenis de Mesa</option>
            <option value="frisby">Frisby</option>
            <option value="ajedrez">Ajedrez</option>
            <option value="voleibol">Voleibol</option>
            <option value="otro">Otro</option>
            <option value="ninguna">Ninguna</option>
          </select>
        </div>
        <!--otra diciplina deportiva gustaria entrar -->
        <div
          v-if="
            actualIndex == 79 &&
            informacion[actualIndex - 1].respuesta == 'otro'
          "
        >
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            @input="capturar($event.target.value)"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--instrumento interpreta -->
        <div v-if="actualIndex == 80">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            :value="informacion[actualIndex].respuesta"
            @input="capturar($event.target.value)"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--grupo cultural ha participado-->
        <div v-if="actualIndex == 81">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            :value="informacion[actualIndex].respuesta"
            @input="capturar($event.target.value)"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--representado a nivel competitivo -->
        <div v-if="actualIndex == 82">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="si">Si</option>
            <option value="no">No</option>
          </select>
        </div>
        <!--cual deporte representado a nivel competitivo-->
        <div
          v-if="
            actualIndex == 83 && informacion[actualIndex - 1].respuesta == 'si'
          "
        >
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            @input="capturar($event.target.value)"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--gustaria participar en torneos -->
        <div v-if="actualIndex == 84">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="si">Si</option>
            <option value="no">No</option>
          </select>
        </div>
        <!--cual gustaria participar en torneos -->
        <div
          v-if="
            actualIndex == 85 && informacion[actualIndex - 1].respuesta == 'si'
          "
        >
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            @input="capturar($event.target.value)"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--en que horario cuenta tiempo libre-->
        <div v-if="actualIndex == 86">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="mañana">Mañana</option>
            <option value="tarde">Tarde</option>
            <option value="noche">Noche</option>
            <option value="los fines de Semana">Los fines de Semana</option>
            <option value="no tengo tiempo">No tengo tiempo</option>
          </select>
        </div>
        <!--contribuye mas ingreso familiar-->
        <div v-if="actualIndex == 87">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="papá">Papá</option>
            <option value="mamá">Mamá</option>
            <option value="hermano/a">Hermano/a</option>
            <option value="amigo/a">Amigo/a</option>
            <option value="familiar">Familiar</option>
            <option value="yo">Yo</option>
            <option value="otro">Otro</option>
          </select>
        </div>
        <!--cual otro contribuye mas ingreso familiar -->
        <div
          v-if="
            actualIndex == 88 &&
            informacion[actualIndex - 1].respuesta == 'otro'
          "
        >
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            @input="capturar($event.target.value)"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--cuantas persoans acargo -->
        <div v-if="actualIndex == 89">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="number"
            class="input-text-base"
            @input="capturar($event.target.value)"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--trabaja actualmente-->
        <div v-if="actualIndex == 90">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="si">Si</option>
            <option value="no">No</option>
          </select>
        </div>
        <!--tipo trabajador-->
        <div
          v-if="
            actualIndex == 91 && informacion[actualIndex - 1].respuesta == 'si'
          "
        >
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="asalariado">Asalariado</option>
            <option value="independiente">Independiente</option>
            <option value="accionista">Accionista</option>
            <option value="informal">Informal</option>
            <option value="negocio familiar">Negocio Familiar</option>
            <option value="no aplica">No aplica</option>
          </select>
        </div>
        <!--tipo labor-->
        <div
          v-if="
            actualIndex == 92 && informacion[actualIndex - 2].respuesta == 'si'
          "
        >
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="gerencial">Gerencial</option>
            <option value="administrativa">Administrativa</option>
            <option value="asistencial">Asistencial</option>
            <option value="técnico">Técnico</option>
            <option value="no aplica">No aplica</option>
            <option value="otro">Otro</option>
          </select>
        </div>
        <!--otro tipo labor -->
        <div
          v-if="
            actualIndex == 93 &&
            informacion[actualIndex - 1].respuesta == 'otro'
          "
        >
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            :value="informacion[actualIndex].respuesta"
            @input="capturar($event.target.value)"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--cargo-->
        <div
          v-if="
            actualIndex == 94 && informacion[actualIndex - 4].respuesta == 'si'
          "
        >
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            :value="informacion[actualIndex].respuesta"
            @input="capturar($event.target.value)"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--comparto mis problemas-->
        <div v-if="actualIndex == 95">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="mamá">Mamá</option>
            <option value="papá">Papá</option>
            <option value="hermano/a">Hermano/a</option>
            <option value="amigo/a">Amigo/a</option>
            <option value="primo/a">Primo/a</option>
            <option value="tío/a">Tío/a</option>
            <option value="pareja">Pareja</option>
            <option value="psicólogo/a">Psicólogo/a</option>
            <option value="con nadie">Con nadie</option>
            <option value="otro">Otro</option>
          </select>
        </div>
        <!--otro comparto mis problemas -->
        <div
          v-if="
            actualIndex == 96 &&
            informacion[actualIndex - 1].respuesta == 'otro'
          "
        >
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            @input="capturar($event.target.value)"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--relacion papa-->
        <div v-if="actualIndex == 97">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="excelente">Excelente</option>
            <option value="buena">Buena</option>
            <option value="regular">Regular</option>
            <option value="mala">Mala</option>
            <option value="nula">Nula</option>
          </select>
        </div>
        <!--porque relacion papa -->
        <div v-if="actualIndex == 98">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            @input="capturar($event.target.value)"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--relacion mama-->
        <div v-if="actualIndex == 99">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="excelente">Excelente</option>
            <option value="buena">Buena</option>
            <option value="regular">Regular</option>
            <option value="mala">Mala</option>
            <option value="nula">Nula</option>
          </select>
        </div>
        <!--porque relacion mama -->
        <div v-if="actualIndex == 100">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            @input="capturar($event.target.value)"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--relacion hrmanos-->
        <div v-if="actualIndex == 101">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="excelente">Excelente</option>
            <option value="buena">Buena</option>
            <option value="regular">Regular</option>
            <option value="mala">Mala</option>
            <option value="nula">Nula</option>
          </select>
        </div>
        <!--porque relacion hrmanos -->
        <div v-if="actualIndex == 102">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            @input="capturar($event.target.value)"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--me cinsidero-->
        <div v-if="actualIndex == 103">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="tímido">Tímido</option>
            <option value="extrovertido">Extrovertido</option>
            <option value="aislado">Aislado</option>
            <option value="conflictivo">Conflictivo</option>
          </select>
        </div>
        <!--me siento-->
        <div v-if="actualIndex == 104">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="triste">Triste</option>
            <option value="alegre">Alegre</option>
            <option value="preocupado">Preocupado</option>
            <option value="enojado">Enojado</option>
          </select>
        </div>
        <!-- Facilita demostrar afecto 104-->
        <div v-if="actualIndex == 105">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="si">SI</option>
            <option value="no">NO</option>
          </select>
        </div>
        <!--habilidades sociales-->
        <div v-if="actualIndex == 106">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="empatía">Empatía</option>
            <option value="participación">Participación</option>
            <option value="asertividad">Asertividad</option>
            <option value="cooperación">Cooperación</option>
            <option value="resolución de conflictos">
              Resolución de conflictos
            </option>
            <option value="ninguna">Ninguna</option>
          </select>
        </div>
        <!--aspecto a cambiar -->
        <div v-if="actualIndex == 107">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            @input="capturar($event.target.value)"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--recibiendo orientacion-->
        <div v-if="actualIndex == 108">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="si">Si</option>
            <option value="no">No</option>
          </select>
        </div>
        <!--desde cuando -->
        <div
          v-if="
            actualIndex == 109 && informacion[actualIndex - 1].respuesta == 'si'
          "
        >
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            @input="capturar($event.target.value)"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--gustaria recibir orientacion-->
        <div v-if="actualIndex == 110">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="si">Si</option>
            <option value="no">No</option>
          </select>
        </div>
        <!--p1-->
        <div v-if="actualIndex == 111">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="si">SI</option>
            <option value="no">NO</option>
          </select>
        </div>
        <!--p2-->
        <div v-if="actualIndex == 112">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="si">SI</option>
            <option value="no">NO</option>
          </select>
        </div>
        <!--p3-->
        <div v-if="actualIndex == 113">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="si">SI</option>
            <option value="no">NO</option>
          </select>
        </div>
        <!--p4-->
        <div v-if="actualIndex == 114">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="si">SI</option>
            <option value="no">NO</option>
          </select>
        </div>
        <!--p5-->
        <div v-if="actualIndex == 115">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="si">SI</option>
            <option value="no">NO</option>
          </select>
        </div>
        <!--p6-->
        <div v-if="actualIndex == 116">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="si">SI</option>
            <option value="no">NO</option>
          </select>
        </div>
        <!--p7-->
        <div v-if="actualIndex == 117">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="si">SI</option>
            <option value="no">NO</option>
          </select>
        </div>
        <!--p8-->
        <div v-if="actualIndex == 118">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="si">SI</option>
            <option value="no">NO</option>
          </select>
        </div>
        <!--p9-->
        <div v-if="actualIndex == 119">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="si">SI</option>
            <option value="no">NO</option>
          </select>
        </div>
        <!--p10-->
        <div v-if="actualIndex == 120">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="si">SI</option>
            <option value="no">NO</option>
          </select>
        </div>
        <!--p11-->
        <div v-if="actualIndex == 121">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="si">SI</option>
            <option value="no">NO</option>
          </select>
        </div>
        <!--p12-->
        <div v-if="actualIndex == 122">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="si">SI</option>
            <option value="no">NO</option>
          </select>
        </div>
        <!--p13-->
        <div v-if="actualIndex == 123">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="si">SI</option>
            <option value="no">NO</option>
          </select>
        </div>
        <!--p14-->
        <div v-if="actualIndex == 124">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="si">SI</option>
            <option value="no">NO</option>
          </select>
        </div>
        <!--p15-->
        <div v-if="actualIndex == 125">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="si">SI</option>
            <option value="no">NO</option>
          </select>
        </div>
        <!--p16-->
        <div v-if="actualIndex == 126">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="si">SI</option>
            <option value="no">NO</option>
          </select>
        </div>
        <!--p17-->
        <div v-if="actualIndex == 127">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="si">SI</option>
            <option value="no">NO</option>
          </select>
        </div>
        <!--p18-->
        <div v-if="actualIndex == 128">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="si">SI</option>
            <option value="no">NO</option>
          </select>
        </div>
        <!--p19-->
        <div v-if="actualIndex == 129">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="si">SI</option>
            <option value="no">NO</option>
          </select>
        </div>
        <!--cigarros diarios-->
        <div v-if="actualIndex == 130">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="number"
            class="input-text-base"
            @input="capturar($event.target.value)"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--  -->
        <!--frecuencia consumo licor diarios-->
        <div v-if="actualIndex == 131">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="diaria">Diaria</option>
            <option value="semanal">Semanal</option>
            <option value="mensual">Mensual</option>
            <option value="nunca">Nunca</option>
          </select>
        </div>
        <!--consume psicoactivos-->
        <div v-if="actualIndex == 132">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="si">SI</option>
            <option value="no">NO</option>
          </select>
        </div>
        <!-- cual -->
        <div
          v-if="
            actualIndex == 133 && informacion[actualIndex - 1].respuesta == 'si'
          "
        >
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            @input="capturar($event.target.value)"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!-- consumir verduras-->
        <div v-if="actualIndex == 134">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="si">SI</option>
            <option value="no">NO</option>
          </select>
        </div>
        <!--consumir frutas-->
        <div v-if="actualIndex == 135">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="si">SI</option>
            <option value="no">NO</option>
          </select>
        </div>
        <!--actividad fisica-->
        <div v-if="actualIndex == 136">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="si">SI</option>
            <option value="no">NO</option>
          </select>
        </div>
        <!--horas sueno-->
        <div v-if="actualIndex == 137">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="4-6">4-6</option>
            <option value="7-10">7-10</option>
            <option value="11 en adelante">11 en adelante</option>
          </select>
        </div>
        <!--visita medico-->
        <div v-if="actualIndex == 138">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="1-2">1-2</option>
            <option value="3-4">3-4</option>
            <option value="5 en adelante">5 en adelante</option>
            <option value="ninguna de las anteriores">
              Ninguna de las anteriores
            </option>
          </select>
        </div>
        <!--visita odontologoo-->
        <div v-if="actualIndex == 139">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="1-2">1-2</option>
            <option value="3-4">3-4</option>
            <option value="5 en adelante">5 en adelante</option>
            <option value="ninguna de las anteriores">
              Ninguna de las anteriores
            </option>
          </select>
        </div>
        <!-- vacunas -->
        <div v-if="actualIndex == 140">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
            multiple
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="toxoide tetanico">Toxoide tetanico</option>
            <option value="hepatitis a">Hepatitis A</option>
            <option value="hepatitis b">Hepatitis B</option>
            <option value="fiebre amarilla">Fiebre amarilla</option>
            <option value="influenza">Influenza</option>
            <option value="Neumococo">Neumococo</option>
            <option value="meningitis">Meningitis</option>
            <option value="varicela">Varicela</option>
            <option value="sarampion">Sarampion</option>
            <option value="rubeola">Rubeola</option>
            <option value="vph">VPH</option>
            <option value="covid 19">Covid 19</option>
          </select>
        </div>
        <!-- lentes formualdos-->
        <div v-if="actualIndex == 141">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="si">SI</option>
            <option value="no">NO</option>
          </select>
        </div>
        <!--fatiga visual-->
        <div v-if="actualIndex == 142">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="si">SI</option>
            <option value="no">NO</option>
          </select>
        </div>
        <!--problemas de refraccion-->
        <div v-if="actualIndex == 143">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
            multiple
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="miopía">Miopía</option>
            <option value="astigmatismo">Astigmatismo</option>
            <option value="hipermetropía">Hipermetropía</option>
            <option value="presbicia">Presbicia</option>
            <option value="ninguno">Ninguno</option>
          </select>
        </div>
        <!--derechos sexuales-->
        <div v-if="actualIndex == 144">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="si">SI</option>
            <option value="no">NO</option>
          </select>
        </div>
        <!-- orientacion sexial de quien -->
        <div v-if="actualIndex == 145">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            @input="capturar($event.target.value)"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--metodos planificacion-->
        <div v-if="actualIndex == 146">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="si">SI</option>
            <option value="no">NO</option>
          </select>
        </div>
        <!-- cual metodos de planificacion -->
        <div
          v-if="
            actualIndex == 147 && informacion[actualIndex - 1].respuesta == 'si'
          "
        >
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            @input="capturar($event.target.value)"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
        <!--evitar ets-->
        <div v-if="actualIndex == 148">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="si">SI</option>
            <option value="no">NO</option>
          </select>
        </div>
        <!--conversa y reflexiona-->
        <div v-if="actualIndex == 149">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <select
            class="input-text-base"
            @input="capturar($event.target.value)"
            :value="informacion[actualIndex].respuesta"
            v-animate-css="animationSetting.fadeInUp"
          >
            <option hidden selected value>-- seleccione una opcion --</option>
            <option value="si">SI</option>
            <option value="no">NO</option>
          </select>
        </div>
        <!--observaciones-->
        <div v-if="actualIndex == 150">
          <p v-animate-css="animationSetting.fadeInUp">
            {{ actualDate.pregunta }}
          </p>
          <input
            type="text"
            class="input-text-base"
            @input="capturar($event.target.value)"
            v-animate-css="animationSetting.fadeInUp"
          />
        </div>
      </div>

      <div
        v-show="showNextButton"
        v-animate-css="animationSetting.fadeInRight"
        class="Block2__container-btn-continuar"
      >
        <button
          readinly
          tabindex="-1"
          class="button-base button-back"
          @click="backStep()"
        >
          <i class="bx bx-arrow-back"></i>
        </button>
        <button class="button-base" @click="nextStep()">Siguiente</button>
      </div>
    </div>
    <div v-show="actualBlock == 3" class="Block3">
      
    
        <img class="Block3__img-container" :src="require('@/assets/task-finish.svg')" alt="">
     
      <h3>Su encuesta se envio correctamente</h3>
      <button class="btn btn-primary w-25 m-5 p-2" @click="recargar">Salir</button>
    </div>
  </section>
</template>

<script>
import api from "@/logic/ApiUsers.js"
import localToken from "@/logic/localToken.js";
export default {
  name: "Caracterizacion",
  data: function () {
    return {
      animationSetting: {
        fadeInUp: {
          classes: "fadeInUp",
          duration: 2000,
          iteration: "1",
        },
        fadeInRight: {
          classes: "fadeInRight",
          duration: 2000,
          iteration: "1",
        },
      },
      departamentSelected: "null",
      colombia: {
        departamentos: [
          "seleccione",
          "Amazonas",
          "Antioquia",
          "Arauca",
          "Archipiélago de San Andrés, Providencia y Santa Catalina",
          "Atlántico",
          "Bolívar",
          "Boyacá",
          "Caldas",
          "Caquetá",
          "Casanare",
          "Cauca",
          "Cesar",
          "Chocó",
          "Córdoba",
          "Cundinamarca",
          "Guainía",
          "Guaviare",
          "Huila",
          "La Guajira",
          "Magdalena",
          "Meta",
          "Nariño",
          "Norte de Santander",
          "Putumayo",
          "Quindío",
          "Risaralda",
          "Santander",
          "Sucre",
        ],
        municipios: {
          Amazonas: [
            "seleccione",
            "Leticia",
            "El Encanto",
            "La Chorrera",
            "La Pedrera",
            "La Victoria",
            "Puerto Arica",
            "Puerto Nariño",
            "Puerto Santander",
            "Tarapacá",
            "Puerto Alegría",
            "Miriti Paraná",
          ],

          Antioquia: [
            "seleccione",
            "Medellín",
            "Abejorral",
            "Abriaquí",
            "Alejandría",
            "Amagá",
            "Amalfi",
            "Andes",
            "Angelópolis",
            "Angostura",
            "Anorí",
            "Anza",
            "Apartadó",
            "Arboletes",
            "Argelia",
            "Armenia",
            "Barbosa",
            "Bello",
            "Betania",
            "Betulia",
            "Ciudad Bolívar",
            "Briceño",
            "Buriticá",
            "Cáceres",
            "Caicedo",
            "Caldas",
            "Campamento",
            "Cañasgordas",
            "Caracolí",
            "Caramanta",
            "Carepa",
            "Carolina",
            "Caucasia",
            "Chigorodó",
            "Cisneros",
            "Cocorná",
            "Concepción",
            "Concordia",
            "Copacabana",
            "Dabeiba",
            "Don Matías",
            "Ebéjico",
            "El Bagre",
            "Entrerrios",
            "Envigado",
            "Fredonia",
            "Giraldo",
            "Girardota",
            "Gómez Plata",
            "Guadalupe",
            "Guarne",
            "Guatapé",
            "Heliconia",
            "Hispania",
            "Itagui",
            "Ituango",
            "Belmira",
            "Jericó",
            "La Ceja",
            "La Estrella",
            "La Pintada",
            "La Unión",
            "Liborina",
            "Maceo",
            "Marinilla",
            "Montebello",
            "Murindó",
            "Mutatá",
            "Nariño",
            "Necoclí",
            "Nechí",
            "Olaya",
            "Peñol",
            "Peque",
            "Pueblorrico",
            "Puerto Berrío",
            "Puerto Nare",
            "Puerto Triunfo",
            "Remedios",
            "Retiro",
            "Rionegro",
            "Sabanalarga",
            "Sabaneta",
            "Salgar",
            "San Francisco",
            "San Jerónimo",
            "San Luis",
            "San Pedro",
            "San Rafael",
            "San Roque",
            "San Vicente",
            "Santa Bárbara",
            "Santo Domingo",
            "El Santuario",
            "Segovia",
            "Sopetrán",
            "Támesis",
            "Tarazá",
            "Tarso",
            "Titiribí",
            "Toledo",
            "Turbo",
            "Uramita",
            "Urrao",
            "Valdivia",
            "Valparaíso",
            "Vegachí",
            "Venecia",
            "Yalí",
            "Yarumal",
            "Yolombó",
            "Yondó",
            "Zaragoza",
            "San Pedro de Uraba",
            "Santafé de Antioquia",
            "Santa Rosa de Osos",
            "San Andrés de Cuerquía",
            "Vigía del Fuerte",
            "San José de La Montaña",
            "San Juan de Urabá",
            "El Carmen de Viboral",
            "San Carlos",
            "Frontino",
            "Granada",
            "Jardín",
            "Sonsón",
          ],

          Arauca: [
            "seleccione",
            "Arauquita",
            "Cravo Norte",
            "Fortul",
            "Puerto Rondón",
            "Saravena",
            "Tame",
            "Arauca",
          ],
          "Archipiélago de San Andrés, Providencia y Santa Catalina": [
            "seleccione",
            "Providencia",
            "San Andrés",
            "Santa Catalina",
          ],
          Atlántico: [
            "seleccione",
            "Barranquilla",
            "Baranoa",
            "Candelaria",
            "Galapa",
            "Luruaco",
            "Malambo",
            "Manatí",
            "Piojó",
            "Polonuevo",
            "Sabanagrande",
            "Sabanalarga",
            "Santa Lucía",
            "Santo Tomás",
            "Soledad",
            "Suan",
            "Tubará",
            "Usiacurí",
            "Juan de Acosta",
            "Palmar de Varela",
            "Campo de La Cruz",
            "Repelón",
            "Puerto Colombia",
            "Ponedera",
          ],
          Bolívar: [
            "seleccione",
            "Achí",
            "Arenal",
            "Arjona",
            "Arroyohondo",
            "Calamar",
            "Cantagallo",
            "Cicuco",
            "Córdoba",
            "Clemencia",
            "El Guamo",
            "Magangué",
            "Mahates",
            "Margarita",
            "Montecristo",
            "Mompós",
            "Morales",
            "Norosí",
            "Pinillos",
            "Regidor",
            "Río Viejo",
            "San Estanislao",
            "San Fernando",
            "San Juan Nepomuceno",
            "Santa Catalina",
            "Santa Rosa",
            "Simití",
            "Soplaviento",
            "Talaigua Nuevo",
            "Tiquisio",
            "Turbaco",
            "Turbaná",
            "Villanueva",
            "Barranco de Loba",
            "Santa Rosa del Sur",
            "Hatillo de Loba",
            "El Carmen de Bolívar",
            "San Martín de Loba",
            "Altos del Rosario",
            "San Jacinto del Cauca",
            "San Pablo de Borbur",
            "San Jacinto",
            "El Peñón",
            "Cartagena",
            "María la Baja",
            "San Cristóbal",
            "Zambrano",
          ],
          Boyacá: [
            "seleccione",
            "Tununguá",
            "Motavita",
            "Ciénega",
            "Tunja",
            "Almeida",
            "Aquitania",
            "Arcabuco",
            "Berbeo",
            "Betéitiva",
            "Boavita",
            "Boyacá",
            "Briceño",
            "Buena Vista",
            "Busbanzá",
            "Caldas",
            "Campohermoso",
            "Cerinza",
            "Chinavita",
            "Chiquinquirá",
            "Chiscas",
            "Chita",
            "Chitaraque",
            "Chivatá",
            "Cómbita",
            "Coper",
            "Corrales",
            "Covarachía",
            "Cubará",
            "Cucaita",
            "Cuítiva",
            "Chíquiza",
            "Chivor",
            "Duitama",
            "El Cocuy",
            "El Espino",
            "Firavitoba",
            "Floresta",
            "Gachantivá",
            "Gameza",
            "Garagoa",
            "Guacamayas",
            "Guateque",
            "Guayatá",
            "Güicán",
            "Iza",
            "Jenesano",
            "Jericó",
            "Labranzagrande",
            "La Capilla",
            "La Victoria",
            "Macanal",
            "Maripí",
            "Miraflores",
            "Mongua",
            "Monguí",
            "Moniquirá",
            "Muzo",
            "Nobsa",
            "Nuevo Colón",
            "Oicatá",
            "Otanche",
            "Pachavita",
            "Páez",
            "Paipa",
            "Pajarito",
            "Panqueba",
            "Pauna",
            "Paya",
            "Pesca",
            "Pisba",
            "Puerto Boyacá",
            "Quípama",
            "Ramiriquí",
            "Ráquira",
            "Rondón",
            "Saboyá",
            "Sáchica",
            "Samacá",
            "San Eduardo",
            "San Mateo",
            "Santana",
            "Santa María",
            "Santa Sofía",
            "Sativanorte",
            "Sativasur",
            "Siachoque",
            "Soatá",
            "Socotá",
            "Socha",
            "Sogamoso",
            "Somondoco",
            "Sora",
            "Sotaquirá",
            "Soracá",
            "Susacón",
            "Sutamarchán",
            "Sutatenza",
            "Tasco",
            "Tenza",
            "Tibaná",
            "Tinjacá",
            "Tipacoque",
            "Toca",
            "Tópaga",
            "Tota",
            "Turmequé",
            "Tutazá",
            "Umbita",
            "Ventaquemada",
            "Viracachá",
            "Zetaquira",
            "Togüí",
            "Villa de Leyva",
            "Paz de Río",
            "Santa Rosa de Viterbo",
            "San Pablo de Borbur",
            "San Luis de Gaceno",
            "San José de Pare",
            "San Miguel de Sema",
            "Tuta",
            "Tibasosa",
            "La Uvita",
            "Belén",
          ],
          Caldas: [
            "seleccione",
            "Manizales",
            "Aguadas",
            "Anserma",
            "Aranzazu",
            "Belalcázar",
            "Chinchiná",
            "Filadelfia",
            "La Dorada",
            "La Merced",
            "Manzanares",
            "Marmato",
            "Marulanda",
            "Neira",
            "Norcasia",
            "Pácora",
            "Palestina",
            "Pensilvania",
            "Riosucio",
            "Risaralda",
            "Salamina",
            "Samaná",
            "San José",
            "Supía",
            "Victoria",
            "Villamaría",
            "Viterbo",
            "Marquetalia",
          ],
          Caquetá: [
            "seleccione",
            "Florencia",
            "Albania",
            "Curillo",
            "El Doncello",
            "El Paujil",
            "Morelia",
            "Puerto Rico",
            "Solano",
            "Solita",
            "Valparaíso",
            "San José del Fragua",
            "Belén de Los Andaquies",
            "Cartagena del Chairá",
            "Milán",
            "La Montañita",
            "San Vicente del Caguán",
          ],
          Casanare: [
            "seleccione",
            "Yopal",
            "Aguazul",
            "Chámeza",
            "Hato Corozal",
            "La Salina",
            "Monterrey",
            "Pore",
            "Recetor",
            "Sabanalarga",
            "Sácama",
            "Tauramena",
            "Trinidad",
            "Villanueva",
            "San Luis de Gaceno",
            "Paz de Ariporo",
            "Nunchía",
            "Maní",
            "Támara",
            "Orocué",
          ],
          Cauca: [
            "seleccione",
            "Popayán",
            "Almaguer",
            "Argelia",
            "Balboa",
            "Bolívar",
            "Buenos Aires",
            "Cajibío",
            "Caldono",
            "Caloto",
            "Corinto",
            "El Tambo",
            "Florencia",
            "Guachené",
            "Guapi",
            "Inzá",
            "Jambaló",
            "La Sierra",
            "La Vega",
            "López",
            "Mercaderes",
            "Miranda",
            "Morales",
            "Padilla",
            "Patía",
            "Piamonte",
            "Piendamó",
            "Puerto Tejada",
            "Puracé",
            "Rosas",
            "Santa Rosa",
            "Silvia",
            "Sotara",
            "Suárez",
            "Sucre",
            "Timbío",
            "Timbiquí",
            "Toribio",
            "Totoró",
            "Villa Rica",
            "Santander de Quilichao",
            "San Sebastián",
            "Páez",
          ],
          Cesar: [
            "seleccione",
            "Valledupar",
            "Aguachica",
            "Agustín Codazzi",
            "Astrea",
            "Becerril",
            "Bosconia",
            "Chimichagua",
            "Chiriguaná",
            "Curumaní",
            "El Copey",
            "El Paso",
            "Gamarra",
            "González",
            "La Gloria",
            "Manaure",
            "Pailitas",
            "Pelaya",
            "Pueblo Bello",
            "La Paz",
            "San Alberto",
            "San Diego",
            "San Martín",
            "Tamalameque",
            "Río de Oro",
            "La Jagua de Ibirico",
          ],
          Chocó: [
            "seleccione",
            "Istmina",
            "Quibdó",
            "Acandí",
            "Alto Baudo",
            "Atrato",
            "Bagadó",
            "Bahía Solano",
            "Bajo Baudó",
            "Bojaya",
            "Cértegui",
            "Condoto",
            "Juradó",
            "Lloró",
            "Medio Atrato",
            "Medio Baudó",
            "Medio San Juan",
            "Nóvita",
            "Nuquí",
            "Río Iro",
            "Río Quito",
            "Riosucio",
            "Sipí",
            "Unguía",
            "El Litoral del San Juan",
            "El Cantón del San Pablo",
            "El Carmen de Atrato",
            "San José del Palmar",
            "Belén de Bajira",
            "Carmen del Darien",
            "Tadó",
            "Unión Panamericana",
          ],
          Córdoba: [
            "seleccione",
            "San Bernardo del Viento",
            "Montería",
            "Ayapel",
            "Buenavista",
            "Canalete",
            "Cereté",
            "Chimá",
            "Chinú",
            "Cotorra",
            "Lorica",
            "Los Córdobas",
            "Momil",
            "Moñitos",
            "Planeta Rica",
            "Pueblo Nuevo",
            "Puerto Escondido",
            "Purísima",
            "Sahagún",
            "San Andrés Sotavento",
            "San Antero",
            "San Pelayo",
            "Tierralta",
            "Tuchín",
            "Valencia",
            "San José de Uré",
            "Ciénaga de Oro",
            "San Carlos",
            "Montelíbano",
            "La Apartada",
            "Puerto Libertador",
          ],
          Cundinamarca: [
            "seleccione",
            "Anapoima",
            "Arbeláez",
            "Beltrán",
            "Bituima",
            "Bogotá D.C.",
            "Bojacá",
            "Cabrera",
            "Cachipay",
            "Cajicá",
            "Caparrapí",
            "Caqueza",
            "Chaguaní",
            "Chipaque",
            "Choachí",
            "Chocontá",
            "Cogua",
            "Cota",
            "Cucunubá",
            "El Colegio",
            "El Rosal",
            "Fomeque",
            "Fosca",
            "Funza",
            "Fúquene",
            "Gachala",
            "Gachancipá",
            "Gachetá",
            "Girardot",
            "Granada",
            "Guachetá",
            "Guaduas",
            "Guasca",
            "Guataquí",
            "Guatavita",
            "Guayabetal",
            "Gutiérrez",
            "Jerusalén",
            "Junín",
            "La Calera",
            "La Mesa",
            "La Palma",
            "La Peña",
            "La Vega",
            "Lenguazaque",
            "Macheta",
            "Madrid",
            "Manta",
            "Medina",
            "Mosquera",
            "Nariño",
            "Nemocón",
            "Nilo",
            "Nimaima",
            "Nocaima",
            "Venecia",
            "Pacho",
            "Paime",
            "Pandi",
            "Paratebueno",
            "Pasca",
            "Puerto Salgar",
            "Pulí",
            "Quebradanegra",
            "Quetame",
            "Quipile",
            "Apulo",
            "Ricaurte",
            "San Bernardo",
            "San Cayetano",
            "San Francisco",
            "Sesquilé",
            "Sibaté",
            "Silvania",
            "Simijaca",
            "Soacha",
            "Subachoque",
            "Suesca",
            "Supatá",
            "Susa",
            "Sutatausa",
            "Tabio",
            "Tausa",
            "Tena",
            "Tenjo",
            "Tibacuy",
            "Tibirita",
            "Tocaima",
            "Tocancipá",
            "Topaipí",
            "Ubalá",
            "Ubaque",
            "Une",
            "Útica",
            "Vianí",
            "Villagómez",
            "Villapinzón",
            "Villeta",
            "Viotá",
            "Zipacón",
            "San Juan de Río Seco",
            "Villa de San Diego de Ubate",
            "Guayabal de Siquima",
            "San Antonio del Tequendama",
            "Agua de Dios",
            "Carmen de Carupa",
            "Vergara",
            "Albán",
            "Anolaima",
            "Chía",
            "El Peñón",
            "Sopó",
            "Gama",
            "Sasaima",
            "Yacopí",
            "Fusagasugá",
            "Zipaquirá",
            "Facatativá",
          ],
          Guainía: [
            "seleccione",
            "Inírida",
            "Barranco Minas",
            "Mapiripana",
            "San Felipe",
            "Puerto Colombia",
            "La Guadalupe",
            "Cacahual",
            "Pana Pana",
            "Morichal",
          ],
          Guaviare: [
            "seleccione",
            "Calamar",
            "San José del Guaviare",
            "Miraflores",
            "El Retorno",
          ],
          Huila: [
            "seleccione",
            "Neiva",
            "Acevedo",
            "Agrado",
            "Aipe",
            "Algeciras",
            "Altamira",
            "Baraya",
            "Campoalegre",
            "Colombia",
            "Elías",
            "Garzón",
            "Gigante",
            "Guadalupe",
            "Hobo",
            "Iquira",
            "Isnos",
            "La Argentina",
            "La Plata",
            "Nátaga",
            "Oporapa",
            "Paicol",
            "Palermo",
            "Palestina",
            "Pital",
            "Pitalito",
            "Rivera",
            "Saladoblanco",
            "Santa María",
            "Suaza",
            "Tarqui",
            "Tesalia",
            "Tello",
            "Teruel",
            "Timaná",
            "Villavieja",
            "Yaguará",
            "San Agustín",
          ],
          "La Guajira": [
            "seleccione",
            "Riohacha",
            "Albania",
            "Barrancas",
            "Dibula",
            "Distracción",
            "El Molino",
            "Fonseca",
            "Hatonuevo",
            "Maicao",
            "Manaure",
            "Uribia",
            "Urumita",
            "Villanueva",
            "La Jagua del Pilar",
            "San Juan del Cesar",
          ],
          Magdalena: [
            "seleccione",
            "Santa Marta",
            "Algarrobo",
            "Aracataca",
            "Ariguaní",
            "Cerro San Antonio",
            "Chivolo",
            "Concordia",
            "El Banco",
            "El Piñon",
            "El Retén",
            "Fundación",
            "Guamal",
            "Nueva Granada",
            "Pedraza",
            "Pivijay",
            "Plato",
            "Remolino",
            "Salamina",
            "San Zenón",
            "Santa Ana",
            "Sitionuevo",
            "Tenerife",
            "Zapayán",
            "Zona Bananera",
            "San Sebastián de Buenavista",
            "Sabanas de San Angel",
            "Pijiño del Carmen",
            "Santa Bárbara de Pinto",
            "Pueblo Viejo",
            "Ciénaga",
          ],
          Meta: [
            "seleccione",
            "Uribe",
            "Villavicencio",
            "Acacias",
            "Cabuyaro",
            "Cubarral",
            "Cumaral",
            "El Calvario",
            "El Castillo",
            "El Dorado",
            "Granada",
            "Guamal",
            "Mapiripán",
            "Mesetas",
            "La Macarena",
            "Lejanías",
            "Puerto Concordia",
            "Puerto Gaitán",
            "Puerto López",
            "Puerto Lleras",
            "Puerto Rico",
            "Restrepo",
            "San Juanito",
            "San Martín",
            "Vista Hermosa",
            "Barranca de Upía",
            "Fuente de Oro",
            "San Carlos de Guaroa",
            "San Juan de Arama",
            "Castilla la Nueva",
          ],
          Nariño: [
            "seleccione",
            "Santacruz",
            "Pasto",
            "Albán",
            "Aldana",
            "Ancuyá",
            "Barbacoas",
            "Colón",
            "Consaca",
            "Contadero",
            "Córdoba",
            "Cuaspud",
            "Cumbal",
            "Cumbitara",
            "El Charco",
            "El Peñol",
            "El Rosario",
            "El Tambo",
            "Funes",
            "Guachucal",
            "Guaitarilla",
            "Gualmatán",
            "Iles",
            "Imués",
            "Ipiales",
            "La Cruz",
            "La Florida",
            "La Llanada",
            "La Tola",
            "La Unión",
            "Leiva",
            "Linares",
            "Los Andes",
            "Magüí",
            "Mallama",
            "Mosquera",
            "Nariño",
            "Olaya Herrera",
            "Ospina",
            "Francisco Pizarro",
            "Policarpa",
            "Potosí",
            "Providencia",
            "Puerres",
            "Pupiales",
            "Ricaurte",
            "Roberto Payán",
            "Samaniego",
            "Sandoná",
            "San Bernardo",
            "San Lorenzo",
            "San Pablo",
            "Santa Bárbara",
            "Sapuyes",
            "Taminango",
            "Tangua",
            "Túquerres",
            "Yacuanquer",
            "San Pedro de Cartago",
            "El Tablón de Gómez",
            "Buesaco",
            "San Andrés de Tumaco",
            "Belén",
            "Chachagüí",
            "Arboleda",
          ],
          "Norte de Santander": [
            "seleccione",
            "Silos",
            "Cácota",
            "Toledo",
            "Mutiscua",
            "El Zulia",
            "Salazar",
            "Cucutilla",
            "Puerto Santander",
            "Gramalote",
            "El Tarra",
            "Teorama",
            "Arboledas",
            "Lourdes",
            "Bochalema",
            "Convención",
            "Hacarí",
            "Herrán",
            "Tibú",
            "San Cayetano",
            "San Calixto",
            "La Playa",
            "Chinácota",
            "Ragonvalia",
            "La Esperanza",
            "Villa del Rosario",
            "Chitagá",
            "Sardinata",
            "Abrego",
            "Los Patios",
            "Ocaña",
            "Bucarasica",
            "Santiago",
            "Labateca",
            "Cachirá",
            "Villa Caro",
            "Durania",
            "Pamplona",
            "Pamplonita",
            "Cúcuta",
            "El Carmen",
          ],
          Putumayo: [
            "seleccione",
            "Mocoa",
            "Colón",
            "Orito",
            "Puerto Caicedo",
            "Puerto Guzmán",
            "Leguízamo",
            "Sibundoy",
            "San Francisco",
            "San Miguel",
            "Santiago",
            "Valle de Guamez",
            "Puerto Asís",
            "Villagarzón",
          ],
          Quindío: [
            "seleccione",
            "Armenia",
            "Buenavista",
            "Circasia",
            "Córdoba",
            "Filandia",
            "La Tebaida",
            "Montenegro",
            "Pijao",
            "Quimbaya",
            "Salento",
            "Calarcá",
            "Génova",
          ],
          Risaralda: [
            "seleccione",
            "Pereira",
            "Apía",
            "Balboa",
            "Dosquebradas",
            "Guática",
            "La Celia",
            "La Virginia",
            "Marsella",
            "Mistrató",
            "Pueblo Rico",
            "Quinchía",
            "Santuario",
            "Santa Rosa de Cabal",
            "Belén de Umbría",
          ],
          Santander: [
            "seleccione",
            "Puerto Wilches",
            "Puerto Parra",
            "Bucaramanga",
            "Aguada",
            "Albania",
            "Aratoca",
            "Barbosa",
            "Barichara",
            "Barrancabermeja",
            "Betulia",
            "Bolívar",
            "Cabrera",
            "California",
            "Carcasí",
            "Cepitá",
            "Cerrito",
            "Charalá",
            "Charta",
            "Chipatá",
            "Cimitarra",
            "Concepción",
            "Confines",
            "Contratación",
            "Coromoro",
            "Curití",
            "El Guacamayo",
            "El Playón",
            "Encino",
            "Enciso",
            "Florián",
            "Floridablanca",
            "Galán",
            "Gambita",
            "Girón",
            "Guaca",
            "Guadalupe",
            "Guapotá",
            "Guavatá",
            "Güepsa",
            "Jesús María",
            "Jordán",
            "La Belleza",
            "Landázuri",
            "La Paz",
            "Lebríja",
            "Los Santos",
            "Macaravita",
            "Málaga",
            "Matanza",
            "Mogotes",
            "Molagavita",
            "Ocamonte",
            "Oiba",
            "Onzaga",
            "Palmar",
            "Páramo",
            "Piedecuesta",
            "Pinchote",
            "Puente Nacional",
            "Rionegro",
            "San Andrés",
            "San Gil",
            "San Joaquín",
            "San Miguel",
            "Santa Bárbara",
            "Simacota",
            "Socorro",
            "Suaita",
            "Sucre",
            "Suratá",
            "Tona",
            "Vélez",
            "Vetas",
            "Villanueva",
            "Zapatoca",
            "Palmas del Socorro",
            "San Vicente de Chucurí",
            "San José de Miranda",
            "Santa Helena del Opón",
            "Sabana de Torres",
            "El Carmen de Chucurí",
            "Valle de San José",
            "San Benito",
            "Hato",
            "Chimá",
            "Capitanejo",
            "El Peñón",
          ],
          Sucre: [
            "seleccione",
            "Guaranda",
            "Majagual",
            "Sucre",
            "Chalan",
            "Coloso",
            "Morroa",
            "Ovejas",
            "Sincelejo",
            "Covenas",
            "Palmito",
            "San Onofre",
            "Santiago de Tlu",
            "Toluviejo",
            "Buenavista",
            "Corozal",
            "El Roble",
            "Galeras",
            "Los Palmitos",
            "Sampues",
            "San Juan de Betulia",
            "San Pedro",
            "Since",
            "Caimito",
            "La Union",
            "San Benito Abad",
            "San Marcos",
          ],
        },
      },
      step: 0,
      showNextButton:true,
      informacion: [
        {
          categoria: "Información Personal",
          pregunta: "¿Cual es su Nombre completo?:",
          respuesta: null,
        },
        {
          categoria: "Información Personal",
          pregunta: "¿Cual es su Edad?:",
          respuesta: null,
        },
        {
          categoria: "Información Personal",
          pregunta: "¿Cual es su Sexo?:",
          respuesta: null,
        },
        {
          categoria: "Información Personal",
          pregunta: "¿Cual es su fecha de nacimiento?:",
          respuesta: null,
        },
        {
          categoria: "Información Personal",
          pregunta: "¿Cual es su Pais de nacimiento?:",
          respuesta: null,
        },
        {
          categoria: "Información Personal",
          pregunta: "¿Cual es su Departamento de nacimiento?:",
          respuesta: null,
        },
        {
          categoria: "Información Personal",
          pregunta: "¿Cual es su Municipio de nacimiento?:",
          respuesta: null,
        },
        {
          categoria: "Información Personal",
          pregunta: "Seleccione su tipo de documento:",
          respuesta: null,
        },
        {
          categoria: "Información Personal",
          pregunta: "Ingrese su numero de documento:",
          respuesta: null,
        },
        {
          categoria: "Información Personal",
          pregunta: "¿Cual es su lugar de prodecencia?:",
          respuesta: null,
        },
        {
          categoria: "Información Personal",
          pregunta: "¿Cual es su lugar de procedencia (Pais)?:",
          respuesta: null,
        },
        {
          categoria: "Información Personal",
          pregunta: "¿Cual es su lugar de procedencia (Departamento)?:",
          respuesta: null,
        },
        {
          categoria: "Información Personal",
          pregunta: "Seleccione el tipo de vivienda:",
          respuesta: null,
        },
        {
          categoria: "Información Personal",
          pregunta: "¿Con quien vive actualmente?:",
          respuesta: null,
        },
        {
          categoria: "Información Personal",
          pregunta: "¿Cual es su dirección?:",
          respuesta: null,
        },
        {
          categoria: "Información Personal",
          pregunta: "¿Cual es su estrato socio-economico?:",
          respuesta: null,
        },
        {
          categoria: "Información Personal",
          pregunta: "¿Cual es su numero de telefono?:",
          respuesta: null,
        },
        {
          categoria: "Información Personal",
          pregunta: "¿Cual es su numero de celular?:",
          respuesta: null,
        },
        {
          categoria: "Información Personal",
          pregunta: "Ingrese su E-mail:",
          respuesta: null,
        },
        {
          categoria: "Información Personal",
          pregunta: "¿Usted esta matriulado en el programa de:?:",
          respuesta: null,
        },
        {
          categoria: "Información Personal",
          pregunta: "¿Actualmente usted esta en el semestre numero:?:",
          respuesta: null,
        },
        {
          categoria: "Información Personal",
          pregunta: "Ingrese su ID (codigo estudiantil):",
          respuesta: null,
        },
        {
          categoria: "Información Personal",
          pregunta: "¿Cual es su jornada academica?:",
          respuesta: null,
        },
        {
          categoria: "Información Personal",
          pregunta: "¿ Cual es estado civil?:",
          respuesta: null,
        },
        {
          categoria: "Información Personal",
          pregunta: "¿Cuantos hijos tiene?:",
          respuesta: null,
        },

        {
          categoria: "Información Personal",
          pregunta: "Edades (separe por comas):",
          respuesta: null,
        },
        {
          categoria: "Información Personal",
          pregunta: "¿Cual es su religion?:",
          respuesta: null,
        },
        {
          categoria: "Información Personal",
          pregunta: "¿A que minorias o comunidad vulnerable pertenece usted?:",
          respuesta: null,
        },
        {
          categoria: "Información Personal",
          pregunta: "¿Es usted victima del conflicto armado?:",
          respuesta: null,
        },
        {
          categoria: "Información Personal",
          pregunta: "¿Pertenece a alguna etnia?:",
          respuesta: null,
        },
        {
          categoria: "Información Personal",
          pregunta: "¿Cual?:",
          respuesta: null,
        },
        {
          categoria: "Información Personal",
          pregunta: "¿Tiene algun tipo de discapacidad?:",
          respuesta: null,
        },
        {
          categoria: "Información Personal",
          pregunta: "¿Cual?:",
          respuesta: null,
        },
        {
          categoria: "Información Personal",
          pregunta: "¿Cual es tu mano dominante?:",
          respuesta: null,
        },

        // *** ------------------------------------------------***

        {
          categoria: "Información Familiar",
          pregunta: "¿ Cual es el Nombre del Padre o Tutor?:",
          respuesta: null,
        },
        {
          categoria: "Información Familiar",
          pregunta: "Edad:",
          respuesta: null,
        },
        {
          categoria: "Información Familiar",
          pregunta: "Ocupación:",
          respuesta: null,
        },
        {
          categoria: "Información Familiar",
          pregunta: "Nivel de estudios:",
          respuesta: null,
        },
        {
          categoria: "Información Familiar",
          pregunta:
            "Celular (Sino tiene coloque el de un familiar o conocido mas cercano):",
          respuesta: null,
        },
        {
          categoria: "Información Familiar",
          pregunta: "¿Cual es el Nombre de la Madre o Tutora?:",
          respuesta: null,
        },
        {
          categoria: "Información Familiar",
          pregunta: "Edad:",
          respuesta: null,
        },
        {
          categoria: "Información Familiar",
          pregunta: "Ocupación:",
          respuesta: null,
        },
        {
          categoria: "Información Familiar",
          pregunta: "Nivel de estudios:",
          respuesta: null,
        },
        {
          categoria: "Información Familiar",
          pregunta:
            "Celular (Sino tiene coloque el de un familiar o conocido mas cercano):",
          respuesta: null,
        },
        {
          categoria: "Información Familiar",
          pregunta: "¿Cuantos hermanos tiene?:",
          respuesta: null,
        },
        {
          categoria: "Información Familiar",
          pregunta: "Lugar que ocupas entre ellos",
          respuesta: null,
        },
        {
          categoria: "Información Familiar",
          pregunta: "Ingrese el nombre de la persona a contactar en caso de emergencia:",
          respuesta: null,
        },
        {
          categoria: "Información Familiar",
          pregunta: "¿Cual es su parentesco?:",
          respuesta: null,
        },
        {
          categoria: "Información Familiar",
          pregunta: "Celular:",
          respuesta: null,
        },
        {
          categoria: "Información Familiar",
          pregunta: "Ingresos familiares en SMMLV:",
          respuesta: null,
        },
        {
          categoria: "Información Académica",
          pregunta:
            "Nombre del colegio en que obtuvo el grado de bachiller academico:",
          respuesta: null,
        },
        {
          categoria: "Información Académica",
          pregunta: "Tipo de colegio:",
          respuesta: null,
        },
        {
          categoria: "Información Académica",
          pregunta: "Modalidad del colegio:",
          respuesta: null,
        },
        {
          categoria: "Información Académica",
          pregunta: "Enfasis del colegio:",
          respuesta: null,
        },
        {
          categoria: "Información Académica",
          pregunta: "¿Que puntaje obtuvo en las pruebas saber 11?:",
          respuesta: null,
        },
        {
          categoria: "Información Académica",
          pregunta: "¿Ha estado matriculado en otra universidad?:",
          respuesta: null,
        },
        {
          categoria: "Información Académica",
          pregunta: "¿Cual?:",
          respuesta: null,
        },
        {
          categoria: "Información Académica",
          pregunta: "Cantidad de semestres cursados:",
          respuesta: null,
        },
        {
          categoria: "Información Académica",
          pregunta: "Programa:",
          respuesta: null,
        },
        {
          categoria: "Información Académica",
          pregunta: "Tipo de ingreso a la Universidad del Sinú:",
          respuesta: null,
        },
        {
          categoria: "Información Académica",
          pregunta: "Motivo para escoger la carrera:",
          respuesta: null,
        },
        {
          categoria: "Información Académica",
          pregunta: "Motivo para escoger la Universidad del Sinú:",
          respuesta: null,
        },
        {
          categoria: "Información Académica",
          pregunta: "¿Esta usted Becado?:",
          respuesta: null,
        },
        {
          categoria: "Información Académica",
          pregunta: "Razon:",
          respuesta: null,
        },
        {
          categoria: "IInformación Académica",
          pregunta: "¿Cual?:",
          respuesta: null,
        },
        {
          categoria: "Información Académica",
          pregunta:
            "¿Ha requerido de financiacion para su estudios superiores?:",
          respuesta: null,
        },
        {
          categoria: "Información Académica",
          pregunta:
            "Areas en las cuales tuvo mejor desempeño en el bachillerato:",
          respuesta: null,
        },
        {
          categoria: "Información Académica",
          pregunta:
            "Areas en la cuales no tuvo mejor desempeño en el bachillerato:",
          respuesta: null,
        },
        {
          categoria: "Información Académica",
          pregunta: "Nivel de dominio de una segunda lengua ( INGLES ):",
          respuesta: null,
        },
        {
          categoria: "Información Académica",
          pregunta: "Nivel de dominio de una segunda lengua ( FRANCES ):",
          respuesta: null,
        },
        {
          categoria: "Información Académica",
          pregunta: "Nivel de dominio de una segunda lengua ( ALEMAN ):",
          respuesta: null,
        },
        // {
        //   categoria: "Informacion Academica",
        //   pregunta: "Otra Cual?:",
        //   respuesta: null,
        // },
        {
          categoria: "Información Académica",
          pregunta:
            "De las situaciones relacionadas a continuacion ¿Cuales concidera que afectan o puedan afectar tu rendimiento academico?:",
          respuesta: null,
        },
        {
          categoria: "Información Académica",
          pregunta:
            "¿En que areas consideras que puedes necesitar apoyo academico?:",
          respuesta: null,
        },
        {
          categoria: "Información Académica",
          pregunta: "¿Cual?:",
          respuesta: null,
        },
        {
          categoria: "Información Académica",
          pregunta: "¿Qué elementos utilizas para dar clases virtual?:",
          respuesta: null,
        },
        {
          categoria: "Información Académica",
          pregunta: "¿Que tipo de internet utilizas?:",
          respuesta: null,
        },
        {
          categoria: "Intereses Personales",
          pregunta:
            "Elige la modalidad cultural a la que te gustaria pertenecer dentro de la universidad:",
          respuesta: null,
        },
        {
          categoria: "Intereses Personales",
          pregunta: "¿Cual?:",
          respuesta: null,
        },
        {
          categoria: "Intereses Personales",
          pregunta:
            "Elige la disciplina deportiva a la que te gustaria pertenecer dentro de la universidad:",
          respuesta: null,
        },
        {
          categoria: "Intereses Personales",
          pregunta: "¿Cual?:",
          respuesta: null,
        },
        {
          categoria: "Intereses Personales",
          pregunta: "¿Que instrumento interpreta?:",
          respuesta: null,
        },
        {
          categoria: "Intereses Personales",
          pregunta: "¿En que grupo cultural ha participado?:",
          respuesta: null,
        },
        {
          categoria: "Intereses Personales",
          pregunta:
            "¿Has hecho parte o haces parte de una disciplina deportiva a nivel competitivo que representes o hayas representado?:",
          respuesta: null,
        },
        {
          categoria: "Intereses Personales",
          pregunta: "¿Cual deporte?:",
          respuesta: null,
        },
        {
          categoria: "Intereses Personales",
          pregunta:
            "¿Te gustaria participar en torneos recreativos y deportivos?:",
          respuesta: null,
        },
        {
          categoria: "Intereses Personales",
          pregunta: "¿En que deporte?:",
          respuesta: null,
        },
        {
          categoria: "Intereses Personales",
          pregunta:
            "¿Cuenta usted con tiempo libre para las Actividades de Esparcimiento y Recreacion?:",
          respuesta: null,
        },
        //  {
        //   categoria: "Intereses Personales",
        //   pregunta: "En que horario:",
        //   respuesta: "NA",
        // },
        {
          categoria: "Información Económica",
          pregunta:
            "¿Quien contribuye en mayor proporcion al ingreso familiar?:",
          respuesta: null,
        },
        {
          categoria: "Información Económica",
          pregunta: "¿Quien?:",
          respuesta: null,
        },
        {
          categoria: "Información Económica",
          pregunta: "¿Cuantas personas tienes personas a cargo?:",
          respuesta: null,
        },
        {
          categoria: "Información Económica",
          pregunta: "¿Trabajas actualmente?:",
          respuesta: null,
        },
        {
          categoria: "Información Económica",
          pregunta: "Tipo de trabajador:",
          respuesta: null,
        },
        {
          categoria: "Información Económica",
          pregunta: "Tipo de labor:",
          respuesta: null,
        },
        {
          categoria: "Información Económica",
          pregunta: "¿Cual?:",
          respuesta: null,
        },
        {
          categoria: "Información Económica",
          pregunta: "Cargo:",
          respuesta: null,
        },
        {
          categoria: "Aspectos Psico-Socio-Afectivos",
          pregunta: "¿Con quien comparto mis problemas?:",
          respuesta: null,
        },
        {
          categoria: "Aspectos Psico-Socio-Afectivos",
          pregunta: "¿Quien?:",
          respuesta: null,
        },
        {
          categoria: "Aspectos Psico-Socio-Afectivos",
          pregunta: "¿Como es tu relacion con tu padre?:",
          respuesta: null,
        },
        {
          categoria: "Aspectos Psico-Socio-Afectivos",
          pregunta: "¿Por qué?:",
          respuesta: null,
        },
        {
          categoria: "Aspectos Psico-Socio-Afectivos",
          pregunta: "¿Cómo es tu relacion con tu madre?:",
          respuesta: null,
        },
        {
          categoria: "Aspectos Psico-Socio-Afectivos",
          pregunta: "¿Por qué?:",
          respuesta: null,
        },
        {
          categoria: "Aspectos Psico-Socio-Afectivos",
          pregunta: "¿Cómo esta tu relacion con tus hermanos?:",
          respuesta: null,
        },
        {
          categoria: "Aspectos Psico-Socio-Afectivos",
          pregunta: "¿Por qué?:",
          respuesta: null,
        },
        {
          categoria: "Aspectos Psico-Socio-Afectivos",
          pregunta: "Me concidero una persona:",
          respuesta: null,
        },
        {
          categoria: "Aspectos Psico-Socio-Afectivos",
          pregunta: "Por lo general me siento:",
          respuesta: null,
        },
        {
          categoria: "Aspectos Psico-Socio-Afectivos",
          pregunta: "¿Se te facilita dar demostraciones de afecto?:",
          respuesta: null,
        },
        {
          categoria: "Aspectos Psico-Socio-Afectivos",
          pregunta: "¿Qué habilidades sociales posee?:",
          respuesta: null,
        },
        {
          categoria: "Aspectos Psico-Socio-Afectivos",
          pregunta:
            "¿Qué aspectos de tu personalidad concideras que debes cambiar?:",
          respuesta: null,
        },
        {
          categoria: "Aspectos Psico-Socio-Afectivos",
          pregunta: "¿Has estado recibiendo orientación psicologica?:",
          respuesta: null,
        },
        {
          categoria: "Aspectos Psico-Socio-Afectivos",
          pregunta: "¿Desde cuando?:",
          respuesta: null,
        },
        {
          categoria: "Aspectos Psico-Socio-Afectivos",
          pregunta: "¿Te gustaria recibir orientación psicologica?:",
          respuesta: null,
        },
        {
          categoria: "Aspectos Psico-Socio-Afectivos",
          pregunta: "¿Se concidera usted una persona activa y dinamica?:",
          respuesta: null,
        },
        {
          categoria: "Aspectos Psico-Socio-Afectivos",
          pregunta:
            "¿Se ha adaptado usted con facilidad a la vida universitaria?:",
          respuesta: null,
        },
        {
          categoria: "Aspectos Psico-Socio-Afectivos",
          pregunta: "¿Tiene seguridad y confianza en si mismo?:",
          respuesta: null,
        },
        {
          categoria: "Aspectos Psico-Socio-Afectivos",
          pregunta:
            "¿Tiene los objetivos y metas claras a corto y mediano plazo?:",
          respuesta: null,
        },
        {
          categoria: "Aspectos Psico-Socio-Afectivos",
          pregunta: "¿Es usted una persona Sociable, alegre y optimista?:",
          respuesta: null,
        },
        {
          categoria: "Aspectos Psico-Socio-Afectivos",
          pregunta:
            "¿Cuando las cosas no salen como usted quiere, es capaz de manejar a frustracion¿:",
          respuesta: null,
        },
        {
          categoria: "Aspectos Psico-Socio-Afectivos",
          pregunta: "¿Tiene buenas cualidades y aptitudes para el estudio?:",
          respuesta: null,
        },
        {
          categoria: "Aspectos Psico-Socio-Afectivos",
          pregunta: "¿Tiene buenos habitos de estudio?:",
          respuesta: null,
        },
        {
          categoria: "Aspectos Psico Socio-Afectivos",
          pregunta:
            "¿Ha realizado pruebas de orientacion vocacional en el colegio?:",
          respuesta: null,
        },
        {
          categoria: "Aspectos Psico-Socio-Afectivos",
          pregunta: "¿Se siente motivado hacia la carrera que estudia?:",
          respuesta: null,
        },
        {
          categoria: "Aspectos Psico-Socio-Afectivos",
          pregunta:
            "¿Siente temor, nervios o malestar cuando tiene que expresarse en publico?:",
          respuesta: null,
        },
        {
          categoria: "Aspectos Psico-Socio-Afectivos",
          pregunta:
            "¿Se siente ansioso cuando le van a evaluar y se refleja en sus resultados?:",
          respuesta: null,
        },
        {
          categoria: "Aspectos Psico-Socio-Afectivos",
          pregunta:
            "¿Tiene usted su proyecto de vida claro y sus acciones son coherenres con el?:",
          respuesta: null,
        },
        {
          categoria: "Aspectos Psico-Socio-Afectivos",
          pregunta: "¿Tiene buena organizacion del tiempo?:",
          respuesta: null,
        },
        {
          categoria: "Aspectos Psico-Socio-Afectivos",
          pregunta: "¿Goza de buena salud fisica?:",
          respuesta: null,
        },
        {
          categoria: "Aspectos Psico-Socio-Afectivos",
          pregunta:
            "¿Sufre de cansancio, nervios y/o dolores de cabeza frecuentes?:",
          respuesta: null,
        },
        {
          categoria: "Aspectos Psico-Socio-Afectivos",
          pregunta:
            "¿Antes las dificultades y obstaculos suele usted trabajar con constancia para superarlos?:",
          respuesta: null,
        },
        {
          categoria: "Aspectos Psico-Socio-Afectivos",
          pregunta:
            "¿Asiste generalemente a todas las clases programadas y de manera puntual?:",
          respuesta: null,
        },
        {
          categoria: "AspectosP sico-Socio-Afectivos",
          pregunta:
            "¿Por lo general debe esforzarse demasiado para mantener la motivacion?:",
          respuesta: null,
        },
        {
          categoria: "Aspectos En Salud",
          pregunta: "¿Usted fuma?,en caso de que su respuesta sea afirmativa ingrese cuantos cigarrillos fuma a diario, en caso contrario 0:",
          respuesta: "NA",
        },
        {
          categoria: "Aspectos En Salud",
          pregunta: "¿Con que frecuencia consume licor?, en caso de que no seleccion nunca:",
          respuesta: "NA",
        },
        {
          categoria: "Aspectos En Salud",
          pregunta: "¿Has consumido alguna sustancia psicoactiva?:",
          respuesta: "NA",
        },
        {
          categoria: "Aspectos En Salud",
          pregunta: "¿Cual?:",
          respuesta: "NA",
        },
        {
          categoria: "Aspectos En Salud",
          pregunta: "¿Te gusta consumir verduras en tus alimentacion?:",
          respuesta: "NA",
        },
        {
          categoria: "Aspectos En Salud",
          pregunta: "¿Te gusta consumir frutas en tu alimentacion?:",
          respuesta: "NA",
        },
        {
          categoria: "Aspectos En Salud",
          pregunta: "¿Realizas actividades fisicas?:",
          respuesta: "NA",
        },
        {
          categoria: "Aspectos En Salud",
          pregunta: "¿Cuantas son sus horas de sueño?:",
          respuesta: "NA",
        },
        {
          categoria: "Aspectos En Salud",
          pregunta: "¿Cuantas veces al año visita a su medico?:",
          respuesta: "NA",
        },
        {
          categoria: "Aspectos En Salud",
          pregunta: "¿Cuantas veces al año visita su odontologo?:",
          respuesta: "NA",
        },
        {
          categoria: "Inmunizacion",
          pregunta: "¿Te has aplicado la vacuna de las siguientes vacunas? (Seleccion multiple):",
          respuesta: "NA",
        },
        {
          categoria: "Salud Visual",
          pregunta: "¿Usas lentes formulados?:",
          respuesta: "NA",
        },
        {
          categoria: "Salud Visual",
          pregunta: "¿Presenta fatiga o cansancio visual al leer?:",
          respuesta: "NA",
        },
        {
          categoria: "Salud Visual",
          pregunta: "¿Cual de los siguientes problemas de Refraccion presenta usted?",
          respuesta: "NA",
        },
        {
          categoria: "Salud Sexual y Reproductiva",
          pregunta: "¿Conoce los derechos sexuales y reproductivos?:",
          respuesta: "NA",
        },
        {
          categoria: "Salud Sexual y Reproductiva",
          pregunta:
            "¿De quien a recibido orientacion acerca de la sexualidad?:",
          respuesta: "NA",
        },

        {
          categoria: "Salud Sexual y Reproductiva",
          pregunta:
            "¿Usas metodos de planificacion familiar (Anticonceptivos)?:",
          respuesta: "NA",
        },
        {
          categoria: "Salud Sexual y Reproductiva",
          pregunta: "¿Cual?:",
          respuesta: "NA",
        },
        {
          categoria: "Salud Sexual y Reproductiva",
          pregunta:
            "¿Sabes cual es la forma mas segura de evitar una ETS (ENFERMEDAD DE TRANSMICION SEXUAL)?:",
          respuesta: "NA",
        },
        {
          categoria: "Salud Sexual y Reproductiva",
          pregunta:
            "¿Conversas y reflexionas con tu pareja acerca de las implicaciones de tener relaciones sexuales y exiges condiciones para que esta desicion se realice de mutuo acuerdo, en el momento adecuado y de forma segura y responsable?:",
          respuesta: "NA",
        },
        {
          categoria: "Salud Sexual y Reproductiva",
          pregunta: "Observaciones:",
          respuesta: "NA",
        },
      ],
      
      actualDate: null,
      actualIndex: null,
      actualBlock: 0,
    };
  },
  methods: {
    nextBlock(po) {
      this.actualBlock = po;
    },
    capturar(parametro) {
      this.informacion[this.actualIndex].respuesta = parametro;
      console.log(this.informacion[this.actualIndex].respuesta);
    },
    backStep() {
      // if (this.actualIndex == 33 && this.informacion[32].respuesta != null) {
      //   this.actualIndex = this.actualIndex - 2;
      //   this.actualDate = this.informacion[this.actualIndex];
      // } else {
      //   if (this.actualIndex == 58 && this.informacion[54].respuesta == "no") {
      //     this.actualIndex = this.actualIndex - 4;
      //     this.actualDate = this.informacion[this.actualIndex];
      //   } else {
      //     this.actualIndex = this.actualIndex - 1;
      //     this.actualDate = this.informacion[this.actualIndex];
      //   }
      // }

      switch (this.actualIndex) {
        case 26:
          this.go("-", 2);
          break;
        case 31:
          this.go("-", 2);
          break;

        case 33:
          this.go("-", 2);
          break;
        case 46:
          this.go("-", 2);
          break;
        // *******
        case 59:
          this.go("-", 4);
          break;
        // *******
        case 65:
          this.go("-", 3);
          break;

        case 74:
          this.go("-", 2);
          break;
        case 78:
          this.go("-", 2);
          break;
        case 80:
          this.go("-", 2);
          break;
        case 84:
          this.go("-", 2);
          break;
        case 86:
          this.go("-", 2);
          break;
        case 89:
          this.go("-", 2);
          break;
        // *****
        case 95:
          this.go("-", 5);
          break;
        case 94:
          this.go("-", 2);
          break;
        case 97:
          this.go("-", 2);
          break;
        case 110:
          this.go("-", 2);
          break;
        case 134:
          this.go("-", 2);
          break;
        case 148:
          this.go("-", 2);
          break;
        default:
          this.go("-", 1);
          break;

        // this.go("+", 1);
        //break;
      }
      
    },
    nextStep() {
      // efecto shadow rojo
      let item = document.getElementsByClassName("input-text-base")[0];
      item.style.boxShadow = "0px 0px 10px rgba(0, 0, 0, 0.2)";
      // decisor de salto de preguntas que no aplican
      switch (this.actualIndex) {
        case 4:
          if (this.informacion[this.actualIndex].respuesta == null) {
            break;
          } else if (this.informacion[this.actualIndex].respuesta == "otro") {
            this.informacion[this.actualIndex].respuesta = prompt(
              "Ingrese su pais de nacimiento"
            );
            this.go("+", 1);
          } else {
            this.go("+", 1);
          }
          break;
        case 24:
          console.log(this.informacion[24].pregunta);
          console.log(this.informacion[24].respuesta);
          console.log(typeof this.informacion[24].respuesta);
          this.cuando(this.actualIndex, "==", 0, "+", 2);
          break;
        case 29:
          this.cuando(this.actualIndex, "!=", "otra", "+", 2);
          break;
        case 31:
          this.cuando(this.actualIndex, "!=", "otra", "+", 2);
          break;
        case 44:
          this.cuando(this.actualIndex, "==", "0", "+", 2);
          break;
        case 55:
          this.cuando(this.actualIndex, "==", "no", "+", 4);
          break;
        case 62:
          this.cuando(this.actualIndex, "==", "no", "+", 3);
          break;
        case 63:
          this.cuando(this.actualIndex, "!=", "otro", "+", 2);
          break;
        case 72:
          this.cuando(this.actualIndex, "!=", "otro", "+", 2);
          break;
        case 76:
          this.cuando(this.actualIndex, "!=", "otro", "+", 2);
          break;
        case 78:
          this.cuando(this.actualIndex, "!=", "otro", "+", 2);
          break;
        case 82:
          this.cuando(this.actualIndex, "==", "no", "+", 2);
          break;
        case 84:
          this.cuando(this.actualIndex, "==", "no", "+", 2);
          break;
        case 87:
          this.cuando(this.actualIndex, "!=", "otro", "+", 2);
          break;
        case 90:
          this.cuando(this.actualIndex, "==", "no", "+", 5);
          break;
        case 92:
          this.cuando(this.actualIndex, "!=", "otro", "+", 2);
          break;
        case 95:
          this.cuando(this.actualIndex, "!=", "otro", "+", 2);
          break;
        case 108:
          this.cuando(this.actualIndex, "==", "no", "+", 2);
          break;
        case 132:
          this.cuando(this.actualIndex, "==", "no", "+", 2);
          break;
        case 146:
          this.cuando(this.actualIndex, "==", "no", "+", 2);
          break;
          case 150:
          this.sendDatos();
          this.showNextButton = false;
          break;
        default:
          if (this.informacion[this.actualIndex].respuesta == null) {
            item.style.boxShadow = "0px 0px 20px rgba(255, 108, 108, 0.5)";
            break;
          } else {
            this.go("+", 1);
            break;
          }

        // this.go("+", 1);
        //break;
      }
     

    },
    async sendDatos() {
      try {
        const r = await api.createCaracterizacion(this.informacion,localToken.getToken());
        if(r.status==200){
            this.actualBlock =3;
        }else{
          alert("Ocurrio algo")
        } 
      } catch (error) {
        console.log(error)
        alert("Error al enviar los datos")
      }
      
    },
    /**
     * @param {number} razon razon de incremento o decremento
     * @param {string} operacion signo de la operacion
     */
    go(operacion, razon) {
      if (operacion == "+") {
        this.actualIndex = this.actualIndex + razon;
        this.actualDate = this.informacion[this.actualIndex];
      } else if (operacion == "-") {
        this.actualIndex = this.actualIndex - razon;
        this.actualDate = this.informacion[this.actualIndex];
      }
    },
    /**
     * @param {number} idxActual actualIndex
     * @param {string} opComparacion operador de comparacion "==" o "!="
     * @param  valor valor a ser comparado con el contenido de la pregunta en la pos idxActual
     * @param {string}  opIncremento operacion de incremento para funcion tipo go()
     * @param {number}  incremento  para funcion tipo go()
     */
    cuando(idxActual, opComparacion, valor, opIncremento, incremento) {
      if (opComparacion == "==") {
        if (this.informacion[idxActual].respuesta == valor) {
          this.go(opIncremento, incremento);
        } else {
          this.go(opIncremento, 1);
        }
      } else if (opComparacion == "!=") {
        if (
          this.actualIndex == idxActual &&
          this.informacion[this.actualIndex].respuesta != valor
        ) {
          this.go(opIncremento, incremento);
        } else {
          this.go(opIncremento, 1);
        }
      }
    },
    recargar(){
      window.location.reload();
    }
  },
  mounted() {
    document.body.requestFullscreen();
    
  },
  created() {
    this.actualIndex = 0;
    this.actualDate = this.informacion[this.actualIndex];
  
    
  },
  updated() {
    const el = document.getElementsByClassName("input-text-base")[0];
    if (el != undefined) {
      el.focus();
    }
    if(this.finish==false){
      this.$store.state.newActualCaracterizacion = this.informacion;
      this.$store.dispatch("newActualCaracterizacionAction");
      
    }
  },
  
};
</script>

<style lang="scss" scoped>
$bg-color: #f4fbfe;
$primary-color: #8dd6f57c;
$primary-color-hover: #8dd6f5;

.caracterizacion-Container {
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  padding: 10px;
  overflow: hidden;
  justify-items: center;
  align-items: center;
  &--bg-color {
    background-color: $bg-color;
  }
}
.Block1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
  &__titulo {
    padding-top: 25px;
  }
  &__parrafo {
    transition: display 4s;
    display: none;
    transition: display 4s;
    display: block;
    padding: 20px;
    text-align: justify;
    font-size: 18px;
  }
  &__container-btn-continuar {
    display: flex;
    margin-top: auto;
    width: 80%;
    height: 7vh;
  }
}
.Block2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 15px;
  overflow: scroll;
  &__container-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    min-height: max-content;
    height: 100%;
  }
  .input-text-base {
    display: flex;
    min-width: 80vw;
    width: 100%;
    transition: box-shadow 0.5s;
  }

  &__container-btn-continuar {
    display: flex;
    margin-top: auto;
    justify-content: space-around;
    width: 100%;
    height: 7vh;
    margin-bottom: 20px;

    .button-base {
      min-height: 50px;
      border-radius: 35px;
    }
    .button-back {
      margin-right: 20px;
      width: 12vh;
      height: 100%;
      border-radius: 30px;
      &:focus {
        box-shadow: none;
        &:active {
          &:focus {
            border: none;
            outline: none;
            width: 100%;
            transition: width 0.2s;
            box-shadow: 0px 0px 10px $primary-color-hover;
          }
        }
      }
    }
  }
  h3 {
    display: flex;
    width: 100%;
    border-bottom: solid rgba(128, 128, 128, 0.247);
    justify-content: center;
    padding-bottom: 10px;
  }
}
.Block3{
  padding: 20px;
  display: flex;
  width: 100vw;
  height: 99vh;
  max-height: 99vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__img-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    // height: 100%;
    max-height: 80vh;
  }
}

// *** Elementos base ***
.button-base {
  display: flex;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 10px rgba(128, 128, 128, 0.2);
  background-color: $primary-color;
  &:hover {
    box-shadow: 0px 3px 10px $primary-color-hover;
    cursor: pointer;
  }
}

.input-text-base {
  display: flex;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  border: none;
  width: 100%;
  margin-top: 10px;
  min-height: 45px;
  max-height: 6vh;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

  &:focus {
    border: none;
    outline: none;
    width: 100%;
    transition: width 0.2s;
    box-shadow: 0px 0px 10px $primary-color-hover;
  }
}

@media (min-width: 768px) {
  .caracterizacion-Container{
    width: 100vw;

  }
  .Block1 {
    
    width: 40%;
  
   }
  .Block2 {
    margin-top: 15px;
    width: 50%;
   overflow: hidden;
    
    .input-text-base {
     
      min-width: 30vw;
      
    }

  }
}
</style>