<template>
  <section id="team" class="team section-bg">
    <Alerta
      class="alerta"
      v-show="$store.state.showrutadescape"
      :titulo="smsTitle"
      :cuerpo="smsBody"
      :tBoton="smsBText"
    />
    <div class="container">
      <div class="section-title">
        <h2>Desarrollo Humano</h2>
        <p>Solicitud de atencion Psicologica.</p>
      </div>
      <div class="input-grupo-container">
        <div v-show="showOneSection" class="input-grupo">
          <h5>Rol:</h5>
          <select class="custom-select" v-model="seleccion">
            <!-- <option selected>Selecione</option> -->
            <option value="estudiante">Estudiante</option>
            <option value="docente">Docente</option>
            <option value="administrativo">Administrativo</option>
            <option value="egresado">Egresado</option>
            <option value="estudiante-intercambio">
              Estudiante Intercambio
            </option>
            <option value="invitado-externo">Invitado Externo</option>
          </select>
          <div class="input-group-append p-3">
            <button
              class="btn btn-outline-primary"
              type="button"
              @click="selectRol"
            >
              Siguiente
            </button>
          </div>
        </div>
      </div>

      <div v-show="showInputCodigo" class="container p-2 col-lg-6">
        <div>
          <div class="form-group">
            <label for="codEs">Codigo del Estudiante</label>
            <input
              min="0"
              type="number"
              class="form-control"
              id="codEs"
              v-model="codigoEstudiante"
            />
          </div>
        </div>
        <div class="input-group-append p-3">
          <button
            class="btn btn-outline-primary"
            type="button"
            @click="loadEstudiante"
          >
            Siguiente
          </button>
        </div>
      </div>

      <div v-show="showInputDatos" class="container p-2 col-lg-6">
        <form class="row">
          <div class="form-group col-6">
            <label>Nombre</label>
            <input
              type="text"
              class="form-control"
              placeholder=""
              v-model="nombre"
            />
          </div>

          <div class="form-group col-6">
            <label>Apellido</label>
            <input
              type="text"
              class="form-control"
              placeholder=""
              v-model="apellido"
            />
          </div>
          <div class="form-group">
            <label for="exampleFormControlInput1"># Identificacion</label>
            <input
              min="0"
              type="number"
              class="form-control"
              v-model="identificacion"
            />
          </div>
          <div v-show="seleccion == 'estudiante'" class="form-group">
            <label for="exampleFormControlInput1">Programa</label>
            <input
              type="text"
              class="form-control"
              placeholder=""
              v-model="programa"
            />
          </div>

          <div v-show="seleccion == 'estudiante'" class="form-group col-4">
            <label for="exampleFormControlInput1">Codigo</label>
            <input
              type="text"
              class="form-control"
              placeholder=""
              v-model="codigoEstudiante"
              disabled
            />
          </div>
          <div class="form-group col-4">
            <label for="exampleFormControlInput1">Periodo</label>
            <input
              type="text"
              class="form-control"
              placeholder=""
              v-model="periodo"
            />
          </div>
          <div v-show="seleccion == 'estudiante'" class="form-group col-4">
            <label for="exampleFormControlInput1">Semestre</label>
            <input
              type="text"
              class="form-control"
              placeholder=""
              v-model="semestre"
            />
          </div>
          <div v-show="seleccion == 'estudiante'" class="form-group col-4">
            <label for="exampleFormControlInput1">Jornada</label>
            <input
              type="text"
              class="form-control"
              placeholder=""
              v-model="jornada"
            />
          </div>
          <div class="form-group col-8">
            <label for="exampleFormControlInput1">Email</label>
            <input
              type="email"
              class="form-control"
              placeholder=""
              v-model="email"
            />
          </div>
          <div class="form-group col-10">
            <label for="exampleFormControlInput1">Telefono</label>
            <input
              type="number"
              class="form-control"
              placeholder=""
              v-model="telefono"
            />
          </div>
          <div class="form-group col-6">
            <label for="exampleFormControlInput1">Motivo de la Consulta</label>
            <select class="form-control" v-model="motivo">
              <option value="0" selected hidden>-- Seleccione --</option>
              <option value="problemas personales">Problemas personales</option>
              <option value="problemas academicos">Problemas academicos</option>
              <option value="problemas familiares">Problemas familiares</option>
              <option value="problemas de relaciones y adaptacion">
                Problemas de relaciones y adaptacion
              </option>
              <option value="problemas de pareja">Problemas de pareja</option>
              <option value="otro">otro</option>
            </select>
          </div>
          <div v-show="motivo == 'otro'" class="form-group col-6">
            <label>Cual?</label>
            <input
              type="text"
              class="form-control col-8"
              placeholder="cual"
              v-model="otroMotivo"
            />
          </div>
         

          <div class="form-group col-6">
            <label for="exampleFormControlInput1">Profesional</label>
            <select class="form-control" v-model="profesionalSelected">
              <option value="0" selected hidden>-- Seleccione --</option>
              <option v-for="prof in profesionales" :key="prof" :value='prof'>{{prof}}</option>
              
            </select>
          </div>

           <div class="form-group col-12 mt-4">
            <input
              type="checkbox"
              v-model="tyc"
            />
            <span > Acepto el <router-link to="/tycPsicologia" target="_blank">concentimiento informado</router-link> y la <a href="https://www.unisinu.edu.co/politica-de-privacidad/" target="_blank"> Política de tratamiento de datos </a> de la Universidad del Sinú – Elías Bechara Zainúm.  </span>
            
          </div>
        </form>
        <div class="input-group-append p-3">
          <button
            class="btn btn-outline-primary"
            type="button"
            @click="sendReq"
          >
            Siguiente
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ApiUsers from "@/logic/ApiUsers.js";
import Alerta from "@/components/general/Alerta.vue";
//const ObjectId = require('mongo-objectid');
export default {
  name: "SolicitudAPsico",
  data: function () {
    return {
      nombre: null,
      apellido: null,
      identificacion: null,
      programa: null,
      jornada: null,
      periodo: null,
      tyc:null,
      semestre: null,
      email: null,
      telefono: null,
      motivo: "0",
      otroMotivo: null,
      codigoEstudiante: null,
      profesionales:['carlos','mile','andres'],
      profesionalSelected:'0',
      seleccion: "estudiante",

      showInputCodigo: false,
      showInputDatos: false,
      showActividades: false,
      showOneSection: true,
      showNone: false,
      smsTitle: "Mensaje",
      smsBody: "Cuerpo",
      smsBText: "Clickme",

      smsShow: false,
      actividades: [],
    };
  },
  methods: {
    selectRol() {
      if (this.seleccion == "estudiante") {
        this.showInputCodigo = true;
        this.showInputDatos = false;
        this.showOneSection = false;
      } else {
        this.showInputDatos = true;
        this.showInputCodigo = false;
        this.showOneSection = false;
      }
    },
    async loadEstudiante() {
      if (this.codigoEstudiante == null) {
        this.smsBody = "Debes ingresar un codigo.";
        this.smsBText = "Salir";
        this.$store.state.valueSRE = true;
        this.$store.state.rutadeescape = "/servicios";
        this.$store.dispatch("actionShowRutaDeEscape");
      } else {
        try {
          const datos = await ApiUsers.existeEstudiante(this.codigoEstudiante);
          if (datos.data == true) {
            this.showInputCodigo = false;
            this.showInputDatos = true;
            const r = await ApiUsers.getEstudiante(this.codigoEstudiante);
            console.log(r.status);
            this.nombre = r.data[0].nombre;
            this.apellido = r.data[0].apellido;
            this.identificacion = r.data[0].numeroidentificacion;
            this.programa = r.data[0].programa;
            this.periodo = r.data[0].periodo;
            this.semestre = r.data[0].semestre;
            this.email = r.data[0].correoelectronico;
          } else {
            this.smsBody = "Este codigo no registra en la base de datos.";
            this.smsBText = "Salir";
            this.$store.state.valueSRE = true;
            this.$store.state.rutadeescape = "/servicios";
            this.$store.dispatch("actionShowRutaDeEscape");
          }
        } catch (error) {
          console.log(error);
          this.smsBody = "Ocurrio un error interno, intente mas tarde.";
          this.smsBText = "Salir";
          this.$store.state.valueSRE = true;
          this.$store.state.rutadeescape = "/servicios";
          this.$store.dispatch("actionShowRutaDeEscape");
        }
      }
    },
    async sendReq() {
      if (this.seleccion == "estudiante") {
        if (this.motivo != "0" && this.motivo != "otro") {
          this.otroMotivo = this.motivo;
        }
        if (
          this.nombre == null ||
          this.apellido == null ||
          this.identificacion == null ||
          this.programa == null ||
          this.periodo == null ||
          this.profesionalSelected == "0" ||
          this.semestre == null ||
          this.jornada == null ||
          this.email == null ||
          this.tyc == null ||
          this.tyc == false ||
          this.telefono == null ||
          this.motivo == "0" ||
          this.otroMotivo == null
        ) {
          this.smsBody = "Todos los campos deben ser diligenciados.";
          this.smsBText = "Salir";
          this.$store.state.valueSRE = true;
          this.$store.state.rutadeescape = "/servicios";
          this.$store.dispatch("actionShowRutaDeEscape");
        } else {
          let recado={
              "codigo":this.codigoEstudiante,
              "nombre":this.nombre,
              "apellido":this.apellido,
              "identificacion":this.identificacion,
              "programa":this.programa,
              "periodo":this.periodo,
              "semestre":this.semestre,
              "jornada":this.jornada,
              "email":this.email,
              "telefono":this.telefono,
              "motivoSolicitud":this.motivo,
              "otroMotivo":this.otroMotivo,
              "tipoAtencion":"Solicitud",//solicitud o remitido por
              "rol":this.seleccion,
              "estado":'pendiente',
              "tyc":this.tyc,
              "profesionalSolicitado":this.profesionalSelected
            //   fecha
            //   observaciones
            //   asistio
            //   observaciones
            //   fecha cita
            //   hora cita
            //   remitido por:
          }
          try {
            const resp = await ApiUsers.enviarSolicitudAP(recado)
          console.log(resp)
          alert("Solicitud enviado Satisfactoriamente.")
           this.$router.push('/servicios')
          } catch (error) {
            console.log(error)
          }
          
        
        }
      }else{
          if (this.motivo != "0" && this.motivo != "otro") {
          this.otroMotivo = this.motivo;
        }

        if (
          this.nombre == null ||
          this.apellido == null ||
          this.identificacion == null ||
          this.periodo == null ||
          this.profesionalSelected == "0" ||
          this.email == null ||
          this.tyc == null ||
          this.tyc == false ||
          this.telefono == null ||
          this.motivo == "0" ||
          this.otroMotivo == null
        ) {
          this.smsBody = "Todos los campos deben ser diligenciados.";
          this.smsBText = "Salir";
          this.$store.state.valueSRE = true;
          this.$store.state.rutadeescape = "/servicios";
          this.$store.dispatch("actionShowRutaDeEscape");
        } else {
          let recado={
              "codigo":this.codigoEstudiante,
              "nombre":this.nombre,
              "apellido":this.apellido,
              "identificacion":this.identificacion,
              "programa":this.programa,
              "periodo":this.periodo,
              "semestre":this.semestre,
              "jornada":this.jornada,
              "email":this.email,
              "telefono":this.telefono,
              "motivoSolicitud":this.motivo,
              "otroMotivo":this.otroMotivo,
              "tipoAtencion":"Solicitud",//solicitud o remitido por
              "rol":this.seleccion,
              "estado":'pendiente',
              "tyc":this.tyc,
              "profesionalSolicitado":this.profesionalSelected
            //   fecha
            //   observaciones
            //   asistio
            //   observaciones
            //   fecha cita
            //   hora cita
            //   remitido por:
          }
          try {
            const resp = await ApiUsers.enviarSolicitudAP(recado)
          console.log(resp)
          alert("Solicitud enviado Satisfactoriamente.")
           this.$router.push('/servicios')
          } catch (error) {
            console.log(error)
          }
        }

      }
     
    },
    async getProfesionales(){
      try {
        const r = await ApiUsers.getPsicologos();
        this.profesionales = r.data;
      } catch (error) {
        console.error(error)
      }
    }
  },
  created() {
    this.getProfesionales()
  },

  components: {
    Alerta,
  },
};
</script>

<style scoped>
.input-grupo-container {
  display: flex;
  width: 100%;
  justify-content: center;
}
.input-grupo {
  display: flex;
  flex-direction: column;
  width: 90%;
  justify-content: center;
}
.input-grupo > select {
  min-height: 5vh;
  border-radius: 5px;
  background-color: white;
  padding: 5px;
}
.input-grupo > div {
  display: flex;
  justify-content: center;
}
.input-grupo > div > button {
  margin-top: 6vh;
  width: 70%;
}
.activ-img {
  min-height: 180px;
  max-height: 180px;
}
.mensaje {
  height: 80vh;
  width: 90vw;
  position: fixed;
  top: calc(50% - 200px);
  left: calc(50% - (90vw / 2));
}
.team {
  height: 100vh;
  min-height: 76vh;
  overflow: auto;
}

.alerta {
  z-index: 99;
}
.inscritos {
  width: min-content;

  padding-right: 0px;
}
@media (min-width: 768px) {
  .input-grupo-container {
    padding-left: 20vw;
    padding-right: 20vw;
  }
}
</style>