<template>
  <div class="container-dashboard">
    <SlideBard />
    <Board />
  </div>
</template>

<script>
import SlideBard from "@/components/privado/SlideBard.vue";
import Board from "@/components/privado/Board.vue";
import localToken from "@/logic/localToken.js";
import ApiUsers from "@/logic/ApiUsers.js";
export default {
  name: "Dashboard",
  components: {
    SlideBard,
    Board,
  },
  methods: {
    async authrol() {
      try {
        const res = await ApiUsers.getUserId({ token: localToken.getToken() });
        const resp = await ApiUsers.getUser(res.data.numeroidentificacion, {
        token: localToken.getToken(),
        
      });
      this.$store.state.newrol = resp.data.rol;
        this.$store.dispatch("addRolAction");


      } catch (error) {
        
        if (error.response.status == 401) {
          console.log(`Expiro el token`);
        } else {
          console.error(`${error.response.status} line 36`);
        }
        this.$router.push("/login");
      }
      

      
    },
  },

  created() {
    this.authrol();
  },
  
};
</script>
<style scoped>
.container-dashboard {
  display: flex;
  width: 100vw;
  height: 100vh;
}
@media (min-width: 360px) and (max-width: 768px) {
  .container-dashboard {
    flex-direction: column;
  }
}
</style>
